import React, { FC } from "react";
import { Switch } from "antd";
import { isNil } from "ramda";
import { STATUS_STYLE } from "../../utils/connection";
import { ConnectionStatusType, MachineConnectivityAgentStatus } from "../../utils/types/enums";
import { getHumanizedElapsedTime } from "../../utils/general/dates";
import { IconButton } from "../general/IconButton";

export const ConnectionCard: FC<ConnectionType> = function ({ size, updatedAt, gateway, connectionStatus, agentStatus, connectivityProvider = "", image, onEdit, onSwitch }: ConnectionType) {
  return size === 'default' ?
    <div className={"relative w-80 h-64 border shadow-xs rounded bg-white"}>
      <div className={"absolute top-0 w-full"}>
        <div className={`h-7 w-full rounded-t font-medium text-center text-xs pt-1.5 ${STATUS_STYLE[connectionStatus]}`}>{connectionStatus}</div>
      </div>
      <div className={"w-full inline-flex items-center justify-center h-28 mt-7"}>
        <img className={"h-16"} src={image} />
      </div>
      <div className={"absolute bottom-10 w-full flex flex-col gap-1 px-4 py-1"}>
        <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
          <span className={"font-light pr-1"}>Integration:</span> {connectivityProvider.replaceAll("_", " ")}
        </div>
        <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
          <span className={"font-light pr-1"}>Gateway:</span> {gateway?.name} {!isNil(gateway?.version) && "(v" + gateway?.version + ")"}
        </div>
        <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
          <span className={"font-light pr-1"}>Last Updated:</span> {getHumanizedElapsedTime(updatedAt)}
        </div>
      </div>
      <div className={"absolute bottom-1 pl-2 pr-4 w-full"}>
        <div className={"flex items-center justify-between"}>
          <IconButton type={"primary"} icon={"edit"} onClick={onEdit} />
          {/*<TimestampPopover connections={0} jobs={0} monitors={0} />*/}
          <Switch
            className={`${agentStatus == MachineConnectivityAgentStatus.ACTIVE ? "bg-primary-500" : "bg-dark-400"}`}
            size={"default"}
            checked={agentStatus == MachineConnectivityAgentStatus.ACTIVE}
            onClick={(checked: boolean): void => {
              onSwitch(checked);
            }}
          />
        </div>
      </div>
    </div> :
      <div className={"relative bg-white max-w-xl flex flex-row border w-full"}>
          <div className={`h-full w-6 ${STATUS_STYLE[connectionStatus]}`}></div>
          <div className={"flex-grow flex-col gap-1 p-4"}>
              <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
                  <span className={"font-light pr-1"}>Integration:</span> {connectivityProvider.replaceAll("_", " ")}
              </div>
              <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
                  <span className={"font-light pr-1"}>Gateway:</span> {gateway?.name} {!isNil(gateway?.version) && "(v" + gateway?.version + ")"}
              </div>
              <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
                  <span className={"font-light pr-1"}>Status:</span> {connectionStatus}
              </div>
              <div className={"w-full font-extralight inline-block text-xs truncate overflow-ellipsis"}>
                  <span className={"font-light pr-1"}>Last Updated:</span> {getHumanizedElapsedTime(updatedAt)}
              </div>
          </div>
          <div className={'h-full inline-flex items-start p-4'}>
              <img className={"w-32"} src={image} />
          </div>
          <div className={"absolute bottom-1 right-4"}>
              <div className={"flex items-center justify-between gap-1"}>
                  <IconButton type={"primary"} icon={"edit"} onClick={onEdit} />
                  {/*<TimestampPopover connections={0} jobs={0} monitors={0} />*/}
                  <Switch
                      className={`${agentStatus == MachineConnectivityAgentStatus.ACTIVE ? "bg-primary-500" : "bg-dark-400"}`}
                      size={"small"}
                      checked={agentStatus == MachineConnectivityAgentStatus.ACTIVE}
                      onClick={(checked: boolean): void => {
                          onSwitch(checked);
                      }}
                  />
              </div>
          </div>
      </div>
};

type ConnectionType = {
  size: 'small' | 'default';
  id: string;
  name: string;
  updatedAt: number;
  gateway: GatewayType | null;
  connectionStatus: ConnectionStatusType;
  agentStatus: MachineConnectivityAgentStatus;
  connectivityProvider: string;
  image?: string;
  onEdit: () => void;
  onSwitch: (v: boolean) => void;
};

type GatewayType = {
  name: string;
  version: string;
};
