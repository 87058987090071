import React, { FC, useEffect, useState } from "react";
import { DeviceConnectionsStatusType, DeviceSummaryType } from "../../../utils/types/types";
import { DeviceGridContainer } from "./DeviceGridContainer";
import { getDeviceSummary } from "../../../services/services";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { ErrorType, ResponseType } from "../../../utils/types/uiTypes";
import { ChartWidgetType } from "../../../utils/types/enums";
import { getNumberOfAlerts } from "../../../utils/alerts/alert";
import { DeviceMetricChartSelector } from "./DeviceMetricChartSelector";
import { useAutoRefresh } from "../../../custom-hooks/useAutoRefresh";

export const DeviceCard: FC<DeviceCardType> = function ({ deviceSummary, image = "error", gridView }: DeviceCardType) {
  const [error, setError] = useState<ErrorType | null>(null);

  const handleError = (message: string, err?: any) => {
    setError({
      error: err,
      message: message,
    });
  };

  return (
    <div className={"sm:h-104"}>
      {gridView ? (
        <DeviceGridContainer
          className={"shadow-xl md:shadow-xs"}
          loading={deviceSummary ? false : true}
          error={error ? true : false}
          id={deviceSummary?.device?.id}
          name={deviceSummary?.device?.name}
          type={deviceSummary?.device?.model?.type}
          job={deviceSummary?.jobs[0]}
          widgets={deviceSummary?.widgets?.filter(({ type }) => type == ChartWidgetType.SINGLE_VALUE)}
          alerts={getNumberOfAlerts(deviceSummary?.monitors)}
          status={deviceSummary?.device?.currentStatus?.status}
          activeConnections={deviceSummary?.device?.currentStatus?.activeConnections}
          totalConnections={deviceSummary?.device?.currentStatus?.totalConnections}
          image={image}
        />
      ) : (
        <div className="h-full lg:flex">
          <DeviceGridContainer
            className={"lg:w-96"}
            loading={deviceSummary ? false : true}
            error={error ? true : false}
            id={deviceSummary?.device?.id}
            name={deviceSummary?.device?.name}
            type={deviceSummary?.device?.model?.type}
            job={deviceSummary?.jobs[0]}
            widgets={deviceSummary?.widgets?.filter(({ type }) => type == ChartWidgetType.SINGLE_VALUE)}
            alerts={getNumberOfAlerts(deviceSummary?.monitors)}
            status={deviceSummary?.device?.currentStatus?.status}
            activeConnections={deviceSummary?.device?.currentStatus?.activeConnections}
            totalConnections={deviceSummary?.device?.currentStatus?.totalConnections}
            image={image}
          />
          <div className="hidden lg:inline-flex max-h-full flex-auto bg-white lg:border-t lg:border-b lg:border-r lg:border-gray-200">
            {!isNil(deviceSummary?.widgets) && !isEmpty(deviceSummary?.widgets) && <DeviceMetricChartSelector deviceId={deviceSummary?.device.id} widgets={deviceSummary?.widgets.filter(({ type }) => type !== ChartWidgetType.SINGLE_VALUE)} />}
          </div>
        </div>
      )}
    </div>
  );
};

type DeviceCardType = {
  deviceSummary: DeviceSummaryType;
  image?: string;
  gridView: boolean;
};
