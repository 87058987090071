import React, { FC } from "react";
import { isNil } from "ramda";
import { Tooltip } from "antd";
import { displayDashAsDefault } from "../../utils/general/helper";

export const LabelValueCard: FC<LabelValueType> = function ({ label = "--", value = undefined, tooltip = true, tooltipText = value, className = "bg-primary-50 border text-grey-800 border-primary-100 rounded" }: LabelValueType) {
  return (
    <div className={`px-2 py-2 ${className}`}>
      <div className={"text-xxs text-center text-gray-500"}>{label}</div>
      <div className={`text-xs text-center ${value?.replaceAll(/[/-]/g, "") ? "text-gray-800" : "text-muted"} truncate overflow-ellipsis font-medium pt-1.5`}>
        {isNil(value) ? (
          <span>{displayDashAsDefault(value)}</span>
        ) : tooltip ? (
          <Tooltip placement="bottom" title={<span className={"text-xxs"}>{tooltipText}</span>}>
            {value}
          </Tooltip>
        ) : (
          <span>{value}</span>
        )}
      </div>
    </div>
  );
};

type LabelValueType = {
  label: string;
  value: string | undefined;
  tooltip?: boolean;
  tooltipText?: string | undefined;
  className?: string;
};
