import React, { FC } from "react";
import { Progress } from "antd";

export const JobProgress: FC<JobProgressType> = function ({ name = "", percentage, mainStyle = "rounded-md border border-gray-300 text-black bg-white", percentStyle = "text-xs text-black" }: JobProgressType) {
  return (
    <div className={`bg-white flex justify-between px-2 py-1 ${mainStyle}`}>
      <span className={"w-52 truncate overflow-ellipsis text-xs pt-px"}>
        {name}
      </span>
      <div className={"w-28 mx-2 flex my-auto"}>
        <Progress size="small" showInfo={false} percent={percentage} />
        <span className={`inline-block pl-1 pt-1 leading-none ${percentStyle}`}>{!isNaN(percentage) && percentage.toFixed(2)}%</span>
      </div>
    </div>
  );
};

type JobProgressType = {
  name: string;
  percentage: number;
  mainStyle?: string;
  percentStyle?: string;
};
