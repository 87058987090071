import React, { FC, ReactNode } from "react";
import { Button, Tooltip } from "antd";
import { PRIMARY_BUTTON_STYLE } from "../../utils/general/styles";

export const FloatingButton: FC<FloatingButtonType> = ({ icon, label, className = "", onClick }: FloatingButtonType) => {
  return (
    <Tooltip title={label} placement={"left"}>
      <Button onClick={onClick} className={`z-[1000] fixed bottom-8 right-8 rounded-full p-4 flex items-center justify-center shadow h-12 w-12 border-0 ${PRIMARY_BUTTON_STYLE} ${className}`}>
        {icon}
      </Button>
    </Tooltip>
  );
};

type FloatingButtonType = {
  icon: ReactNode;
  label?: string;
  className?: string;
  onClick?: () => void;
};
