import React, { FC } from "react";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import { Routes } from "./components/routes/Routes";

export const App: FC = function () {
  return (
    <AuthenticationProvider>
      <Routes />
    </AuthenticationProvider>
  );
};
