import React, { FC } from "react";
import { BackgroundImage } from "../general/BackgroundImage";
import MaterialiseLogo from "../../images/logo/materialise/materialise-full-logo.svg";

export const MaterialiseFooter: FC = function () {
  return (
    <div className={'w-full text-center p-8'}>
      <div className={'w-28 h-20 m-auto'}>
        <BackgroundImage border={false} size={'contain'} src={MaterialiseLogo} />
      </div>
      <div className={'flex flex-row items-center justify-center gap-2'}>
        <a rel={'noreferrer'}target={'_blank'} href={'https://www.materialise.com/en/software/customer-services/technical-support'}>
          <span className={'underline'}>Contact Us</span>
        </a>
        <span>•</span>
        <a rel={'noreferrer'}
          target={'_blank'}
          href={'https://help.materialise.com/materialise-software-end-user-license-agreement-(eula)'}>
          <span className={'underline'}>End-User License Agreement</span>
        </a>
      </div>
    </div>
  );
};


