import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb, Button, Collapse, Empty, message, Space, Switch } from "antd";
import { Widget } from "../device/metrics/Widget";
import { DeviceDashboardContext } from "../../context/DeviceDashboardContext";
import { ChartWidgetType, ParameterDataType } from "../../utils/types/enums";
import { getMonitor, updateMonitor } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { MonitoringRuleSetDetailsType } from "../../utils/types/types";
import { AlertRuleCard } from "./AlertRuleCard";
import { path } from "ramda";
import { removeItemFromArray, valueOrDefault } from "../../utils/general/helper";
import { AddMonitorForm } from "./AddMonitorForm";
import { MonitorHistory } from "./MonitorHistory";
import { convertRuleToExpressions } from "../../utils/metric";
import { ConfirmDeleteModal } from "../../utils/modals";
import { PageLayout } from "../layout/PageLayout";
import { AuthenticationContext } from "../../context/AuthenticationContext";

const { Panel } = Collapse;
export const MonitorDetails: FC<PropType> = function ({ isDashboard }) {
  const { authentication } = useContext(AuthenticationContext);
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const { deviceId, monitorId }: ParamsType = useParams();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [drawerType, setDrawerType] = useState<DrawerType | null>(null);
  const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>(0);
  const [monitor, setMonitor] = useState<MonitoringRuleSetDetailsType | null>(null);

  const getSubHeader: getSubHeaderType = (id, name = "") => {
    return (
      <div className={"w-full flex justify-between items-center mb-3"}>
        <Breadcrumb separator=">" className={"mt-1.5"}>
          <Breadcrumb.Item>
            {isDashboard ? (
              <Link to={`/all-monitors`}>
                <i className="fas fa-binoculars" /> Monitors
              </Link>
            ) : (
              <Link to={`/devices/${id}/monitors`}>
                <i className="fas fa-binoculars" /> Monitor
              </Link>
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{name}</Breadcrumb.Item>
        </Breadcrumb>
        <span className={"flex flex-row gap-4 items-center"}>
          <Button
            type={"primary"}
            className={"w-28 bg-primary-500 border-primary-500 hover:bg-opacity-80 border-opacity-80 hidden lg:block"}
            onClick={() => {
              setDrawerType("EDIT_MONITOR");
            }}
          >
            Edit
          </Button>
        </span>{" "}
      </div>
    );
  };
  const fetchMonitor: fetchMonitorType = (deviceId, id) => {
    setLoading(true);
    getMonitor({
      segments: {
        deviceId,
        id,
      },
    })
      .then(({ data }: ResponseType<MonitoringRuleSetDetailsType>) => {
        setMonitor(data);
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Unable to load device monitors");
        setError(true);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const update: updateType = (deviceId, monitor: MonitoringRuleSetDetailsType) => {
    updateMonitor({
      segments: {
        deviceId,
        id: monitor.id,
      },
      body: JSON.stringify(monitor),
    })
      .then(({ data }: ResponseType<MonitoringRuleSetDetailsType>) => {
        setMonitor(data);
        message.success("Monitor updated successfully");
      })
      .catch(() => {
        message.error("Unable to update monitor");
      });
  };

  const getDrawerContent = () => {
    if (!deviceDashboard || !monitor) return;
    switch (drawerType) {
      case "EDIT_MONITOR":
        return (
          <AddMonitorForm
            monitor={monitor}
            users={authentication?.teamMembers}
            deviceId={deviceId}
            parameters={deviceDashboard.parameters}
            onSuccess={(value) => {
              setMonitor(value);
            }}
            onClose={() => {
              setDrawerType(null);
            }}
          />
        );
    }
  };

  useEffect(() => {
    fetchMonitor(deviceId, monitorId);
  }, [deviceId, monitorId]);

  return (
    <PageLayout loading={loading} loadingText={"Loading Monitor"} height={"full"} subHeader={getSubHeader(deviceId, monitor?.name)}>
      <>
        {deviceDashboard && monitor && (
          <>
            <div>
              <Space direction={"vertical"} className={"gap-2 w-full border-0"}>
                <Collapse defaultActiveKey={["rules"]} bordered={false}>
                  <Panel header={<div className={"font-medium"}>Conditions</div>} key="rules" className={"border shadow-xs"}>
                    <div className={"max-h-[500px] overflow-y-auto overflow-x-clip"}>
                      {monitor?.ruleSet?.rules?.length > 0 ? (
                        <div className={"w-full mx-4"}>
                          <div className={"px-6"}>
                            <Widget
                              deviceId={deviceId}
                              from={deviceDashboard.fromDate}
                              to={deviceDashboard.toDate}
                              expressions={convertRuleToExpressions(valueOrDefault(null, path(["ruleSet", "rules", selectedRuleIndex], monitor)))}
                              type={path(["ruleSet", "rules", selectedRuleIndex, "primaryMetric", "parameter", "dataType"], monitor) === ParameterDataType.STRING.toString() ? ChartWidgetType.SINGLE_VALUE : ChartWidgetType.LINE_PLOT}
                              yThreshold={path(["ruleSet", "rules", selectedRuleIndex, "threshold", "value"], monitor)}
                              height={200}
                              allowExport={false}
                            />
                          </div>
                          <div>
                            <div className={"my-4 px-6"}>
                              <div className={"text-sm inline-block"}>
                                <span className={"text-dark-400"}>When </span>
                                <span className={"font-bold"}>{monitor?.ruleSet?.condition}</span>
                                <span className={"text-dark-400"}> of the following conditions are met</span>
                              </div>
                              <div className={"flex flex-col gap-2 my-5"}>
                                {monitor?.ruleSet?.rules?.map(({ condition, primaryMetric, secondaryMetric, threshold, timeRange }, i) => {
                                  return (
                                    <AlertRuleCard
                                      key={i}
                                      selected={selectedRuleIndex === i}
                                      condition={condition}
                                      primaryMetric={primaryMetric}
                                      secondaryMetric={secondaryMetric}
                                      threshold={threshold}
                                      onSelect={() => {
                                        setSelectedRuleIndex(i);
                                      }}
                                      onDelete={() => {
                                        ConfirmDeleteModal("Are you sure you want to delete this condition?", () => update(deviceId, { ...monitor, ruleSet: { ...monitor?.ruleSet, rules: removeItemFromArray(i, monitor.ruleSet.rules) } }));
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Empty className={"pt-4"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Panel>
                </Collapse>
                <Collapse defaultActiveKey={["history"]} bordered={false}>
                  <Panel header={<div className={"font-medium"}>History</div>} key="history" className={"border shadow-xs"}>
                    <div className={"max-h-[500px] overflow-auto -mt-4"}>
                      <MonitorHistory deviceId={deviceId} monitorId={monitorId} />
                    </div>
                  </Panel>
                </Collapse>
              </Space>
            </div>
            {drawerType && getDrawerContent()}
          </>
        )}
      </>
    </PageLayout>
  );
};
type PropType = {
  isDashboard: boolean;
};
type ParamsType = {
  deviceId: string;
  monitorId: string;
};
type DrawerType = "EDIT_MONITOR";
type fetchMonitorType = (deviceId: string, monitorId: string) => void;
type updateType = (deviceId: string, monitor: MonitoringRuleSetDetailsType) => void;
type getSubHeaderType = (deviceId: string, name?: string) => ReactNode;
