import React, { createContext, FC, ReactNode, useEffect, useState } from "react";
import { getAuth } from "../services/services";
import { NoParamVoidType, ResponseType } from "../utils/types/uiTypes";
import { useHistory } from "react-router";
import { HOME_PAGE_URL } from "../utils/routes/config";
import { AuthenticationType } from "../utils/types/types";

export const AuthenticationContext = createContext<AuthenticationContextType>({
  authentication: null,
  setAuthentication: () => {},
});

export const AuthenticationProvider: FC<AuthenticationProviderType> = function ({ children }: AuthenticationProviderType) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [authentication, setAuthentication] = useState<AuthenticationType | null>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const authorize: NoParamVoidType = () => {
    getAuth({
      params: {
        token: urlSearchParams.get("token") ?? "",
      },
    })
      .then(({ data }: ResponseType) => {
        setAuthentication(data);
      })
      .then(() => {
        history && history.push(HOME_PAGE_URL);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    authorize();
  }, []);

  return <>{<AuthenticationContext.Provider value={{ authentication, setAuthentication }}>{!loading && children}</AuthenticationContext.Provider>}</>;
};

type AuthenticationProviderType = {
  children: ReactNode;
};

type AuthenticationContextType = {
  authentication: null | AuthenticationType;
  setAuthentication: (u: AuthenticationType | null) => void;
};
