import React, { FC, useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsStock from "highcharts/highstock";
import { getHighchartsConfig, MetricType } from "../../utils/charts";
import Exporting from "highcharts/modules/exporting";
import Offline from "highcharts/modules/offline-exporting";
import ExportData from "highcharts/modules/export-data";
import { ChartWidgetType } from "../../utils/types/enums";
import { valueOrDefault } from "../../utils/general/helper";
import { Empty } from "antd";
import { isEmpty } from "ramda";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

Exporting(Highcharts);
Offline(Highcharts);
ExportData(Highcharts);
NoDataToDisplay(Highcharts);

export const ChartConfiguration: FC<PlotChartsType> = function ({ allowExport = false, stock = false, title = false, height, type, metrics = [], min, max, colors, yThreshold }: PlotChartsType) {
  const [options, setOptions] = useState<any>(null);

  useEffect(() => {
    setOptions({
      ...getHighchartsConfig(allowExport, valueOrDefault("", title), height, type, metrics, min, max, colors, yThreshold),
    });
  }, [allowExport, title, height, type, metrics, min, max, colors, yThreshold]);

  return (
    <div className={"h-full w-full relative"} style={{ minHeight: height }}>
      {options && <HighchartsReact className={"w-full"} highcharts={stock ? HighchartsStock : Highcharts} constructorType={stock && "stockChart"} allowChartUpdate={true} options={options} />}
    </div>
  );
};

type PlotChartsType = {
  allowExport?: boolean;
  stock?: boolean;
  title?: string | false;
  height: number;
  type: ChartWidgetType;
  metrics: Array<MetricType>;
  min?: number;
  max?: number;
  colors: Array<string>;
  yThreshold: number | boolean;
};
