import React, { FC, useEffect, useState } from "react";
import { Divider, Tag, Tooltip } from "antd";
import { path } from "ramda";
import { DeviceSummaryType } from "../../../utils/types/types";
import { getDeviceSummary } from "../../../services/services";
import { ResponseType } from "../../../utils/types/uiTypes";
import { getConnectionStatus, STATUS_STYLE } from "../../../utils/connection";
import { useParams } from "react-router";
import { DeviceStatusType } from "../../../utils/types/enums";
import moment from "moment";
import { ApiOutlined } from "@ant-design/icons";
import { getNumberOfAlerts } from "../../../utils/alerts/alert";
import { usePageTitle } from "../../../custom-hooks/usePageTitle";
import { PageLayout } from "../../layout/PageLayout";
import { MetricValueIconMap } from "../metrics/MetricValueIconMap";
import { AlertBadge } from "../../alert/AlertBadge";
import { DeviceConnections } from "./DeviceConnections";

export const DeviceOverview: FC = function () {
  usePageTitle("Overview");
  const { deviceId }: ParamsType = useParams();
  const [loading, setLoading] = useState(true);
  const [deviceSummary, setDevicesSummary] = useState<DeviceSummaryType | null>(null);

  const getSummary: getSummaryType = (id) => {
    getDeviceSummary({
      segments: {
        id,
      },
      params: {
        from: moment(Date.now()).subtract(1, "h").valueOf(),
        to: moment(Date.now()).valueOf(),
      },
    })
        .then(({ data }: ResponseType<DeviceSummaryType>) => {
          const { device, jobs = [], widgets = [], messages = [], monitors = [] } = data;
          setDevicesSummary({ device, jobs, widgets, messages, monitors });
        })
        .catch((error: any) => {
          console.log(error);
        })
        .then(() => {
          setLoading(false);
        });
  };

  useEffect(() => {
    getSummary(deviceId);
  }, [deviceId]);

  return (
      <PageLayout height={"full"} loading={loading} loadingText={"Loading Overview ..."}>
        <>
          <div className={"flex flex-col gap-6"}>
            <div className={"w-full"}>
              <div className={"flex flex-row gap-4"}>
                <div className={"relative border w-80 md:w-full md:h-48 p-4 mx-auto md:mx-0 bg-white shadow-xs rounded"}>
                  <AlertBadge id={deviceId} alerts={getNumberOfAlerts(deviceSummary?.monitors)} />
                  <div className={"flex flex-col md:flex-row h-full"}>
                    <div className={"w-56 h-full"}>
                      <img className={"max-w-full max-h-full mx-auto"} src={deviceSummary?.device?.model?.imageUrl} />
                    </div>
                    <Divider type={"vertical"} className={"h-full mx-4"} />
                    <div className={"flex flex-col gap-5"}>
                      <div className={"w-full text-base font-bold"}>
                      <span className={"text-primary-600 group"}>
                        {deviceSummary?.device?.name}
                        {/*
                        <EditOutlined className={'px-2 text-primary-500 opacity-0 group-hover:opacity-100'} />
*/}
                      </span>
                      </div>
                      <div className={"flex flex-col gap-1"}>
                        <div className={"truncate overflow-ellipsis"}>
                          <div className={"inline-block w-44 pr-1 text-xs"}>ID</div>
                          <span className={"w-full truncate overflow-ellipsis font-light"}>
                          <Tag className={"text-xs"}>{deviceSummary?.device?.id}</Tag>
                        </span>
                        </div>
                        <div className={"truncate overflow-ellipsis"}>
                          <div className={"inline-block w-44 pr-1 text-xs"}>Status</div>
                          <span className={"w-full truncate overflow-ellipsis font-light"}>
                          <Tag className={`text-xs ${deviceSummary?.device?.currentStatus ? STATUS_STYLE[deviceSummary.device.currentStatus.status] : STATUS_STYLE[DeviceStatusType.UNKNOWN]}`}>
                            {getConnectionStatus(deviceSummary?.device?.currentStatus?.status, deviceSummary?.device?.currentStatus?.activeConnections, deviceSummary?.device?.currentStatus?.totalConnections)}{" "}
                          </Tag>
                        </span>
                        </div>
                        <div className={"truncate overflow-ellipsis"}>
                          <div className={"inline-block w-44 pr-1 text-xs"}>Manufacturer</div>
                          <span className={"w-full truncate overflow-ellipsis font-light text-xs"}>{deviceSummary?.device?.model?.manufacturer?.name}</span>
                        </div>
                        <div className={"truncate overflow-ellipsis"}>
                          <div className={"inline-block w-44 pr-1 text-xs"}>Model</div>
                          <span className={"w-full truncate overflow-ellipsis font-light text-xs"}>{deviceSummary?.device?.model?.name}</span>
                        </div>
                      </div>
                    </div>
                    <Divider type={"vertical"} className={"h-full mx-4"} />
                    <div className={"flex flex-col"}>
                      <div className={"grid grid-rows-6 grid-flow-col gap-x-8 gap-y-1"}>
                        {deviceSummary?.widgets.map(({ label = "--", metrics = [] }, i) => {
                          const value: any = path([0, "results", 0, "stringValue"], metrics);
                          return (
                              <div key={i} className={"flex flex-row gap-4 w-full items-center"}>
                                <div className={"min-w-[15rem] pr-1 text-xs truncate overflow-ellipsis"}><Tooltip title={label}>{label}</Tooltip></div>
                                <span className={"w-full truncate overflow-ellipsis font-light text-xs"}>
                              <span className={"mr-2"}>{value}</span>
                              <MetricValueIconMap value={value} />
                            </span>
                              </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"w-full"}>
              <div className={"font-semibold"}>
                <ApiOutlined className={"text-xs mr-2"} />
                Connections
              </div>
              <Divider className={"mt-2 mb-4"} type={"horizontal"} />
              <DeviceConnections deviceId={deviceId} manufacturer={deviceSummary?.device?.model?.manufacturer} />
            </div>
          </div>
        </>
      </PageLayout>
  );
};

type ParamsType = {
  deviceId: string;
};
type getSummaryType = (deviceId: string) => void;