import { valueOrDefault } from "../utils/general/helper";
import { events } from "../utils/events";

const getParams: getParamType = (params) => new URLSearchParams(params).toString();
const getUrl: getUrlType = (segments: any, url: urlType) => (typeof url === "string" ? url : url(segments));

const request: requestType = function (options) {
  return new Promise((resolve, reject) => {
    fetch(options.params ? `${options.url}?${getParams(options.params)}` : options.url, { ...options })
      .then((response) => {
        const originalResponse = response.clone();
        response.text().then((data) => {
          let responseData = undefined;
          try {
            if (options.filename) {
              originalResponse.blob().then((blob) => {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(new Blob([blob]));
                element.download = options.filename;
                document.body.appendChild(element);
                element.click();
                element.parentNode?.removeChild(element);
              });
            } else {
              responseData = JSON.parse(data);
            }
          } finally {
            if (response.ok && response.status >= 200 && response.status <= 299) {
              resolve({
                ok: response.ok,
                status: response.status,
                data: responseData,
              });
            } else {
              if (response.status === 401) {
                events.trigger("REQUEST-UNAUTHORIZED", null);
              }
              reject({ ok: response.ok, status: response.status, data: valueOrDefault("Something went wrong, please try again!", responseData?.message?.toString()) });
            }
          }
        });
      })
      .catch((error) => {
        reject({ ok: false, data: valueOrDefault("Something went wrong, please try again!", error?.message?.toString()) });
      });
  });
};

export const GET: httpCallType =
  (url) =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }: optionsType) =>
    request({ params, body, headers, method: "get", url: getUrl(segments, url) });

export const POST: httpCallType =
  (url) =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    filename = undefined,
  }: optionsType) =>
    request({ params, body, headers, filename, method: "post", url: getUrl(segments, url) });

export const PUT: httpCallType =
  (url) =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }: optionsType) =>
    request({ params, body, headers, method: "put", url: getUrl(segments, url) });

export const DELETE: httpCallType =
  (url) =>
  ({
    segments,
    params,
    body,
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }: optionsType) =>
    request({ params, body, headers, method: "delete", url: getUrl(segments, url) });

type urlFnType = (v: any) => string;
type urlType = string | urlFnType;
type optionsType = {
  url?: string;
  segments?: any;
  params?: any;
  body?: any;
  headers?: any;
  filename?: string;
};
type getParamType = (param: any) => string;
type requestType = (options: any) => Promise<any>;
type getUrlType = (segments: any, url: urlType) => string;
type httpCallType = (url: urlType) => (o: optionsType) => Promise<any>;
