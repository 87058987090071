import React, { FC, useContext, useState } from "react";
import { createNewRow, DraggableType, MENU_ITEMS } from "../../../../utils/dashboardCustomization";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DropZone } from "./DropZone";
import { EditableRow } from "./EditableRow";
import { insertItemIntoArray, moveItemInArray, removeItemFromArray, updateItemInArray } from "../../../../utils/general/helper";
import { WidgetMenuBar } from "./WidgetMenuBar";
import { findIndex, propEq } from "ramda";
import { AddWidgetModal } from "./AddWidgetModal";
import { ACTION_TYPES } from "../../../../utils/types/uiTypes";
import { uniqueId } from "../../../../utils/general/uniqueId";
import { TrashDropZone } from "./TrashDropZone";
import { DashboardLayoutType, ChartWidgetType } from "../../../../utils/types/enums";
import { DeviceDashboardContext } from "../../../../context/DeviceDashboardContext";

export const EditableSection: FC<EditableSectionType> = function ({ rows = [], onChange }: EditableSectionType) {
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const [index, setIndex] = useState<number>(-1);
  const [widgetForm, setWidgetForm] = useState<WidgetFormType | null>(null);

  const clearForm: clearFormType = () => {
    setWidgetForm(null);
    setIndex(-1);
  };

  const handleSectionLevelDrop: handleSectionLevelDropType =
    (index) =>
    ({ id, type, data }): void => {
      switch (type) {
        case DashboardLayoutType.ROW: {
          const currentRowIndex = findIndex(propEq("id", id))(rows);
          onChange(moveItemInArray(currentRowIndex, index, rows));
          return;
        }
        case DashboardLayoutType.WIDGET: {
          onChange(insertItemIntoArray(index, rows, createNewRow([{ ...data }])));
          return;
        }
        case DashboardLayoutType.MENU_ITEM:
          setWidgetForm({ formType: ACTION_TYPES.ADD, type: ChartWidgetType[id as keyof typeof ChartWidgetType], id: uniqueId(), expressions: [], label: "" });
          setIndex(index);
          return;
      }
    };

  const handleTrashDrop: handleTrashDropType = ({ id, type }) => {
    switch (type) {
      case DashboardLayoutType.ROW: {
        const index = findIndex(propEq("id", id))(rows);
        onChange(removeItemFromArray(index, rows));
        return;
      }
      case DashboardLayoutType.WIDGET: {
        const updatedRows = rows.map((o) => ({
          ...o,
          widgets: o.widgets.filter((v) => v.id !== id),
        }));
        onChange(updatedRows);
        return;
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={"flex h-full"}>
        <div className={"fixed max-h-full overflow-y-auto"}>
          <WidgetMenuBar>
            <TrashDropZone accept={[DashboardLayoutType.WIDGET, DashboardLayoutType.ROW]} onDrop={handleTrashDrop} />
          </WidgetMenuBar>
        </div>
        <div className={"ml-40 flex-1 border bg-white h-full overflow-y-scroll hide-scrollbar"}>
          <div className={"max-w-full"}>
            {rows.map(({ widgets, id }, i) => {
              return (
                <div key={id}>
                  <div className={"w-full pr-2 pl-12"}>
                    <DropZone accept={[DashboardLayoutType.ROW, DashboardLayoutType.WIDGET, DashboardLayoutType.MENU_ITEM]} className={"w-full border border-dashed border-gray-300 my-2 h-6"} onDrop={handleSectionLevelDrop(i)} />
                  </div>
                  <EditableRow
                    id={id}
                    widgets={widgets}
                    onChange={(widgets): void => {
                      const updatedRows = updateItemInArray(i, rows, { id, widgets });
                      onChange(updatedRows.filter(({ widgets }) => widgets.length > 0));
                    }}
                  />
                </div>
              );
            })}
            <div className={"mx-2"}>
              <DropZone accept={[DashboardLayoutType.ROW, DashboardLayoutType.WIDGET, DashboardLayoutType.MENU_ITEM]} className={"w-full border border-dashed border-gray-300 text-muted h-12 my-2"} text={"Drag and drop a widget here"} onDrop={handleSectionLevelDrop(rows.length)} />
            </div>
          </div>
        </div>
      </div>
      {deviceDashboard && widgetForm && (
        <AddWidgetModal
          deviceId={deviceDashboard.deviceId}
          fromDate={deviceDashboard.fromDate}
          toDate={deviceDashboard.toDate}
          parameters={deviceDashboard.parameters}
          formType={widgetForm.formType}
          expressions={widgetForm.expressions}
          label={widgetForm.label}
          type={widgetForm.type}
          onCancel={clearForm}
          onSubmit={(widget): void => {
            if (index !== -1 && ACTION_TYPES.ADD) {
              onChange(insertItemIntoArray(index, rows, createNewRow([{ ...widget, metrics: [], id: uniqueId() }])));
              clearForm();
            }
          }}
        />
      )}
    </DndProvider>
  );
};

type FormType = {
  formType: ACTION_TYPES;
};

type EditableWidgetType = {
  id: string;
  label: string;
  type: ChartWidgetType;
  expressions: Array<string>;
};

type WidgetFormType = FormType & EditableWidgetType;

type EditableRowType = {
  id: string;
  widgets: Array<EditableWidgetType>;
};

type EditableSectionType = {
  rows: Array<EditableRowType>;
  onChange: (rows: Array<EditableRowType>) => void;
};

type clearFormType = () => void;
type handleSectionLevelDropType = (index: number) => ({ id, type, data }: DraggableType) => void;
type handleTrashDropType = ({ id, type, data }: DraggableType) => void;
