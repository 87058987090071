import React, { FC, useRef, useState } from "react";
import { Avatar, Button, Collapse, Divider, Form, FormInstance, Input, Radio, Select, Space, Tabs } from "antd";
import { CONDITION_OPTIONS, getAlertRuleDescription } from "../../utils/alerts/alert";
import { DeviceParameterType, MonitoringRuleSetDetailsType, MonitoringRuleType } from "../../utils/types/types";
import { AGGREGATIONS } from "../../utils/metric";
import { getPopupContainer } from "../../utils/general/common";
import { filterSortWithTitle, valueOrDefault } from "../../utils/general/helper";
import { IconButton } from "../general/IconButton";
import { assocPath, path } from "ramda";
import { AggregationType, ParameterDataType } from "../../utils/types/enums";

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const parseConditionRule = (data: MonitoringRuleType) => data && <span className={"px-2 py-1 text-xs rounded-sm bg-primary-50 text-primary-500 border border-primary-200"}>{getAlertRuleDescription(data.primaryMetric, data.secondaryMetric, data.threshold, data.condition)}</span>;

export const RuleConfigurationForm: FC<RuleConfigurationFormType> = function ({ form, parameters, onTabSwitch, formValues, setFormValues }: RuleConfigurationFormType) {
  const ref = useRef(null);
  const [currentDataType1, setCurrentDataType1] = useState<ParameterDataType | null>(null);
  const [currentDataType2, setCurrentDataType2] = useState<ParameterDataType | null>(null);

  const isDisabled: disabledAggregationType = (dataType, aggregationType) => {
    return dataType === ParameterDataType.STRING && aggregationType !== AggregationType.LATEST;
  };

  return (
    <div ref={ref}>
      <Form.List name={["ruleSet", "rules"]}>
        {(rules, { add, remove }) => {
          return (
            <>
              {rules.map((rule, index) => {
                return (
                  <Collapse key={index} accordion={true} className={"my-2"} defaultActiveKey={["1"]}>
                    <Panel
                      header={
                        <div className={"flex flex-row items-center gap-x-2"}>
                          Condition {index + 1}&nbsp;{formValues !== null && parseConditionRule(path(["ruleSet", "rules", index], valueOrDefault({}, formValues)) as MonitoringRuleType)}
                        </div>
                      }
                      key="1"
                      className={"bg-grey-50"}
                      extra={
                        <IconButton
                          className={"-mt-0.5"}
                          size={"sm"}
                          type={"danger"}
                          icon={"trash"}
                          onClick={(e) => {
                            e.stopPropagation();
                            remove(index);
                          }}
                        />
                      }
                    >
                      <div className={"p-5"}>
                        <Space className={"mb-4 gap-2"}>
                          <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
                            1
                          </Avatar>
                          <div className={"text-sm font-medium"}>Metric</div>
                        </Space>
                        <div className={"ml-10 flex flex-row gap-6"}>
                          <Form.Item labelAlign={"left"} label={<span className={"text-xs text-gray-400"}>Parameter</span>} name={[index, "primaryMetric", "parameter", "tag"]} rules={[{ required: true, message: "Parameter is required" }]}>
                            <Select
                              getPopupContainer={getPopupContainer}
                              className={"w-96"}
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp={"title"}
                              filterSort={filterSortWithTitle}
                              onChange={(v, w): void => {
                                form.setFieldsValue(assocPath(["ruleSet", "rules", index, "primaryMetric", "parameter", "dataType"], w.type, form.getFieldsValue()));
                                setFormValues(assocPath(["ruleSet", "rules", index, "primaryMetric", "parameter", "dataType"], w.type, form.getFieldsValue()));
                                setCurrentDataType1(w.type);
                              }}
                            >
                              {parameters.map(({ id, tag, name = tag, unit, dataType }) => {
                                return (
                                  <Option title={name + tag + valueOrDefault("", unit)} key={id} value={tag} type={dataType}>
                                    <div className={"flex flex-col items-start p-0 m-0 w-full"}>
                                      <span className={"flex flex-row items-center w-full"}>
                                        {tag}
                                        <span className={"ml-auto font-extralight text-xs"}>
                                          {valueOrDefault("", unit)}&nbsp;({dataType})
                                        </span>
                                      </span>
                                      <span className={"font-extralight text-xs"}>{name}</span>
                                    </div>
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item labelAlign={"left"} label={<span className={"text-xs text-gray-400"}>Aggregation</span>} name={[index, "primaryMetric", "aggregation"]} rules={[{ required: true, message: "Aggregation is required" }]}>
                            <Select getPopupContainer={getPopupContainer} className={"w-80"}>
                              {AGGREGATIONS.map(({ key, value }, index) => (
                                <Option key={index} value={key} disabled={isDisabled(currentDataType1, key)}>
                                  {value}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <Divider className={"mt-0"} />
                        <Space className={"mb-4"}>
                          <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
                            2
                          </Avatar>
                          <div className={"text-sm font-medium"}>Operation</div>
                        </Space>
                        <div className={"ml-10"}>
                          <Form.Item name={[index, "condition"]} label="" rules={[{ required: true, message: "Condition is required" }]}>
                            <Radio.Group className={`flex flex-row gap-4`}>
                              {CONDITION_OPTIONS.map(({ key, value, label }) => (
                                <Radio.Button key={key} value={key} className={`flex items-center justify-center w-full h-14`}>
                                  <div className={"my-0 py-0 text-xl text-center font-extralight"}>{value}</div>
                                  <div className={"my-0 py-0 text-xs"}>{label}</div>
                                </Radio.Button>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        <Divider />
                        <Space className={"mb-2"}>
                          <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
                            3
                          </Avatar>
                          <div className={"text-sm font-medium"}>Threshold</div>
                        </Space>
                        <div className={"ml-10"}>
                          <Tabs
                            size={"small"}
                            destroyInactiveTabPane={true}
                            onChange={(key) => {
                              onTabSwitch(index, key);
                            }}
                            defaultActiveKey={form.getFieldValue(["ruleSet", "rules", index, "threshold"]) === undefined ? "secondaryMetric" : "threshold"}
                          >
                            <TabPane tab="Dynamic" key="secondaryMetric">
                              <div className={"flex flex-row gap-6"}>
                                <Form.Item
                                  shouldUpdate={true}
                                  labelAlign={"left"}
                                  label={<span className={"text-xs text-gray-400"}>Parameter</span>}
                                  name={[index, "secondaryMetric", "parameter", "tag"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Parameter is required",
                                    },
                                  ]}
                                >
                                  <Select
                                      getPopupContainer={(trigger) => (ref ? ref.current : trigger)}
                                      className={"w-96"}
                                      showSearch
                                      placeholder="Search to Select"
                                      optionFilterProp={"title"}
                                      filterSort={filterSortWithTitle}
                                      onChange={(v, w): void => {
                                        form.setFieldsValue(assocPath(["ruleSet", "rules", index, "secondaryMetric", "parameter", "dataType"], w.type, form.getFieldsValue()));
                                        setFormValues(assocPath(["ruleSet", "rules", index, "secondaryMetric", "parameter", "dataType"], w.type, form.getFieldsValue()));
                                        setCurrentDataType2(w.type);
                                      }}
                                  >
                                    {parameters.map(({ id, tag, name = tag, unit, dataType }) => {
                                      return (
                                        <Option title={name + tag + valueOrDefault("", unit)} key={id} value={tag} type={dataType}>
                                          <div className={"flex flex-col items-start p-0 m-0 w-full"}>
                                            <span className={"flex flex-row items-center w-full"}>
                                              {name}
                                              <span className={"ml-auto text-gray-400 text-xs"}>
                                                {valueOrDefault("", unit)}&nbsp;({dataType})
                                              </span>
                                            </span>
                                            <span className={"text-gray-400 text-xs"}>{tag}</span>
                                          </div>
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  shouldUpdate={true}
                                  labelAlign={"left"}
                                  label={<span className={"text-xs text-gray-400"}>Aggregation</span>}
                                  name={[index, "secondaryMetric", "aggregation"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Aggregation is required",
                                    },
                                  ]}
                                >
                                  <Select getPopupContainer={(trigger) => (ref ? ref.current : trigger)} className={"w-80"} placeholder={"Select Aggregation"}>
                                    {AGGREGATIONS.map(({ key, value }, index) => (
                                      <Option key={index} value={key} disabled={isDisabled(currentDataType2, key)}>
                                        {value}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </TabPane>
                            <TabPane tab="Static" key="threshold">
                              <Form.Item shouldUpdate={true} name={[index, "threshold", "value"]} label={<span className={"text-xs text-gray-400"}>Threshold</span>} rules={[{ required: true, message: "Threshold is required" }]}>
                                <Input className={"w-72"} placeholder={"Threshold Value"} />
                              </Form.Item>
                            </TabPane>
                          </Tabs>
                        </div>
                        <Divider className={"mt-0"} />
                        <Space className={"mb-4"}>
                          <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
                            4
                          </Avatar>
                          <div className={"text-sm font-medium"}>Time Range</div>
                        </Space>
                        <div className={"ml-10"}>
                          <Form.Item name={[index, "timeRange"]} labelAlign={"left"} label={<span className={"text-xs text-gray-400"}>Time Range (seconds)</span>} rules={[{ required: true, message: "Time range is required" }]}>
                            <Input type={"number"} className={"w-72"} />
                          </Form.Item>
                        </div>
                        {/*<Widget*/}
                        {/*  outerClassName={"border p-8 mt-8"}*/}
                        {/*  deviceId={deviceId}*/}
                        {/*  from={moment(Date.now()).subtract(1, "hour").valueOf()}*/}
                        {/*  to={moment(Date.now()).valueOf()}*/}
                        {/*  expressions={formValues ? convertMetricsToExpressions2([formValues.ruleSet.rules[index].primaryMetric, formValues.ruleSet.rules[index].secondaryMetric], true) : []}*/}
                        {/*  // yThreshold={}*/}
                        {/*  type={ChartWidgetType.LINE_PLOT}*/}
                        {/*  title={false}*/}
                        {/*  height={150}*/}
                        {/*  allowExport={false}*/}
                        {/*  colors={["#39bf47", "#d02525"]}*/}
                        {/*/>*/}
                      </div>
                    </Panel>
                  </Collapse>
                );
              })}
              <Button
                onClick={() => {
                  add();
                }}
                className={"w-full h-10 my-2 bg-grey-50 border border-gray-200 text-center text-dark-400 hover:text-dark-500 hover:shadow-sm"}
              >
                Add Condition
              </Button>
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

type RuleConfigurationFormType = {
  deviceId: string;
  parameters: Array<DeviceParameterType>;
  onTabSwitch: (i: number, key: string) => void;
  form: FormInstance;
  formValues: MonitoringRuleSetDetailsType | null;
  setFormValues: (values: MonitoringRuleSetDetailsType) => void;
};

type disabledAggregationType = (widgetType: ParameterDataType | null, dataType: AggregationType) => boolean;

