import React, { FC } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import { PageLayout } from "../layout/PageLayout";
import { PageHeader } from "../general/PageHeader";
import { ProfileDetails } from "./ProfileDetails";
import { ProfilesList } from "./ProfilesList";

export const Profiles: FC = () => {
  const { path } = useRouteMatch();

  return (
    <PageLayout loadingText={"Loading Profiles..."} header={<PageHeader title={"Profiles"} />}>
      <Switch>
        <Route exact path={path}>
          <ProfilesList />
        </Route>
        <Route path={`/profiles/:profileId`}>
          <ProfileDetails />
        </Route>
      </Switch>
    </PageLayout>
  );
};
