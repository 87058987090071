import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getDevice } from "../services/services";
import { DeviceType } from "../utils/types/types";
import { ResponseType } from "../utils/types/uiTypes";
import { MENU_ITEMS } from "../utils/deviceDetails";
import { MenuNavigation } from "../components/layout/MenuNavigation";
import { DeviceDashboardProvider } from "../context/DeviceDashboardContext";
import moment from "moment";
import Title from "antd/lib/typography/Title";
import { usePageTitle } from "../custom-hooks/usePageTitle";
import { Button, Tooltip } from "antd";
import { FundViewOutlined } from "@ant-design/icons";

export const DeviceDetails: FC = function () {
  usePageTitle("Device");
  const { menu, deviceId }: ParamsType = useParams();
  const history = useHistory();
  const [device, setDevice] = useState<DeviceType | null>(null);

  const getDeviceData: getDeviceDataType = (id) => {
    getDevice({
      segments: {
        id,
      },
    })
      .then(({ data }: ResponseType<DeviceType>) => {
        setDevice(data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDeviceData(deviceId);
  }, [deviceId]);

  return (
    <DeviceDashboardProvider deviceId={deviceId} fromDate={moment().subtract(1, "h").valueOf()} toDate={moment().valueOf()}>
      {
        device && <MenuNavigation
          defaultValue={"connections"}
          selectedKeys={[menu]}
          menu={MENU_ITEMS(device.model)}
          title={
            <div className={"flex flex-row items-center justify-between gap-2"}>
              <Title level={5} className={"text-dark-700 mb-0"}>
                {device.name}
              </Title>
              <Button className={'px-0'}
                      type={"link"}
                      onClick={(): void => {
                        history.push(`/devices/${deviceId}/metrics`);
                      }}
                      icon={<FundViewOutlined />}
              >
                View Device Metrics
              </Button>
            </div>
          }
          routePath={(link): void => history.push(`/devices/${deviceId}/${link.key}`)}
        />
      }
    </DeviceDashboardProvider>
  );
};

type ParamsType = {
  deviceId: string;
  menu: string;
};

type getDeviceDataType = (deviceId: string) => void;
