import React, { FC, useContext, useState } from "react";
import { useDrag } from "react-dnd";
import { DropZone } from "./DropZone";
import { DraggableType } from "../../../../utils/dashboardCustomization";
import { EditableWidget } from "./EditableWidget";
import { findIndex, propEq } from "ramda";
import { insertItemIntoArray, removeItemFromArray, updateItemInArray } from "../../../../utils/general/helper";
import { ACTION_TYPES } from "../../../../utils/types/uiTypes";
import { DashboardLayoutType, ChartWidgetType, AggregationType } from "../../../../utils/types/enums";
import { uniqueId } from "../../../../utils/general/uniqueId";
import { AddWidgetModal } from "./AddWidgetModal";
import { DeviceDashboardContext } from "../../../../context/DeviceDashboardContext";

const GRID_CSS = {
  1: "grid-cols-1",
  2: "grid-cols-2",
  3: "grid-cols-3",
  4: "grid-cols-4",
  5: "grid-cols-5",
  6: "grid-cols-6",
  7: "grid-cols-7",
  8: "grid-cols-8",
};

export const EditableRow: FC<EditableRowType> = function ({ id, widgets, onChange }: EditableRowType) {
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const [index, setIndex] = useState<number>(-1);
  const [widgetForm, setWidgetForm] = useState<WidgetFormType | null>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DashboardLayoutType.ROW,
    item: {
      id,
      type: DashboardLayoutType.ROW,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const clearForm: clearFormType = () => {
    setWidgetForm(null);
    setIndex(-1);
  };
  const handleRowLevelDrop: handleRowLevelDropType =
    (index) =>
    ({ id, type, data }): void => {
      switch (type) {
        case DashboardLayoutType.WIDGET: {
          const updatedWidgets = insertItemIntoArray(index, widgets, { ...data, id: uniqueId() });
          onChange(updatedWidgets);
          return;
        }
        case DashboardLayoutType.MENU_ITEM:
          setWidgetForm({ formType: ACTION_TYPES.ADD, type: ChartWidgetType[id as keyof typeof ChartWidgetType], id: uniqueId(), label: "", expressions: [] });
          setIndex(index);
          return;
      }
    };

  return (
    <div ref={drag} className={`py-px bg-white ${isDragging ? "opacity-50 cursor-grabbing" : "opacity-100 cursor-grab"}`}>
      <div className={`grid grid-cols-${widgets.length}`}>
        {widgets.map(({ id, label, type, expressions }, i) => {
          return (
            <div key={id} className={"flex flex-row"}>
              {i === 0 && <div className={'inline-flex h-full w-10 items-center justify-center'}>
                <i className="fas fa-bars text-gray-300 hover:text-primary-500 text-xl"></i>
              </div>
              }
              <DropZone className={"w-3 border border-dashed border-gray-300 mx-2"} accept={[DashboardLayoutType.WIDGET, DashboardLayoutType.MENU_ITEM]} onDrop={handleRowLevelDrop(i)} />
              <div className={"flex-1 grid grid-cols-1"}>
                <div className={"col-auto"}>
                  <EditableWidget
                    id={id}
                    label={label}
                    type={type}
                    expressions={expressions}
                    onDelete={(id): void => {
                      const currentWidgetIndex = findIndex(propEq("id", id))(widgets);
                      const updatedWidgets = removeItemFromArray(currentWidgetIndex, widgets);
                      onChange(updatedWidgets);
                    }}
                    onEdit={(): void => {
                      setWidgetForm({ formType: ACTION_TYPES.EDIT, type, expressions, label, id });
                      setIndex(i);
                    }}
                  />
                </div>
              </div>
              {i === widgets.length - 1 && <DropZone className={"w-3 border border-dashed border-gray-300 mx-2"} accept={[DashboardLayoutType.WIDGET, DashboardLayoutType.MENU_ITEM]} onDrop={handleRowLevelDrop(widgets.length)} />}

            </div>
          );
        })}
      </div>
      {deviceDashboard && widgetForm && (
        <AddWidgetModal
          deviceId={deviceDashboard.deviceId}
          fromDate={deviceDashboard.fromDate}
          toDate={deviceDashboard.toDate}
          type={widgetForm.type}
          expressions={widgetForm.expressions}
          label={widgetForm.label}
          parameters={deviceDashboard.parameters}
          formType={widgetForm.formType}
          onCancel={clearForm}
          onSubmit={(widget): void => {
            if (index !== -1 && widgetForm?.formType === ACTION_TYPES.ADD) {
              onChange(insertItemIntoArray(index, widgets, { ...widget, id: uniqueId() }));
              clearForm();
            }
            if (index !== -1 && widgetForm?.formType === ACTION_TYPES.EDIT) {
              const { id } = widgetForm;
              onChange(updateItemInArray(index, widgets, { id, label: widget.label, type: widget.type, expressions: widget.expressions }));
              clearForm();
            }
          }}
        />
      )}
    </div>
  );
};

type FormType = {
  formType: ACTION_TYPES;
};

type EditableWidgetType = {
  id: string;
  label: string;
  type: ChartWidgetType;
  expressions: Array<string>;
};

type WidgetFormType = FormType & EditableWidgetType;

type EditableRowType = {
  id: string;
  widgets: Array<EditableWidgetType>;
  onChange: (widgets: Array<EditableWidgetType>) => void;
};

type clearFormType = () => void;
type handleRowLevelDropType = (index: number) => ({ id, type, data }: DraggableType) => void;
