import React, { FC, ReactNode, useEffect, useState } from "react";
import { Layout, Modal } from "antd";
import { SideBar } from "./SideBar";
import { reflowAllCharts } from "../../utils/charts";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
const { Content } = Layout;

export const MainLayout: FC<MainLayoutType> = function ({ children }: MainLayoutType) {
  const history = useHistory();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const [collapsed, setCollapsed] = useState(true);
  const [hideNavigation, setHideNavigation] = useState(false);

  const onCollapse: onCollapseType = (o) => {
    setCollapsed(o);
    setTimeout(() => {
      reflowAllCharts();
    }, 500);
  };

  useEffect(() => {
    const value = urlSearchParams.get("hideNavigation")?.toLowerCase() === 'true';
    history.listen(() => Modal.destroyAll());
    localStorage.setItem('hideNavigation', String(value));
    setHideNavigation(value);
  }, []);

  return (
    <div className={"min-h-screen"}>
      <Layout className={"bg-white"}>
        {!hideNavigation && <SideBar collapsed={collapsed} onCollapse={onCollapse} />}
        <Layout className={"bg-white"}>
          <Content>
            <div>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

type MainLayoutType = {
  children: ReactNode;
};

type onCollapseType = (collapse: boolean) => void;
