import React, { FC, useContext, useEffect } from "react";
import { logout } from "../services/services";
import { AuthenticationContext } from "../context/AuthenticationContext";
import { ResponseType } from "../utils/types/uiTypes";

export const Logout: FC = function () {
  const { setAuthentication } = useContext(AuthenticationContext);

  useEffect(() => {
    logout({})
      .then(() => {
        setAuthentication(null);
      })
      .catch(({ data }: ResponseType) => {
        console.log(data);
      });
  }, []);

  return <></>;
};
