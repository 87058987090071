import React, { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { LOGIN_PAGE_URL } from "../utils/routes/config";
import { usePageTitle } from "../custom-hooks/usePageTitle";

export const ExternalPageNotFound: FC = function () {
  usePageTitle();
  const history = useHistory();
  useEffect(() => {
    history.push(LOGIN_PAGE_URL);
  }, []);
  return <></>;
};
