import React, { FC, ReactNode, useContext, useState } from "react";
import { Table } from "antd";
import { DeviceDashboardContext } from "../../../context/DeviceDashboardContext";
import { usePageTitle } from "../../../custom-hooks/usePageTitle";
import { PageLayout } from "../../layout/PageLayout";
import { SearchBar } from "../../general/SearchBar";
import { DeviceParameterType } from "../../../utils/types/types";
import { TABLE_PAGINATION_STYLE } from "../../../utils/general/styles";
import Paragraph from "antd/es/typography/Paragraph";

const columns = [
  {
    title: <span className={"text-xs text-gray-500"}>Tag</span>,
    key: "tag",
    dataIndex: "tag",
    width: "w-56",
    className: "text-xs xl:text-sm",
    render: function getTag(tag: string): ReactNode {
      return (
        <Paragraph copyable className={"flex flex-row gap-2"}>
          <div className={"max-w-[90%] truncate"}>{tag} </div>
        </Paragraph>
      );
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Name</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "name",
    key: "name",
    width: "30%",
    render: function getParameterDetails(name: string): ReactNode {
      return (
        <div className={"overflow-hidden truncate"}>
          <span>{name}</span>
        </div>
      );
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Unit</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "unit",
    key: "unit",
    width: "17.5%",
    render: function getUnit(unit = ""): string {
      return unit.replace("_", " ");
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Data Type</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "dataType",
    key: "dataType",
    width: "17.5%",
  },
];

export const DeviceParameters: FC = function () {
  usePageTitle("Parameters");
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const [query, setQuery] = useState("");

  const filterParams = (): Array<DeviceParameterType> | undefined => {
    return deviceDashboard?.parameters?.filter((param) =>
      query
        ? Object.values(param)
            .map((o) => o?.toString().toLowerCase())
            .find((i) => i.includes(query.toLowerCase()))
        : true
    );
  };
  return (
    <PageLayout height={"full"}>
      <>
        <div className={"flex justify-start mb-4 gap-2"}>
          <SearchBar size={"SMALL"} placeholder={"Search Parameters"} onSearch={setQuery} />
        </div>
        <div className={"border shadow-xs max-h-full overflow-y-auto hide-scrollbar bg-white"}>
          <Table rowKey={"id"} bordered={false} sticky={true} columns={columns} dataSource={filterParams()} scroll={{ x: 1200 }} pagination={TABLE_PAGINATION_STYLE} />
        </div>
      </>
    </PageLayout>
  );
};
