import React, { FC, ReactElement, ReactNode } from "react";
import { MetricValueIconMap } from "./MetricValueIconMap";
import { MetricUnitIconMap } from "./MetricUnitIconMap";
import { getUnits } from "../../../utils/widgets";
import { BulbOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { getAbbreviatedHumanizedElapsedTime } from "../../../utils/general/dates";
import { displayDashAsDefault } from "../../../utils/general/helper";
import { MetricTagIconMap } from "./MetricTagIconMap";

const getBackgroundColor: getColorType = (key) => {
  switch (key) {
    case "GREEN":
    case "ACTIVE":
    case "OK":
    case "Idle":
    case "ON":
    case "AVAILABLE":
    case "NOT_LOCKED":
      return "bg-success-500";
    case "YELLOW":
      return "bg-warning-500";
    case "RED":
    case "ALERT":
    case "INACTIVE":
    case "CLOSED":
    case "UNAVAILABLE":
    case "NOT AVAILABLE":
    case "Stop":
    case "OFF":
      return "bg-danger-500";
    default:
      return "";
  }
};

const getTextColor: getColorType = (key) => {
  switch (key) {
    case "GREEN":
    case "ACTIVE":
    case "OK":
    case "Idle":
    case "ON":
    case "AVAILABLE":
    case "NOT_LOCKED":
      return "text-success-500";
    case "YELLOW":
      return "text-warning-500";
    case "RED":
    case "ALERT":
    case "INACTIVE":
    case "CLOSED":
    case "UNAVAILABLE":
    case "NOT AVAILABLE":
    case "Stop":
    case "OFF":
      return "text-danger-500";
    default:
      return "text-gray-500";
  }
};
export const SingleValue: FC<SingleValueType> = function ({ tag = "", title = "", value = "", units = "" }: SingleValueType) {
  return (
    <>
      <div className={`font-medium text-xs text-left truncate overflow-ellipsis`}>
        <MetricTagIconMap tag={tag} value={title} />
      </div>
      <div className={"mt-4 inline-flex items-center "}>
        <div className={`font-bold text-left sm:text-xs md:text-sm lg:text-xl ${getTextColor(value)}`}>
          {units === "SECOND" ? (isNaN(Number(value)) ? displayDashAsDefault(value) : getAbbreviatedHumanizedElapsedTime(Number(value) * 1000)) : value}
          {units !== "SECOND" && <span className={"ml-2 text-gray-500 font-thin sm:text-xs md:text-sm lg:text-base"}>{getUnits(units)}</span>}
        </div>
        <div className={"sm:text-xs md:text-sm lg:text-xl"}>
          <MetricValueIconMap value={value} />
        </div>
      </div>
      {units === "CELSIUS" && (
        <div className={"absolute top-0 right-5 h-full text-4xl"}>
          <div className={"flex h-full items-center"}>
            <MetricUnitIconMap value={value} unit={units} />
          </div>
        </div>
      )}
      <div className={`absolute top-0 right-0 w-4 h-full ${getBackgroundColor(value)}`}></div>
    </>
  );
};

type SingleValueType = {
  tag?: string;
  title?: string;
  value?: string;
  units?: string;
};
type getColorType = (key: string) => string;
