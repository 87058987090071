import React, { FC } from "react";
import {Link} from "react-router-dom";
import {Tooltip} from "antd";
import {getPopupContainer} from "../../utils/general/common";

export const AlertBadge: FC<AlertBadgeType> = function ({ id, alerts = 0 }: AlertBadgeType) {
    return (
        <>
            {alerts > 0 && (
                <div className={"hidden md:block absolute top-0 right-0 -mt-4 -mr-3"}>
                    <Link to={`/devices/${id}/monitors`}>
                        <Tooltip title={`${alerts} Alert${alerts > 1 ? "s" : ""}`} placement={"bottom"} getPopupContainer={getPopupContainer}>
                            <div className={"relative"}>
                                <i className="fas fa-exclamation-triangle text-danger-500 text-2xl"></i>
                                <div className={"absolute top-0 -right-1.5 inline-flex gap-x-1 items-center justify-center text-[8px] text-white bg-danger-500 border-danger-600 shadow-xs rounded-full h-3.5 w-3.5"}>{alerts}</div>
                            </div>
                        </Tooltip>
                    </Link>
                </div>
            )}
        </>
    );
};

type AlertBadgeType = {
    id: string,
    alerts?: number
};
