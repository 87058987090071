import React, { DependencyList, MutableRefObject, ReactElement, useEffect, useRef } from "react";
import { valueOrDefault } from "../utils/general/helper";
import { NoParamVoidType } from "../utils/types/uiTypes";

export const useAutoRefresh: useAutoRefreshType = (callback, enabled = true, dependencies = [], delay = 60000) => {
  const savedCallback = useRef(callback);
  const timer = useRef<NodeJS.Timer>();

  const executeInterval: executeIntervalType = (newDelay) => {
    timer.current = setInterval(() => {
      savedCallback.current();
    }, valueOrDefault(delay, newDelay));
  };

  const terminateInterval: terminateIntervalType = ({ current }) => {
    current && clearInterval(current);
    timer.current = undefined;
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (enabled) {
      savedCallback.current();
      executeInterval();
    }
    return (): void => terminateInterval(timer);
  }, dependencies);

  return <div />;
};

type terminateIntervalType = (timer: MutableRefObject<NodeJS.Timer | undefined>) => void;
type executeIntervalType = (newDelay?: number) => void;
type useAutoRefreshType = (callback: NoParamVoidType, enabled?: boolean, dependencies?: DependencyList, delay?: number) => ReactElement;
