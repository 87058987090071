import { DELETE, GET, POST, PUT } from "./request";

/** General APIS **/
export const login = POST(`/api/v2/auth`);
export const logout = DELETE(`/api/v2/auth`);
export const getAuth = GET(`/api/v2/auth/user`);
export const getBuildInfo = GET(`/api/v2/info`);

/** Metrics Dashboard APIS **/
export const getDeviceDashboard = GET(({ id }) => `/api/v2/devices/${id}/dashboards`);
export const saveDashboard = PUT(({ deviceId, id }) => `/api/v2/devices/${deviceId}/dashboards/${id}`);
export const getMetricData = GET(({ id }) => `/api/v2/devices/${id}/metrics`);

/** Device APIS **/
export const getDevices = GET(`/api/v2/devices`);
export const createDevice = POST(`/api/v2/devices`);
export const getDevice = GET(({ id }) => `/api/v2/devices/${id}`);
export const getDeviceJobs = GET(({ id }) => `/api/v2/devices/${id}/jobs`);
export const getDeviceSummary = GET(({ id }) => `/api/v2/devices/${id}/summary`);
export const getDeviceSummaries = GET(`/api/v2/devices/summaries`);
export const getDeviceMessages = GET(({ id }) => `/api/v2/devices/${id}/logs`);
export const getDeviceParameters = GET(({ id }) => `/api/v2/devices/${id}/parameters`);
export const getDeviceIntegrations = GET(({ deviceId }) => `/api/v2/devices/${deviceId}/integrations`);
export const getJobImagesLayer = GET(({ jobId, index }) => `/api/v2/jobs/${jobId}/images?layerIndex=${index}`);
export const getJobImages = GET(({ jobId }) => `/api/v2/jobs/${jobId}/images`);

/** Manufacturer and Model APIS **/
export const getModels = GET(`/api/v2/models`);
export const getManufacturers = GET(`/api/v2/manufacturers`);
export const getModelsByManufacturer = GET(({ id }) => `/api/v2/manufacturers/${id}/models`);

/** Gateway APIS **/
export const getGateways = GET(`/api/v2/gateways`);
export const addGateway = POST(`/api/v2/gateways`);
export const updateGateway = PUT(({ id }) => `/api/v2/gateways/${id}`);
export const deleteGateway = DELETE(({ id }) => `/api/v2/gateways/${id}`);
export const addGatewayV2 = POST(`/api/v2/gateways/config`);
export const getGatewayConnections = GET(({ id }) => `/api/v2/gateways/${id}/connections`);

/** Connection APIS **/
export const getDeviceConnection = GET(({ id, connectionId }) => `/api/v2/devices/${id}/connections/${connectionId}`);
export const getDeviceConnections = GET(({ id }) => `/api/v2/devices/${id}/connections`);

/** Agent APIS **/
export const updateAgent = PUT(({ deviceId, id }) => `/api/v2/devices/${deviceId}/agents/${id}`);
export const deleteAgent = DELETE(({ id }) => `/api/v2/machine-connectivity/agents/${id}`);

/** Monitors **/
export const getAllMonitors = GET(() => `/api/v2/monitors`);
export const getDeviceMonitors = GET(({ deviceId }) => `/api/v2/devices/${deviceId}/monitors`);
export const addMonitor = POST(({ deviceId }) => `/api/v2/devices/${deviceId}/monitors`);
export const getMonitor = GET(({ deviceId, id }) => `/api/v2/devices/${deviceId}/monitors/${id}`);
export const updateMonitor = PUT(({ deviceId, id }) => `/api/v2/devices/${deviceId}/monitors/${id}`);
export const deleteMonitor = DELETE(({ deviceId, id }) => `/api/v2/devices/${deviceId}/monitors/${id}`);
export const getMonitorResults = GET(({ deviceId, id, from, to }) => `/api/v2/devices/${deviceId}/monitors/${id}/results?from=${from}&to=${to}`);
export const getMonitorExecution = GET(({ deviceId, id, from }) => `/api/v2/devices/${deviceId}/monitors/${id}/execute?from=${from}`);

/** Profiles **/
export const getAllProfiles = GET(() => `/api/v2/profiles`);
export const addProfile = POST(() => `/api/v2/profiles`);
export const getProfile = GET(({ profileId }) => `/api/v2/profiles/${profileId}`);
export const updateProfile = PUT(({ profileId }) => `/api/v2/profiles/${profileId}`);
export const deleteProfile = DELETE(({ profileId }) => `/api/v2/profiles/${profileId}`);
export const getAllProfileItems = GET(({ profileId }) => `/api/v2/profiles/${profileId}/items`);
export const addProfileItem = POST(({ profileId }) => `/api/v2/profiles/${profileId}/items`);
export const getProfileItem = POST(({ profileId, itemId }) => `/api/v2/profiles/${profileId}/items/${itemId}`);
export const updateProfileItem = PUT(({ profileId, itemId }) => `/api/v2/profiles/${profileId}/items/${itemId}`);
export const deleteProfileItem = DELETE(({ profileId, itemId }) => `/api/v2/profiles/${profileId}/items/${itemId}`);
