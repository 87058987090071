import React, {FC, ReactElement} from "react";
import {RequestParameterType} from "../../utils/types/enums";
import {Button, Form, Input, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";

const { Option } = Select;

const getFormFieldType: getFormFieldType = (type, values) => {
    switch (type) {
        case RequestParameterType.TEXT:
            return <Input />;
        case RequestParameterType.SELECT_SINGLE:
            return (
                <Select>
                    {values.map((v) => (
                        <Option key={v} value={v}>
                            {v}
                        </Option>
                    ))}
                </Select>
            );
        case RequestParameterType.SELECT_MULTIPLE:
            return (
                <Select mode={"multiple"}>
                    {values.map((v) => (
                        <Option key={v} value={v}>
                            {v}
                        </Option>
                    ))}
                </Select>
            );
        case RequestParameterType.CREDENTIAL:
            return <Input.Password autoComplete={'new-password'}  />;
        case RequestParameterType.CREDENTIAL_TEXT:
            return <Input.TextArea />;
        case RequestParameterType.FILE:
            return (
                <Upload>
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
            );
    }
}
export const ConnectionFormField: FC<ConnectionFormFieldType> = function ({ type = RequestParameterType.TEXT, values, name, required }: ConnectionFormFieldType) {

    return  <Form.Item key={name} name={name} label={<span className={"text-xs text-gray-400"}>{name}</span>} rules={[{ required: required }]}>
        {getFormFieldType(type, values)}
    </Form.Item>
};

type ConnectionFormFieldType = {
    type: RequestParameterType,
    values: any[]
    name: string;
    required: boolean;
};

type getFormFieldType = (type: RequestParameterType, values: any[]) => ReactElement;