import React, { FC, memo, useContext } from "react";
import { useDrag } from "react-dnd";
import { Widget } from "../Widget";
import { DeviceDashboardContext } from "../../../../context/DeviceDashboardContext";
import { DashboardLayoutType, ChartWidgetType } from "../../../../utils/types/enums";

export const EditableWidget: FC<EditableWidgetType> = memo(({ id, label, type, expressions, onDelete, onEdit }: EditableWidgetType) => {
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const [{ isDragging }, drag] = useDrag({
    type: DashboardLayoutType.WIDGET,
    item: {
      id,
      type: DashboardLayoutType.WIDGET,
      data: { id, label, type, expressions },
    },
    end: (item, monitor) => {
      if (item && monitor.getDropResult()) {
        onDelete(id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} className={`w-full h-full group ${isDragging ? "opacity-50" : "opacity-100"}`}>
      {deviceDashboard && <Widget onClick={onEdit} outerClassName={"h-full bg-white border hover:shadow-sm hover:border-blue-200 p-6 cursor-pointer"} height={280} deviceId={deviceDashboard.deviceId} from={deviceDashboard.fromDate} to={deviceDashboard.toDate} expressions={expressions} title={label} type={type} />}
    </div>
  );
});

type EditableWidgetType = {
  id: string;
  label: string;
  type: ChartWidgetType;
  expressions: Array<string>;
  onDelete: (o: string) => void;
  onEdit: () => void;
};
