import React, { FC, ReactNode } from "react";
import { Tooltip } from "antd";
import { DeleteFilled, EditOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { TooltipPlacement } from "antd/lib/tooltip";

const SIZE_STYLE = {
  sm: "h-7 w-7 text-sm",
  md: "h-8 w-8 text-base",
  lg: "h-9 w-9 text-lg",
};

const BUTTON_STYLE = {
  default: "text-gray-400 hover:bg-dark-500",
  primary: "text-primary-500 hover:bg-primary-500",
  warning: "text-warning-600 hover:bg-warning-600",
  danger: "text-danger-500 hover:bg-danger-500",
};

const ICON_TYPE = {
  edit: <EditOutlined />,
  trash: <DeleteFilled />,
  list: <UnorderedListOutlined />,
  device: <i className="fas fa-tachometer-alt" />,
  gear: <i className="fas fa-cogs" />,
  bell: <i className="fas fa-bell" />,
  bellSlashed: <i className="fas fa-bell-slash" />,
};
export const IconButton: FC<IconButtonType> = function ({ className = "", type = "default", size = "md", tooltipText = "", icon = undefined, placement = "bottom", onClick, children }: IconButtonType) {
  return (
    <Tooltip title={tooltipText} placement={placement}>
      <div role={"button"} onClick={onClick} className={`inline-flex items-center justify-center text-center cursor-pointer rounded-full ${className} ${SIZE_STYLE[size]} ${BUTTON_STYLE[type]} hover:bg-opacity-10`}>
        {icon ? ICON_TYPE[icon] : children}
      </div>
    </Tooltip>
  );
};

type IconButtonType = {
  className?: string;
  size?: "sm" | "md" | "lg";
  type: "default" | "primary" | "danger" | "warning";
  icon?: "edit" | "trash" | "list" | "device" | "gear" | "bell" | "bellSlashed" | undefined;
  tooltipText?: string;
  placement?: TooltipPlacement;
  onClick?: (event: any) => void;
  children?: ReactNode;
};
