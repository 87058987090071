import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Button, Drawer, Form, Input, message, Modal, Select, Table, Tag, Tooltip } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { find, findIndex, isNil, propEq } from "ramda";
import moment from "moment";
import { addGateway, deleteGateway, addGatewayV2, getGateways, updateGateway, getBuildInfo } from "../services/services";
import { GatewayInstanceType, InfoType, PaginationContainer } from "../utils/types/types";
import { getHumanizedElapsedTime, getTimeFromNowByUnit } from "../utils/general/dates";
import { ApiOutlined, CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ACTION_TYPES, NoParamVoidType, ResponseType } from "../utils/types/uiTypes";
import { displayDashAsDefault, insertItemIntoArray, removeItemFromArray, updateItemInArray, valueOrDefault } from "../utils/general/helper";
import { PageHeader } from "../components/general/PageHeader";
import { Breakpoint } from "antd/es/_util/responsiveObserve";
import { ConfirmDeleteModal } from "../utils/modals";
import { usePageTitle } from "../custom-hooks/usePageTitle";
import { PageLayout } from "../components/layout/PageLayout";
import { IconButton } from "../components/general/IconButton";
import { AuthenticationContext } from "../context/AuthenticationContext";
import { GatewayConnection } from "../components/gateways/GatewayConnection";
import { FloatingButton } from "../components/general/FloatingButton";
import { TABLE_PAGINATION_STYLE } from "../utils/general/styles";
import { useAutoRefresh } from "../custom-hooks/useAutoRefresh";
import { CustomTag } from "../components/general/CustomTag";

export const Gateways: FC = function () {
  usePageTitle("Gateways");
  const { authentication } = useContext(AuthenticationContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [actionType, setActionType] = useState<ACTION_TYPES | null>(null);
  const [gatewayId, setGatewayId] = useState<string | null>(null);
  const [gateway, setGateway] = useState<GatewayInstanceType | null>(null);
  const [gateways, setGateways] = useState<GatewayInstanceType[]>([]);
  const [version, setVersion] = useState<string>("");

  const columns = [
    {
      title: <span className={"text-xs text-gray-500"}>Gateway</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "name",
      width: "150px",
      fixed: false,
      render: function getGatewayDetails(name: string): ReactNode {
        return (
          <div className={"overflow-hidden truncate"}>
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>UUID</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "uuid",
      width: "200px",
      responsive: ["lg" as Breakpoint],
      render: function getUUID(uuid: string): ReactNode {
        return isNil(uuid) ? (
          <>{displayDashAsDefault(uuid)}</>
        ) : (
          <CustomTag>
            <Paragraph copyable>{uuid}</Paragraph>
          </CustomTag>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>Version</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "version",
      width: "100px",
      render: function getVersion(version: string): ReactNode {
        return displayDashAsDefault(version);
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>Last Synced</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "lastSyncedAt",
      width: "100px",
      render: function getLastSyncDate(date: string): ReactNode {
        return isNil(date) ? (
          <>{displayDashAsDefault(date)}</>
        ) : (
          <Tooltip title={moment(date).format("lll").valueOf()}>
            <span className={`${getTimeFromNowByUnit(date, "minutes") <= 5 ? "text-success-500" : "text-danger-500"}`}>{getHumanizedElapsedTime(date)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>Actions</span>,
      className: "text-xs xl:text-sm",
      key: "actions",
      width: "75px",
      responsive: ["lg" as Breakpoint],
      render: function getActionItems(record: GatewayInstanceType): ReactNode {
        return (
          <div className={"flex flex-row items-right group-hover:opacity-100 opacity-0"}>
            <IconButton
              tooltipText={record?.notificationsEnabled ? "Disable Notifications" : "Enable Notifications"}
              type={record?.notificationsEnabled ? "primary" : "danger"}
              onClick={(): void => {
                record && update({ ...record, notificationsEnabled: !record?.notificationsEnabled }, record?.id);
              }}
              icon={record?.notificationsEnabled ? "bell" : "bellSlashed"}
            />
            <IconButton
              tooltipText={"View Connections"}
              type={"primary"}
              onClick={(): void => {
                setGatewayId(record.id);
              }}
            >
              <ApiOutlined />
            </IconButton>
            <IconButton
              tooltipText={"Edit Gateway"}
              type={"primary"}
              onClick={(): void => {
                form.setFieldsValue(record);
                setActionType(ACTION_TYPES.EDIT);
                setGateway(record);
              }}
              icon={"edit"}
            />
            <IconButton
              tooltipText={"Delete Gateway"}
              type={"danger"}
              onClick={(): void => {
                showDeleteModal(record?.id, record?.name);
              }}
              icon={"trash"}
            />
          </div>
        );
      },
    },
  ];

  const showDeleteModal: showDeleteModalType = (id = "", name = "") => {
    ConfirmDeleteModal("Are you sure you want to delete this gateway?", () => remove(id), <div className={"pt-3"}>Name: {name + " - " + id}</div>);
  };

  const clearForm: clearFormType = () => {
    form.resetFields();
    setGateway(null);
    setLoadingModal(false);
    setActionType(null);
  };

  const addModal = (
    <Modal
      width={700}
      title={"Add Gateway"}
      visible={actionType === ACTION_TYPES.ADD}
      closable={true}
      onCancel={clearForm}
      footer={[
        <Button key={"back"} type={"default"} onClick={clearForm}>
          {gateway ? "Close" : "Cancel"}
        </Button>,
        <Button
          key={"submit"}
          hidden={!isNil(gateway)}
          loading={loadingModal}
          type={"primary"}
          className={"bg-primary-500 border-primary-500 hover:bg-opacity-80 hover:border-opacity-80"}
          onClick={(): void => {
            form.submit();
          }}
        >
          Add & Download Config
        </Button>,
      ]}
    >
      {gateway ? (
        <div>
          <p className={"mb-1 text-center"}>
            <InfoCircleOutlined className={"text-primary-500 mr-1"} /> A custom config file for your gateway has been downloaded.
          </p>
        </div>
      ) : (
        <Form
          layout="vertical"
          form={form}
          name="add-gateway"
          requiredMark={false}
          onFinish={(values): void => {
            setLoadingModal(true);
            addWithDownload(values as GatewayInstanceType);
          }}
        >
          <Form.Item name="name" label={<span className={"text-xs text-gray-400"}>Name</span>} rules={[{ required: true, message: "Name is required" }]}>
            <Input placeholder={"Name"} />
          </Form.Item>
          <Form.Item name="tenantId" label={<span className={"text-xs text-gray-400"}>Facility</span>} rules={[{ required: true, message: "Facility is required" }]}>
            <Select showSearch={true} optionFilterProp={"children"} placeholder={"Select Facility"}>
              {authentication?.tenants?.map(({ id, name }) => (
                <Select.Option title={name} key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );

  const updateModal = (
    <Modal
      width={700}
      title={"Edit Gateway"}
      visible={actionType === ACTION_TYPES.EDIT}
      closable={true}
      onCancel={clearForm}
      footer={[
        <Button key="back" onClick={clearForm}>
          Cancel
        </Button>,
        <Button
          key={"submit"}
          className={"bg-primary-500 border-primary-500 hover:bg-opacity-80 border-opacity-80"}
          loading={loadingModal}
          type={"primary"}
          onClick={(): void => {
            form.submit();
          }}
        >
          Update
        </Button>,
      ]}
    >
      <>
        <Form
          layout="vertical"
          form={form}
          name="edit-gateway"
          requiredMark={false}
          onFinish={(values): void => {
            setLoadingModal(true);
            update(values, gateway?.id);
          }}
        >
          <Form.Item name="name" label={<span className={"text-xs text-gray-400"}>Name</span>} rules={[{ required: true, message: "Name is required" }]}>
            <Input placeholder={"Name"} />
          </Form.Item>
          <Form.Item name="tenantId" label={<span className={"text-xs text-gray-400"}>Facility Name</span>} rules={[{ required: true, message: "Facility is required" }]}>
            <Select disabled={actionType === ACTION_TYPES.EDIT} showSearch={true} optionFilterProp={"children"} placeholder={"Select Facility"}>
              {authentication?.tenants?.map(({ id, name }) => (
                <Select.Option title={name} key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );

  const getInfo: NoParamVoidType = () => {
    getBuildInfo({})
      .then(({ data }: ResponseType<InfoType>) => {
        setVersion("AMWatch " + data.buildVersion);
      })
      .catch((error: ResponseType) => {
        console.log(error);
      });
  };

  const getAll: getAllType = () => {
    getGateways({})
      .then(({ data }: ResponseType<PaginationContainer<GatewayInstanceType>>) => {
        setGateways(valueOrDefault([], data.results));
      })
      .catch((error: string) => {
        message.error("Error! Unable to load gateways!");
        console.error(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const getGateway = () => {
    const gateway = find(propEq("id", gatewayId), gateways);
    return gateway ? <GatewayConnection gateway={gateway} /> : <></>;
  };

  const getModalContent: getModalContentType = (type: ACTION_TYPES) => {
    switch (type) {
      case ACTION_TYPES.ADD:
        return addModal;
      case ACTION_TYPES.EDIT:
        return updateModal;
    }
  };

  const add: addType = (gateway) => {
    addGateway({
      body: JSON.stringify(gateway),
    })
      .then(({ data }: ResponseType<GatewayInstanceType>) => {
        setGateway(data);
        setGateways(insertItemIntoArray(0, gateways, data));
        message.success("Gateway has been added successfully");
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Error! Gateway could not be added");
      })
      .then(() => {
        setLoadingModal(false);
      });
  };

  const update: updateType = (values, id) => {
    updateGateway({
      segments: {
        id,
      },
      body: JSON.stringify({ ...values }),
    })
      .then(({ data }: ResponseType<GatewayInstanceType>) => {
        const index = findIndex(propEq("id", id))(gateways);
        setGateways(updateItemInArray(index, gateways, data));
        message.success("Gateway has been updated successfully");
        clearForm();
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Error! Gateway could not be updated");
        setLoadingModal(false);
      });
  };

  const remove: removeType = (id) => {
    deleteGateway({
      segments: {
        id,
      },
    })
      .then(() => {
        const index = findIndex(propEq("id", id))(gateways);
        setGateways(removeItemFromArray(index, gateways));
        message.success("Gateway has been deleted");
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Error! Gateway could not be deleted");
      });
  };

  const addWithDownload: addWithDownload = (gateway) => {
    addGatewayV2({
      body: JSON.stringify(gateway),
      filename: "GatewayService.exe.config",
    })
      .then(({ data }: ResponseType<ResponseType>) => {
        getAll();
        setGateway(gateways[0]);
        message.success("Gateway has been added successfully");
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Error! Gateway config could not be downloaded");
      })
      .then(() => {
        setLoadingModal(false);
      });
  };

  useEffect(() => {
    getInfo();
  });

  useAutoRefresh(
    () => {
      getAll();
    },
    actionType === null && gateway === null,
    [actionType, gateway]
  );

  return (
    <PageLayout loading={loading} loadingText={"Loading Gateways ..."} header={<PageHeader title={"Gateways"} />}>
      <>
        <div className={"border shadow-xs max-h-full overflow-y-auto hide-scrollbar bg-white"}>
          <Table rowKey={"id"} rowClassName={"group"} sticky={true} bordered={false} columns={columns} dataSource={gateways} scroll={{ x: 1200 }} pagination={TABLE_PAGINATION_STYLE} />
        </div>
        {actionType && <>{getModalContent(actionType)}</>}
        {gatewayId && (
          <Drawer visible={gatewayId !== null} width={900} title={"Gateway Connections"} onClose={(): void => setGatewayId(null)} destroyOnClose={true}>
            {getGateway()}
          </Drawer>
        )}
        <div className={"text-right my-2"}>
          <span className={"text-gray-400 align-left font-light text-sm"}>{version}</span>
        </div>
        <FloatingButton icon={<i className="fas fa-plus" />} label={"Add Gateway"} onClick={(): void => setActionType(ACTION_TYPES.ADD)} />
      </>
    </PageLayout>
  );
};

type clearFormType = () => void;
type getAllType = () => void;
type updateType = (values: any, gatewaysId?: string) => void;
type addType = (values: GatewayInstanceType) => void;
type removeType = (id: string) => void;
type showDeleteModalType = (id: string, name: string) => void;
type getModalContentType = (type: ACTION_TYPES) => void;
type addWithDownload = (values: GatewayInstanceType) => void;
