import React, { FC } from "react";

const ACTIVE_STYLE = "bg-grey-800 hover:bg-grey-700 border-white text-white rounded-full";
const INACTIVE_STYLE = "text-grey-500 hover:text-grey-400";

export const ViewToggleSwitch: FC<ToggleViewSwitchType> = function ({ gridView = true, onClick }: ToggleViewSwitchType) {
  return (
    <div className={"flex bg-white rounded-full border shadow-inner p-1"}>
      <div onClick={(): void => onClick(true)} className={`cursor-pointer text-center h-full w-8 pt-1 ${gridView ? ACTIVE_STYLE : INACTIVE_STYLE}`}>
        <i className="fa fa-th-large fa-sm" aria-hidden="true"></i>
      </div>
      <div onClick={(): void => onClick(false)} className={`cursor-pointer text-center h-full w-8 pt-1  ${!gridView ? ACTIVE_STYLE : INACTIVE_STYLE}`}>
        <i className="fa fa-th-list fa-sm" aria-hidden="true"></i>
      </div>
    </div>
  );
};

type ToggleViewSwitchType = {
  gridView: boolean;
  onClick: (o: boolean) => void;
};
