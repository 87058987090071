import React, { FC, ReactElement, useContext } from "react";
import { MainLayout } from "../layout/MainLayout";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AUTH_ROUTES, RouteType, UNAUTH_ROUTES } from "../../utils/routes/routes";
import { DefaultLayout } from "../layout/DefaultLayout";
import { AuthenticationContext } from "../../context/AuthenticationContext";
import { useRequestUnauth } from "../../custom-hooks/useRequestUnauthorized";

const getRoutes: getRoutesType = (routes: RouteType[]): ReactElement[] =>
  routes.map(({ path, exact, Component }) => (
    <Route key={Array.isArray(path) ? path[0] : path} exact={exact} path={path}>
      <Component />
    </Route>
  ));

export const Routes: FC = function () {
  const { authentication, setAuthentication } = useContext(AuthenticationContext);

  useRequestUnauth(() => {
    setAuthentication(null);
  });

  return (
    <Router>
      {authentication ? (
        <MainLayout>
          <Switch>{getRoutes(AUTH_ROUTES)}</Switch>
        </MainLayout>
      ) : (
        <DefaultLayout>
          <Switch>{getRoutes(UNAUTH_ROUTES)}</Switch>
        </DefaultLayout>
      )}
    </Router>
  );
};

type getRoutesType = (routes: Array<RouteType>) => Array<ReactElement>;
