import React, { Dispatch, FC, ReactNode, useContext, useState } from "react";
import { Button, Empty, message, Switch as ToggleSwitch, Table, Tag, Tooltip } from "antd";
import { isEmpty } from "ramda";
import { MonitoringRuleSetDetailsType, MonitoringRuleSetResultType, MonitoringRuleSetType } from "../../utils/types/types";
import { getAlertRuleDescription, RULE_SET_STATUS_COLOR } from "../../utils/alerts/alert";
import moment from "moment";
import { getHumanizedElapsedTime } from "../../utils/general/dates";
import { IconButton } from "../general/IconButton";
import { useHistory, useRouteMatch } from "react-router";
import { removeItemFromArray, updateItemInArray } from "../../utils/general/helper";
import { deleteMonitor, updateMonitor } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { ConfirmDeleteModal } from "../../utils/modals";
import { Breakpoint } from "antd/es/_util/responsiveObserve";
import { DeviceDashboardContext } from "../../context/DeviceDashboardContext";
import { AddMonitorForm } from "./AddMonitorForm";
import { AuthenticationContext } from "../../context/AuthenticationContext";
import { CustomTag } from "../general/CustomTag";

export const MonitorListTable: FC<PropType> = function ({ setMonitors, monitors, isDashboard }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const { authentication } = useContext(AuthenticationContext);

  const [monitor, setMonitor] = useState<MonitoringRuleSetDetailsType | null>(null);
  const [drawerType, setDrawerType] = useState<DrawerType | null>(null);

  const getDrawerContent = () => {
    if (!deviceDashboard || !monitor || !drawerType) return;
    switch (drawerType?.type) {
      case "EDIT_MONITOR":
        return (
          <AddMonitorForm
            monitor={monitor}
            deviceId={monitor.deviceId}
            users={authentication?.teamMembers}
            parameters={deviceDashboard.parameters}
            onSuccess={(value) => {
              setMonitors(updateItemInArray(drawerType.index, monitors, { ...value, currentStatus: monitor.currentStatus }));
            }}
            onClose={() => {
              setDrawerType(null);
            }}
          />
        );
    }
  };

  const columns = [
    {
      title: "",
      className: "text-xs xl:text-sm",
      dataIndex: "status",
      width: "40px",
      render: function getMonitor(name: string, { currentStatus }: MonitoringRuleSetDetailsType): ReactNode {
        return <>{currentStatus && currentStatus.status && <Tag className={`select-none text-xs ${RULE_SET_STATUS_COLOR[currentStatus.status]}`}>{currentStatus.status}</Tag>}</>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>Monitor</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "name",
      width: "150px",
      fixed: false,
      sorter: (a: MonitorType, b: MonitorType): number => a.name.localeCompare(b.name),
      render: function getMonitor(name: string, { id, deviceId }: MonitoringRuleSetDetailsType): ReactNode {
        return (
          <>
            <Button
              type={"link"}
              onClick={(): void => {
                isDashboard ? history.push(`devices/${deviceId}/monitors/${id}`) : history.push(`${url}/${id}`);
              }}
            >
              {name}
            </Button>
          </>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>Description</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "description",
      width: "125px",
    },
    {
      title: <span className={"text-xs text-gray-500"}>Last Executed</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "currentStatus",
      width: "125px",
      render: function getUpdatedAt(currentStatus: MonitoringRuleSetResultType): ReactNode {
        return (
          <Tooltip title={moment(currentStatus?.executedAt).format("lll").valueOf()}>
            <span>{getHumanizedElapsedTime(currentStatus?.executedAt)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500"}>Enabled</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "enabled",
      width: "75px",
      sorter: (a: MonitorType, b: MonitorType): number => Number(a.enabled) - Number(b.enabled),
      render: function getEnabled(enabled: boolean, { id, deviceId, name, description, createdAt, updatedAt, ruleSet, currentStatus, notificationsEnabled, waitTime, recipients, loading = false }: MonitorType, index: number): ReactNode {
        return (
          <ToggleSwitch
            loading={loading}
            className={`${enabled ? "bg-primary-500" : "bg-dark-400"}`}
            checked={enabled}
            onChange={(o): void => {
              setMonitors((monitors) => updateItemInArray(index, monitors, { id, deviceId, name, enabled, notificationsEnabled, waitTime, recipients, createdAt, updatedAt, ruleSet, currentStatus, loading: true }));
              onUpdate(deviceId, index, { id, deviceId, name, description, createdAt, updatedAt, ruleSet, currentStatus, notificationsEnabled, waitTime, recipients, enabled: o });
            }}
          />
        );
      },
    },
    {
      key: "actions",
      width: "65px",
      align: "center" as const,
      fixed: "right" as const,
      responsive: ["lg" as Breakpoint],
      render: function getActionItems(_: any, monitor: MonitoringRuleSetDetailsType, index: number): ReactNode {
        return (
          <>
            {/*
              <Switch checked={false} size={"default"} onChange={(val) => {}} className={"bg-dark-400"} checkedChildren={<i className="fas fa-bell" />} unCheckedChildren={<i className="fas fa-bell-slash" />} defaultChecked />
*/}
            <IconButton
              tooltipText={"Edit Monitor"}
              type={"primary"}
              icon={"edit"}
              onClick={(): void => {
                setDrawerType({ type: "EDIT_MONITOR", index });
                setMonitor(monitor);
              }}
            />
            <IconButton
              tooltipText={"Delete Monitor"}
              type={"danger"}
              icon={"trash"}
              onClick={(): void => {
                ConfirmDeleteModal(
                  "Are you sure you want to delete this monitor?",
                  () => onDelete(monitor.deviceId, monitor.id, index),
                  <div className={"pt-3"}>
                    Name: {monitor.name}&nbsp;{monitor.id}
                  </div>
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const onDelete: onDeleteType = (deviceId, id, index) => {
    message.loading({
      content: "Loading ...",
      key: "deleteMonitor",
      duration: 0,
    });
    deleteMonitor({
      segments: {
        deviceId,
        id,
      },
    })
      .then(() => {
        setMonitors(removeItemFromArray(index, monitors));
        message.success({ content: "Monitor has been deleted successfully!", key: "deleteMonitor", duration: 2 });
      })
      .catch(() => {
        message.error({ content: "Unable to delete monitor", key: "deleteMonitor", duration: 2 });
      });
  };

  const onUpdate: onUpdateType = (deviceId, index, monitor) => {
    updateMonitor({
      segments: {
        deviceId,
        id: monitor.id,
      },
      body: JSON.stringify(monitor),
    })
      .then(({ data }: ResponseType<MonitoringRuleSetDetailsType>) => {
        setMonitors((monitors) => updateItemInArray(index, monitors, { ...data, currentStatus: monitor.currentStatus, loading: false }));
      })
      .catch(() => {
        message.error("Unable to update monitor");
        setMonitors((monitors) => updateItemInArray(index, monitors, { ...monitor, loading: false }));
      });
  };
  const getExpandedView: getExpandedViewType = (ruleset) => {
    return (
      <>
        <div className={"text-sm inline-block"}>
          <span className={"text-dark-400"}>When </span>
          <span className={"font-bold"}>{ruleset.condition}</span>
          <span className={"text-dark-400"}> of the following conditions are met</span>
        </div>
        <div className={"flex flex-row gap-2 my-2"}>
          {ruleset?.rules?.map(({ condition, primaryMetric, secondaryMetric, threshold }, i) => {
            return (
              <CustomTag key={i}>
                <div>{getAlertRuleDescription(primaryMetric, secondaryMetric, threshold, condition)}</div>
              </CustomTag>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {isEmpty(monitors) ? (
        <Empty description={"No Monitors"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div className={"border shadow-xs max-h-full overflow-y-scroll hide-scrollbar"}>
          {monitors && (
            <>
              <Table
                rowKey={"id"}
                sticky={true}
                bordered={false}
                columns={columns}
                dataSource={monitors}
                scroll={{ x: 1200 }}
                expandable={{
                  expandedRowRender: (record): ReactNode => {
                    return getExpandedView(record?.ruleSet);
                  },
                  columnWidth: "20px",
                }}
                pagination={{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "15", "25", "50"],
                }}
              />
              {drawerType && getDrawerContent()}
            </>
          )}
        </div>
      )}
    </>
  );
};

type PropType = {
  isDashboard: boolean;
  monitors: MonitorType[];
  setMonitors: Dispatch<React.SetStateAction<MonitorType[]>>;
};
type onUpdateType = (deviceId: string, monitorIndex: number, monitor: MonitoringRuleSetDetailsType) => void;
type onDeleteType = (deviceId: string, monitorId: string, monitorIndex: number) => void;

type MonitorType = MonitoringRuleSetDetailsType & LoadingType;
type LoadingType = { loading: boolean };
type getExpandedViewType = (ruleset: MonitoringRuleSetType) => ReactNode;
type DrawerType = { type: "EDIT_MONITOR"; index: number };
