import React, { FC, ReactElement, ReactNode } from "react";
import { Spinner } from "../general/Spinner";

export const PageLayout: FC<PageLayoutType> = function ({ loading = false, loadingText = "Loading ...", error = false, errorContent = <></>, header = <></>, subHeader = <></>, footer, height = "screen", children }: PageLayoutType) {
  return (
    <div className={`bg-grey-50 relative flex flex-col w-full h-${height} max-h-${height}`}>
      <Spinner loading={loading} loadingText={loadingText} />
      {header}
      <>
        {!loading && (
          <>
            {error ? (
              <div className={"flex h-full w-full max-w-full items-center justify-center overflow-auto"}>{errorContent}</div>
            ) : (
              <div className={"p-6 flex flex-col h-full w-full max-h-full 2xl:max-w-screen-2xl mx-auto overflow-y-scroll hide-scrollbar"}>
                <div>{subHeader}</div>
                {children}
              </div>
            )}
          </>
        )}
      </>
        {footer && <div className={'w-full absolute bottom-0 border-t'}>{footer}</div>}
    </div>
  );
};

type PageLayoutType = {
  loading?: boolean;
  loadingText?: string;
  error?: boolean;
  errorContent?: ReactNode;
  header?: ReactNode;
  subHeader?: ReactNode;
    footer?: ReactNode;
    height?: "full" | "screen";
  children: ReactElement;
};
