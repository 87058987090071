import React, { FC, ReactNode } from "react";
import { Space } from "antd";
import Title from "antd/lib/typography/Title";

export const PageHeader: FC<PageHeaderType> = function ({ title, children }: PageHeaderType) {
  return (
    <div className={"bg-white z-[800] w-full"}>
      <div className={"mx-auto border-b shadow-bottom"}>
        <Space className={`2xl:max-w-screen-2xl mx-auto bg-white flex justify-between px-6 h-16`}>
          <div>
            <Title level={5} className={"text-dark-700 mb-0"}>
              {title}
            </Title>
          </div>
          <div>{children}</div>
        </Space>
      </div>
    </div>
  );
};

type PageHeaderType = {
  title?: ReactNode;
  children?: ReactNode;
};
