import React, { createContext, FC, ReactNode, useEffect, useState } from "react";
import { DeviceParameterType, PaginationContainer } from "../utils/types/types";
import { getDeviceParameters } from "../services/services";
import { ResponseType } from "../utils/types/uiTypes";
import { valueOrDefault } from "../utils/general/helper";

export const DeviceDashboardContext = createContext<DeviceDashboardContextType>({
  deviceDashboard: null,
  setDeviceDashboard: () => {},
});

export const DeviceDashboardProvider: FC<DeviceDashboardProviderType> = function ({ deviceId, fromDate, toDate, children }: DeviceDashboardProviderType) {
  const [loading, setLoading] = useState(true);
  const [deviceDashboard, setDeviceDashboard] = useState<DeviceDashboardType | null>(null);

  const getParameters: getParametersType = (id) => {
    getDeviceParameters({
      segments: {
        id,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<DeviceParameterType>>) => {
        setDeviceDashboard({ deviceId: id, fromDate, toDate, parameters: valueOrDefault([], data.results) });
      })
      .catch((error: any) => {
        console.log(error);
        setDeviceDashboard({ deviceId: id, fromDate, toDate, parameters: [] });
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (deviceDashboard) {
      setDeviceDashboard({ ...deviceDashboard, fromDate, toDate });
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    setLoading(true);
    deviceId && getParameters(deviceId);
  }, [deviceId]);

  return <DeviceDashboardContext.Provider value={{ deviceDashboard, setDeviceDashboard }}>{!loading && children}</DeviceDashboardContext.Provider>;
};

type DeviceDashboardProviderType = {
  children: ReactNode;
  deviceId: string;
  fromDate: number;
  toDate: number;
};
type DeviceDashboardType = {
  deviceId: string;
  fromDate: number;
  toDate: number;
  parameters: Array<DeviceParameterType>;
};
type DeviceDashboardContextType = {
  deviceDashboard: null | DeviceDashboardType;
  setDeviceDashboard: (o: DeviceDashboardType | null) => void;
};
type getParametersType = (deviceId: string) => void;
