import React, { FC, useEffect, useState } from "react";
import { DeviceLogType, PaginationContainer, PrintJobImageType, PrintJobType } from "../../utils/types/types";
import { Empty, InputNumber, Modal, Select, Slider } from "antd";
import moment from "moment";
import { getJobImages } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { prop, sortBy } from "ramda";
import { getUnits } from "../../utils/widgets";

const STATUS_STYLE = {
  COMPLETED: "bg-success-500 justify-between",
  IN_PROGRESS: "bg-primary-500 justify-between",
  QUEUED: "bg-warning-500 justify-between",
  PAUSED: "bg-warning-500 justify-between",
  WAITING: "bg-muted justify-between",
  CANCELLED: "!w-full bg-danger-500 justify-center",
  ABORTED: "!w-full bg-danger-500 justify-center",
  FAILED: "!w-full bg-danger-500 justify-center",
  UNKNOWN: "!w-full bg-muted justify-center",
};
const JOB_IMAGE_TYPES = ["PowderBedAfterRecoating", "PowderBedAfterExposure", "PowderBedBeforeRecoating", "ExposureOTIntegral", "ExposureOTMaximum", "MeltPoolMovingAverage1", "MeltPoolMovingAverage2", "MeltPoolSignalDynamics"];

export const JobModal: FC<JobModalType> = ({ job, onClose }) => {
  const [images, setImages] = useState<Array<PrintJobImageType>>([]);
  const [filteredImages, setFilteredImages] = useState<Array<PrintJobImageType>>([]);
  const [imageType, setImageType] = useState<string | null>(null);
  const [index, setIndex] = useState(1);

  const getImages: getImagesType = (jobId) => {
    getJobImages({
      segments: {
        jobId,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<PrintJobImageType>>) => {
        const list = sortBy(prop("layerIndex"), data.results);
        setImages(list);
        setFilteredImages(list);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (job?.id) {
      getImages(job.id);
    }
  }, [job?.id]);
  return (
    <>
      {job && (
        <Modal bodyStyle={{ padding: 0 }} visible={true} footer={false} title={job.name} onCancel={onClose} width={"1200px"}>
          <div className={"grid grid-cols-3"}>
            <div className={"col-span-2 p-6"}>
              <div className={"col-span-2 flex flex-col items-center gap-6"}>
                <div className={"w-full"}>
                  <div className={"flex flex-row justify-between gap-2"}>
                    <InputNumber min={1} max={filteredImages.length} value={index} onChange={(v)=> {
                      setIndex(v ? Number(v) : 1)
                    }}  />
                    <Select
                      allowClear={true}
                      onClear={() => {
                        setImageType(null);
                        setIndex(1);
                        setFilteredImages(images);
                      }}
                      className={"w-56"}
                      value={imageType}
                      onChange={(data: any, val: any): void => {
                        setImageType(data);
                        setIndex(1);
                        setFilteredImages(images.filter((o) => (data ? o?.metadata?.imageType === data : true)));
                      }}
                      placeholder={"Select Image type"}
                    >
                      {JOB_IMAGE_TYPES.map((selectedType) => (
                        <Select.Option key={selectedType}>{selectedType}</Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={"h-[600px]"}>
                  {index > 0 && index < filteredImages.length + 1 && filteredImages[index - 1]?.url ? (
                    <>
                      <img className={"h-full w-auto"} src={filteredImages[index - 1]?.url} />
                      <div className={"p-1 text-gray-400 text-xs text-right"}>{filteredImages[index - 1]?.name}</div>
                    </>
                  ) : (
                    <div className={"w-full h-full flex justify-center items-center"}>
                      <Empty description={"No Images"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )}
                </div>
                <Slider className={`w-56 mt-4`} min={1} max={filteredImages.length} step={1} onChange={setIndex} value={index} disabled={filteredImages.length <= 0} />
              </div>
            </div>
            <div className={"flex flex-col overflow-y-auto p-6 border-l"}>
              <div className={"overflow-y-auto flex flex-col overflow-y-auto gap-2 text-xs"}>
                <div className={"font-semibold w-full border-b"}>Job Info</div>
                <div className={"flex flex-row items-center gap-6"}>
                  Print Name <span className={"ml-auto items-center"}>{job.name}</span>
                </div>
                <div className={"flex flex-row items-center gap-6"}>
                  File Name <span className={"ml-auto items-center"}>{job.filename}</span>
                </div>
                <div className={"flex flex-row items-center"}>
                  Layer Count <span className={"ml-auto items-center"}>{`${job.layerCountCurrent} / ${job.layerCountTarget}`}</span>
                </div>
                {!isNaN(job.position as number) && (
                  <div className={"flex flex-row items-center"}>
                    Queue Position <span className={"ml-auto items-center"}>{(job?.position ?? 0) + 1}</span>
                  </div>
                )}
                {job.submissionDate && (
                  <div className={"flex flex-row items-center"}>
                    Submitted On <span className={"ml-auto items-center"}>{moment(Number(job.submissionDate)).format("lll").toString()}</span>
                  </div>
                )}
                {!isNaN(job.updatedAt as number) && (
                  <div className={"flex flex-row items-center"}>
                    Last Updated <span className={"ml-auto items-center"}>{moment(job.updatedAt).fromNow(true)} ago</span>
                  </div>
                )}

                <div className={"font-semibold w-full border-b pt-4"}>Time Frame</div>
                <div className={"flex flex-row items-center"}>
                  Start Date <span className={"ml-auto items-center"}>{job.startDate && moment(parseInt(job.startDate)).format("lll").toString()}</span>
                </div>
                <div className={"flex flex-row items-center"}>
                  End Date <span className={"ml-auto items-center"}>{job.endDate && moment(parseInt(job.endDate)).format("lll").toString()}</span>
                </div>
                <div className={"flex font-semibold w-full border-b pt-4"}>
                  Materials <span className={"ml-auto items-center"}>Est. Consumption / Est. Total</span>
                </div>
                <div className={"grid grid-cols-1 gap-2"}>
                  {job?.materials?.map(({ id, name, estimatedAmount, consumedAmount, unit }) => (
                    <div key={id} className={"relative bg-gray-100 border py-1"}>
                      <div className={"flex flex-row gap-4 items-center justify-between px-2"}>
                        <div className={"text-xs truncate line-clamp-1"}>{name}</div>
                        <span>
                          {consumedAmount} / {estimatedAmount}&nbsp;{getUnits(unit) ?? ""}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={"flex font-semibold w-full border-b pt-4"}>Progress</div>
                <div className={"flex flex-row items-center"}>
                  Status <span className={"ml-auto items-center"}>{job.status.replace("_", " ")}</span>
                </div>
                <div className={"relative w-full h-4 bg-gray-100"}>
                  <div className={`h-full px-2 ${STATUS_STYLE[job.status]}`} style={{ width: `${job.percentComplete}%` }}></div>
                  <div className={"absolute right-2 top-1/2 -translate-y-1/2 text-xxs select-none"}>{job.percentComplete}%</div>
                </div>
                {index > 0 && index < filteredImages.length + 1 && (
                  <>
                    <div className={"flex font-semibold w-full border-b pt-4"}>Image Metadata</div>
                    <div className={"flex flex-row items-center"}>
                      Name <span className={"pl-6 ml-auto items-center truncate"}>{filteredImages[index - 1]?.name}</span>
                    </div>
                    <div className={"flex flex-row items-center"}>
                      Type <span className={"ml-auto items-center"}>{filteredImages[index - 1]?.metadata?.imageType}</span>
                    </div>
                    <div className={"flex flex-row items-center"}>
                      Layer <span className={"ml-auto items-center"}>{filteredImages[index - 1]?.layerIndex}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

type getImagesType = (jobId: string) => void;
type JobModalType = {
  job: PrintJobType | null;
  onClose: () => void;
};
