import React, { FC, useEffect, useState } from "react";
import { Button, DatePicker, Dropdown, Menu, Space, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { DATE_OPTIONS } from "../../utils/general/dates";
import { DateOptionType } from "../../utils/types/uiTypes";
import { isNil, path } from "ramda";
import { valueOrDefault } from "../../utils/general/helper";

const CUSTOM = "CUSTOM";

const DATE_RANGES = [
  {
    key: "PAST_FIVE_MINS",
    abbreviation: "5m",
    label: <span className={"text-xs"}>Past 5 Minutes</span>,
    amount: 300,
  },
  ...DATE_OPTIONS,
];
export const DateRangePicker: FC<DateRangePickerType> = function ({ onChange, autoSet, disabled = false, defaultValue }: DateRangePickerType) {
  const [fromDate, setFromDate] = useState<Moment | undefined>(undefined);
  const [toDate, setToDate] = useState<Moment | undefined>(undefined);
  const [selected, setSelected] = useState<DateOptionType>(DATE_RANGES[0]);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange: handleVisibleChangeType = (o) => {
    setVisible(o);
  };
  const menu = (
    <Menu
      onClick={(e): void => {
        if (e.key !== CUSTOM) {
          setVisible(false);
        }
      }}
    >
      {DATE_RANGES.map(({ key, abbreviation, label, amount, style = "" }, index) => (
        <Menu.Item
          className={`${style} ${selected.key == key && "bg-blue-200"} `}
          key={index}
          onClick={(): void => {
            const now = moment(Date.now()).valueOf();
            setSelected({ key, abbreviation, label, amount });
            onChange([now - valueOrDefault(0, amount) * 1000, now]);
            autoSet(true);
            setFromDate(undefined);
            setToDate(undefined);
          }}
        >
          <div>
            <Tag className={"w-12 text-center rounded-sm text-gray-800"}>{abbreviation}</Tag>
            {label}
          </div>
        </Menu.Item>
      ))}
      <Menu.Item
        key={CUSTOM}
        onClick={(): void => {
          setSelected({ key: CUSTOM });
        }}
        className={`border-t py-2 ${selected.key == CUSTOM && "bg-blue-200"} `}
      >
        <div>
          <Tag className={"w-12 text-center rounded-sm text-gray-800"}>
            <i className="fa fa-calendar-alt text-xxs" />
          </Tag>
          <Space>
            <DatePicker showTime dropdownClassName={"left-auto right-96 top-36 mr-2 mt-3"} allowClear={false} disabledDate={(d): boolean => !d || d.isAfter(toDate)} value={fromDate} onChange={(date, dateString): void => setFromDate(moment(dateString))} />
            <DatePicker showTime dropdownClassName={"left-auto right-96 top-36 mr-2 mt-3"} allowClear={false} disabledDate={(d): boolean => !d || d.isBefore(fromDate)} value={toDate} disabled={fromDate == null} onChange={(date, dateString): void => setToDate(moment(dateString))} />
          </Space>
        </div>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (selected.key === CUSTOM && !isNil(fromDate) && !isNil(toDate)) {
      onChange([moment(fromDate).valueOf(), moment(toDate).valueOf()]);
      autoSet(false);
      setVisible(false);
    }
  }, [selected.key, fromDate, toDate]);

  useEffect(() => {
    if (defaultValue) {
      setVisible(false);
      setSelected({ key: "CUSTOM" });
      setFromDate(moment(path(["0"], defaultValue ?? [])));
      setToDate(moment(path(["1"], defaultValue ?? [])));
    }
  }, []);

  return (
    <Dropdown disabled={disabled} overlay={menu} placement={"bottomLeft"} trigger={["click"]} onVisibleChange={handleVisibleChange} visible={visible}>
      <Button className={"text-center w-52 h-8 text-xs"} disabled={disabled}>
        {selected.key === CUSTOM ? (
          <>
            <span>{fromDate ? moment(fromDate).format("L").toString() + " - " : "Custom"}</span>
            <span>{toDate && moment(toDate).format("L").toString()}</span>
          </>
        ) : (
          selected.label
        )}
        <DownOutlined className={"float-right pt-1"} />
      </Button>
    </Dropdown>
  );
};

type DateRangePickerType = {
  onChange: (o: [number, number]) => void;
  autoSet: (o: boolean) => void;
  disabled?: boolean;
  defaultValue?: [number, number];
};

type handleVisibleChangeType = (visible: boolean) => void;
