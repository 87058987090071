import React, { FC, useState } from "react";
import { Button } from "antd";
import { FiltersType } from "../../utils/types/types";
import { FilterInput } from "./FilterInput";

export const SearchFiltersBar: FC<SearchFiltersBarType> = function ({ onSubmit, filtersList }) {
  const [selectedFilters, setSelectedFilters] = useState<{ key: string; value: string; path: string[] }[]>([]);

  const addRemoveFilter = (key: string, value: string, path: string[]): void => {
    if (value === "") {
      setSelectedFilters((list) => list.filter((item) => item.key !== key));
    } else {
      setSelectedFilters((list) => {
        return list.find((item) => item.key === key && item.value === value)
          ? list.filter((item) => !(item.key === key && item.value === value))
          : [
              ...list,
              {
                key,
                value,
                path,
              },
            ];
      });
    }
  };
  return (
    <div className={"md:flex md:flex-wrap items-center gap-4"}>
      {filtersList?.map((filter) => (
        <FilterInput filter={filter} key={filter.key} onChange={addRemoveFilter} />
      ))}
      <div className={"float-right md:ml-auto"}>
        <Button className={"bg-primary-500 text-white border-0"} onClick={(): void => onSubmit(selectedFilters)}>
          Apply Filter
        </Button>
      </div>
    </div>
  );
};

type SearchFiltersBarType = {
  onSubmit: (a: Array<{ key: string; value: string; path: string[] }>) => void;
  filtersList: FiltersType[];
};
