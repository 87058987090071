import React, { FC } from "react";
import { LabelValueCard } from "../general/LabelValueCard";
import { JobProgress } from "./JobProgress";
import moment from "moment";
import { path } from "ramda";
import { Tabs } from "antd";
import { displayDashAsDefault } from "../../utils/general/helper";
import { getAbbreviatedHumanizedElapsedTime } from "../../utils/general/dates";
import { ModelType } from "../../utils/types/enums";

const { TabPane } = Tabs;

const getLabel = (type?: ModelType) => {
  switch (type) {
    case ModelType.POST_PROCESSING_MACHINE:
      return "Recent Post Processing Job"
    default:
      return "Recent Print Job"
  }
}

export const JobInfo: FC<JobInfoType> = function ({ name = "", type, showRemainingTime, materials = [], currentLayer = "--", targetLayer = "--", percentage, estimatedTime, elapsedTime }: JobInfoType) {
  const remainingTime: number | undefined = !isNaN(parseFloat(elapsedTime)) && !isNaN(parseFloat(estimatedTime)) && showRemainingTime ? parseFloat(estimatedTime) - parseFloat(elapsedTime) * 1000 : undefined;
  return (
    <div className={"h-40 rounded bg-grey-50 border border-gray-200 p-2"}>
      <div className={"text-xs font-semibold px-3 py-2"}>{getLabel(type)}</div>
      <div className={"mx-2 pb-2 mt-1"}>
        <JobProgress name={name} percentage={parseFloat(percentage)} />
      </div>
      <div className={`hidden sm:grid gap-2 m-2 ${type === ModelType.PRINTER ? 'grid-cols-3' : 'grid-cols-1'}`}>
        {type === ModelType.PRINTER && <>
          <LabelValueCard label={"Material"} value={path([0, "name"], materials)} />
          <LabelValueCard label={"Layers"} value={currentLayer + "/" + targetLayer}
                          tooltip={currentLayer !== "--" || targetLayer !== "--" ? true : false} />
        </>
        }
         <LabelValueCard label={"Remaining"} tooltip={remainingTime ? true : false} tooltipText={remainingTime ? moment.duration(remainingTime).humanize() : undefined} value={remainingTime ? getAbbreviatedHumanizedElapsedTime(remainingTime) : undefined} />
      </div>
      <div className={"m-2 sm:hidden"}>
        <Tabs defaultActiveKey="materials">
          <TabPane tab={<span className={"text-xs text-center"}>Material</span>} key="materials">
            <span className={"text-xs truncate overflow-ellipsis font-medium"}>{displayDashAsDefault(path([0, "name"], materials))}</span>
          </TabPane>
          <TabPane tab={<span className={"text-xs text-center"}>Layers</span>} key="layers">
            <span className={"text-xs truncate overflow-ellipsis font-medium"}>{currentLayer + " / " + targetLayer}</span>
          </TabPane>
          <TabPane tab={<span className={"text-xs text-center"}>Remaining</span>} key="remaining">
            <span className={"text-xs truncate overflow-ellipsis font-medium"}>{remainingTime ? getAbbreviatedHumanizedElapsedTime(remainingTime) : displayDashAsDefault(remainingTime)}</span>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

type MaterialType = {
  name: string;
};

type JobInfoType = {
  name: string;
  type?: ModelType;
  showRemainingTime: boolean;
  materials: Array<MaterialType>;
  currentLayer: string;
  targetLayer: string;
  elapsedTime: string;
  estimatedTime: string;
  percentage: string;
};
