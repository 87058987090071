import React, { FC, useState } from "react";
import { Input } from "antd";

export const SearchBar: FC<SearchBarType> = function ({ size = "DEFAULT", onSearch = (): void => {}, defaultValue, placeholder }: SearchBarType) {
  const [search, setSearch] = useState<string>("");

  return (
    <div className={`flex flex-row items-center bg-white rounded-full border shadow-inner p-1`}>
      <Input
        defaultValue={defaultValue}
        allowClear={true}
        className={`flex-grow rounded-full bg-transparent border-0 border-white shadow-none ${size == "DEFAULT" ? "w-36 sm:w-56" : "h-6 w-44"}`}
        onChange={(e): void => {
          setSearch(e.target.value);
          if (e.target.value === "") {
            onSearch("");
          }
        }}
        onPressEnter={(): void => {
          onSearch(search);
        }}
        placeholder={placeholder}
      />
      <div
        role="button"
        onClick={(): void => {
          onSearch(search);
        }}
        className={`float-right cursor-pointer text-center ml-px border-white text-black hover:text-primary-500 rounded-full pr-3`}
      >
        <i className={`fa fa-search ${size == "DEFAULT" ? "fa-sm" : "fa-xs"}`} aria-hidden="true"></i>
      </div>
    </div>
  );
};

type SearchBarType = {
  size?: "SMALL" | "DEFAULT";
  defaultValue?: string | undefined;
  onSearch?: (o: string) => void;
  placeholder?: string;
};
