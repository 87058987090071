import { FC } from "react";
import { Devices } from "../../pages/Devices";
import { DeviceMetrics } from "../../pages/DeviceMetrics";
import { DeviceDetails } from "../../pages/DeviceDetails";
import { Login } from "../../pages/Login";
import { Gateways } from "../../pages/Gateways";
import { InternalPageNotFound } from "../../pages/InternalPageNotFound";
import { ExternalPageNotFound } from "../../pages/ExternalPageNotFound";
import { Logout } from "../../pages/Logout";
import { Monitors } from "../../pages/Monitors";
import { RedirectToMain } from "../../pages/RedirectToMain";
import { Profiles } from "../../components/profiles/Profiles";
import {AddDevice} from "../../components/device/create/AddDevice";

export const AUTH_ROUTES: Array<RouteType> = [
  {
    Component: DeviceMetrics,
    exact: true,
    path: "/devices/:id/metrics",
  },
  {
    Component: DeviceDetails,
    exact: false,
    path: ["/devices/:deviceId/:menu", "/devices/:deviceId/monitors/:monitorId"],
  },
  {
    Component: Devices,
    exact: true,
    path: ["", "/", "/home", "/devices"],
  },
  {
    Component: AddDevice,
    exact: true,
    path: ["/devices/add"],
  },
  {
    Component: Profiles,
    exact: false,
    path: ["/profiles", "/profiles/:profileId"],
  },
  {
    Component: Gateways,
    exact: true,
    path: "/gateways",
  },
 /* {
    Component: Monitors,
    exact: false,
    path: ["/monitors", "/monitors/:deviceId/:monitorId"],
  },*/
  {
    Component: Logout,
    exact: true,
    path: "/logout",
  },
  {
    Component: RedirectToMain,
    exact: true,
    path: "/login",
  },
  {
    Component: InternalPageNotFound,
    exact: true,
    path: "*",
  },
];

export const UNAUTH_ROUTES: RouteType[] = [
  {
    Component: Login,
    exact: false,
    path: "/login",
  },
  {
    Component: ExternalPageNotFound,
    exact: false,
    path: "*",
  },
  {
    Component: ExternalPageNotFound,
    exact: false,
    path: "*",
  },
];

export type RouteType = {
  Component: FC;
  exact: boolean;
  path: string | string[];
};
