import React, { FC, ReactNode, useEffect, useState } from "react";
import { addProfile, getAllProfiles } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { ProfileType } from "../../utils/types/types";
import { Button, Form, Input, message, Modal, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table/Table";
import { useHistory } from "react-router";
import { FloatingButton } from "../general/FloatingButton";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { PRIMARY_BUTTON_STYLE, TABLE_PAGINATION_STYLE } from "../../utils/general/styles";

export const ProfilesList: FC = () => {
  const history = useHistory();
  const [form] = useForm();

  const [profiles, setProfiles] = useState<ProfileType[]>([]);
  const [loading, setLoading] = useState(true);
  const [createNewProfile, setCreateNewProfile] = useState(false);

  const submitNewProfile: submitNewProfileType = ({ name = '', description= '', tag = '' }) => {
    addProfile({
      body: JSON.stringify({ name, description, tag }),
    })
      .then(({ data }: ResponseType<ProfileType>) => {
        setProfiles([...profiles, data]);
      })
      .catch((error: string) => {
        message.error(error ?? "Error adding new profile");
      });
  };

  const fetchProfiles = (): void => {
    getAllProfiles({})
      .then(({ data }: ResponseType<{ results: ProfileType[] }>) => {
        setProfiles(data.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<ProfileType> = [
    {
      title: "Id",
      width: 150,
      dataIndex: ["id"],
      render: (id: string): ReactNode => <Tag className={"bg-zinc-500 border-zinc-500 text-white text-xxs"}>{id}</Tag>,
    },
    {
      title: "Name",
      render: ({ id, name }: ProfileType): ReactNode => (
        <Button type={"link"} onClick={(): void => history.push(`profiles/${id}`)}>
          {name}
        </Button>
      ),
    },
    {
      title: "Tag",
      render: ({ tag }: ProfileType): ReactNode =>
        tag ? <Tag className={"bg-zinc-500 border-zinc-500 text-white text-xxs"}>{tag}</Tag> : <></>
    },
    { title: "Description", dataIndex: ["description"], className: "min-w-[200px]" },
  ];

  useEffect(() => {
    fetchProfiles();
  }, []);

  return (
    <>
      <div className={"border shadow-xs max-h-full overflow-y-auto hide-scrollbar bg-white"}>
        <Table rowKey={"id"} rowClassName={"group"} loading={loading} dataSource={profiles} columns={columns} scroll={{ x: 1200 }} pagination={TABLE_PAGINATION_STYLE} />
      </div>
      <FloatingButton icon={<i className="fas fa-plus" />} label={"Add Profile"} onClick={(): void => setCreateNewProfile(true)} />
      <Modal
        title={"Add Profile"}
        visible={createNewProfile}
        onOk={(): void => {
          form.submit();
          setCreateNewProfile(false);
        }}
        okButtonProps={{ className: PRIMARY_BUTTON_STYLE }}
        onCancel={(): void => setCreateNewProfile(false)}
        okText={"Submit"}
      >
        <Form form={form} onFinish={submitNewProfile} layout={"vertical"}>
          <Form.Item label={"Name"} name={"name"} required={true}>
            <Input placeholder={"Name"} type={"text"} />
          </Form.Item>
          <Form.Item label={"Tag"} name={"tag"} required={true}>
            <Input placeholder={"Tag"} type={"text"} />
          </Form.Item>
          <Form.Item label={"Description"} name={"description"} required={true}>
            <TextArea placeholder={"Description"} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type submitNewProfileType = (profile: NewProfileType) => void;

type NewProfileType = { name: string; description: string, tag: string };
