import React, { FC } from "react";
import { Select } from "antd";
import { DeviceStatusOption, DEVICE_STATUSES } from "./DeviceStatusOption";
import { EquipmentStatusType } from "../../utils/types/enums";
import { keysIn } from "ramda";

export const StatusFilter: FC<StatusFilterType> = function ({value, onClick = (): void => {} } : StatusFilterType) {
    return (
        <div className={"lg:inline-flex w-52 bg-white rounded-full border shadow-inner p-1"}>
            <Select
                value={value}
                className={"w-full"}
                mode="multiple"
                allowClear
                placeholder={"Select statuses..."}
                tagRender={({value}) => {
                    return (
                        <i className="fas fa-circle text-danger mx-2" style={{color:DEVICE_STATUSES[value as EquipmentStatusType].color}}/>
                    )
                }}
                showArrow={true}
                bordered={false}
                showSearch={false}
                onChange={(value) => {
                    onClick(value)
                }}
                options={keysIn(DEVICE_STATUSES).map((item) => ({ label: <DeviceStatusOption status={item as EquipmentStatusType}/>, value: item }))}
            >
            </Select>
        </div>
    )
}

type StatusFilterType = {
    value?: string[],
    onClick: (o: string[]) => void
}