import React, { FC } from "react";
import { DeviceStatusType } from "../../utils/types/enums";
import { getConnectionStatus, STATUS_STYLE } from "../../utils/connection";

export const ConnectionStatus: FC<ConnectionStatusType> = function ({ loading = false, status = DeviceStatusType.UNKNOWN, active, total }: ConnectionStatusType) {
  return (
    <div className={`inline-flex rounded-full h-8 px-2 items-center text-center text-xxs border ${loading ? STATUS_STYLE[DeviceStatusType.UNKNOWN] : STATUS_STYLE[status]}`}>
      {loading ? (
        <div className={"inline-flex items-center justify-center flex-row gap-1 mx-3 even:animate-pulse"}>
          <span className={"rounded-full h-2 w-2 bg-gray-100 animate-pulse animation-delay-100"} />
          <span className={"rounded-full h-2 w-2 bg-gray-100 animate-pulse animation-delay-500"} />
          <span className={"rounded-full h-2 w-2 bg-gray-100 animate-pulse animation-delay-1000"} />
        </div>
      ) : (
        <span>{getConnectionStatus(status, active, total)}</span>
      )}
    </div>
  );
};

type ConnectionStatusType = {
  loading: boolean;
  status?: DeviceStatusType;
  active: number;
  total: number;
};
