import React, { FC, ReactNode, useEffect, useState } from "react";
import { Tabs } from "antd";
import moment from "moment";
import { reflowAllCharts } from "../../../utils/charts";
import { findIndex, path, propEq } from "ramda";
import useWindowDimensions from "../../../custom-hooks/useWindowDimensions";
import { Widget } from "../metrics/Widget";
import { displayDashAsDefault, getFloatValue, valueOrDefault } from "../../../utils/general/helper";
import { getUnits } from "../../../utils/widgets";
import { LatestValueCard } from "./LatestValueCard";
import { ChartWidgetType, ParameterDataType } from "../../../utils/types/enums";
import { DATE_OPTIONS } from "../../../utils/general/dates";

const { TabPane } = Tabs;

export const DeviceMetricChartSelector: FC<DeviceMetricChartSelectorType> = function ({ deviceId, widgets = [] }: DeviceMetricChartSelectorType) {
  const { width } = useWindowDimensions();
  const [date, setDate] = useState<number>(moment(Date.now()).valueOf());
  const [selectedTab, setSelectedTab] = useState(DATE_OPTIONS[0]);
  const [selectedMetricIndex, setSelectedMetricIndex] = useState<number>(0);

  const getLatestValueContainer: getLatestValueContainerType = (widgets = []) => {
    return (
      <>
        {widgets.slice(0, 4).map(({ label = "--", metrics = [] }, i) => (
          <div key={i} className={"h-full w-full"} style={width < 1280 ? { maxWidth: "150px" } : {}}>
            <LatestValueCard
              selected={selectedMetricIndex == i}
              label={label}
              value={displayDashAsDefault(getFloatValue(path([0, "results", 0, "stringValue"], metrics), 2))}
              unit={getUnits(path([0, "parameter", "unit"], metrics)) as string}
              dataType={getUnits(path([0, "parameter", "dataType"], metrics)) as ParameterDataType}
              onClick={(): void => {
                setSelectedMetricIndex(i);
              }}
            />
          </div>
        ))}
      </>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      reflowAllCharts();
    });
  }, [width, selectedTab]);

  return (
    <>
      <div key={date} className={`bg-white w-full h-full flex flex-col xl:grid-rows-none xl:flex xl:flex-row`}>
        <Tabs
          className={`p-4 flex-1`}
          size={"small"}
          animated={false}
          activeKey={selectedTab.key}
          onChange={(activeKey): void => {
            const index = findIndex(propEq("key", activeKey))(DATE_OPTIONS);
            setDate(moment(Date.now()).valueOf());
            setSelectedTab(DATE_OPTIONS[index]);
          }}
        >
          <React.Fragment>
            {DATE_OPTIONS.map(({ label, key }) => (
              <TabPane key={key} tab={label}>
                <div className={"h-full mx-auto"}>
                  <div className={"grid grid-cols-1 gap-1"}>
                    <div className={"flex flex-row space-x-2 overflow-y-auto p-1 xl:hidden"}>{getLatestValueContainer(widgets)}</div>
                    <div className={"border border-gray-200 bg-white rounded-sm p-3 mx-1"}>
                      <Widget
                        deviceId={deviceId}
                        outerStyle={{ height: width < 1280 ? "200px" : "300px" }}
                        from={date - valueOrDefault(0, selectedTab.amount) * 1000}
                        to={date}
                        expressions={widgets[selectedMetricIndex]?.expressions}
                        type={widgets[selectedMetricIndex]?.type}
                        height={width < 1280 ? 200 : 300}
                        title={widgets[selectedMetricIndex]?.label}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </React.Fragment>
        </Tabs>
        <div className={"hidden xl:block bg-white border-l border-gray-200 w-56 p-4 h-full"}>
          <div className={"h-full w-full grid grid-rows-4 gap-2 p-px"}>{getLatestValueContainer(widgets)}</div>
        </div>
      </div>
    </>
  );
};

type DeviceMetricChartSelectorType = {
  deviceId: string;
  widgets?: Array<DashboardWidgetType>;
};

type DashboardWidgetType = {
  label: string;
  type: ChartWidgetType;
  expressions: Array<string>;
  metrics: Array<MetricType>;
};

type MetricType = {
  parameter: ParameterType;
  results: Array<ValueType>;
};

type ParameterType = {
  unit?: string;
};

type ValueType = {
  stringValue: string;
};
type getLatestValueContainerType = (widgets: Array<DashboardWidgetType>) => ReactNode;
