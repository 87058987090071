import React, { FC, ReactElement } from "react";

const getIconMapping: getIconMappingType = (value) => {
  const degrees = parseFloat(value) ?? "";
  if (degrees < 0) {
    return (
      <span className="text-primary-500">
        <i className="fas fa-temperature-low"></i>
      </span>
    );
  } else if (degrees < 50 && degrees >= 0) {
    return (
      <span className="text-teal-500">
        <i className="fas fa-thermometer-quarter"></i>
      </span>
    );
  } else if (degrees < 75 && degrees >= 50) {
    return (
      <span className="text-amber-500">
        <i className="fas fa-thermometer-half"></i>
      </span>
    );
  } else if (degrees >= 75) {
    return (
      <span className="text-danger-500">
        <i className="fas fa-temperature-high"></i>
      </span>
    );
  } else {
    return <></>;
  }
};

export const MetricUnitIconMap: FC<MetricUnitIconMapType> = function ({ value, unit }: MetricUnitIconMapType) {
  return getIconMapping(value, unit);
};

type MetricUnitIconMapType = {
  value: string;
  unit: string;
};
type getIconMappingType = (value: string, key: string) => ReactElement;
