import * as Highcharts from "highcharts";
import { ChartWidgetType, HighchartsType } from "./types/enums";
import { path, unnest } from "ramda";
import { NoParamVoidType } from "./types/uiTypes";
import { getObjectFromPropertyValue, populateArrayWithPropertyPath, valueOrDefault } from "./general/helper";
import { getUnits } from "./widgets";
import {Empty} from "antd";

export const CHART_HIGHCHARTS_TYPE_MAPPING: chartHighchartsTypeMapping = {
  LINE_PLOT: HighchartsType.line,
  SCATTER_PLOT: HighchartsType.scatter,
  AREA_PLOT: HighchartsType.area,
};

export const getMaxValueForYAxis: getMaxValueForYAxisType = (seriesData, yThreshold) => Math.max(valueOrDefault(null, yThreshold), ...populateArrayWithPropertyPath(["1"], unnest(populateArrayWithPropertyPath(["data"], valueOrDefault([], seriesData)))));

export const getSeriesObject: getSeriesObjectType = (type, metrics, colors) => {
  switch (type) {
    case ChartWidgetType.LINE_PLOT:
    case ChartWidgetType.AREA_PLOT: {
      return metrics.map(({ parameter, results = [] }, i) => ({
        data: results.map(({ stringValue, timestamp }) => [timestamp, parseFloat(stringValue)]),
        name: parameter.tag,
        color: colors[i],
      }));
    }
    case ChartWidgetType.SCATTER_PLOT: {
      const parseData = (metricsA: MetricType, metricsB: MetricType): number[][] =>
        metricsA.results?.reduce((prev: number[][], curr: ValueType) => {
          const match = getObjectFromPropertyValue("timestamp", curr.timestamp, metricsB.results) as ValueType;
          if (match) return [...prev, [parseFloat(curr.stringValue), parseFloat(match.stringValue)]] as number[][];
          else return prev;
        }, []) ?? [];

      return [
        {
          name: `${valueOrDefault(metrics[0]?.parameter?.tag, "")} v/s ${valueOrDefault(metrics[1]?.parameter?.tag, "")}`,
          data: (metrics.length === 2 ? parseData(metrics[0], metrics[1]) : []) as [number, number][],
          color: colors[0],
        },
      ];
    }
    default:
      return metrics.map(({ parameter, results = [] }, i) => ({
        data: results.map(({ stringValue, timestamp }) => [timestamp, parseFloat(stringValue)]),
        name: parameter.tag,
        color: colors[i],
      }));
  }
};
export const getHighchartsConfig: getHighchartsConfigType = (allowExport, title = "", height, type, metrics, min, max, colors, yThreshold) => {
  const seriesData = valueOrDefault([], getSeriesObject(type, metrics, colors));
  return {
    title: {
      enabled: true,
      text: title,
      align: "left",
      margin: 30,
      style: { color: "rgba(0, 0, 0, 0.85)", fontSize: "12px", fontWeight: 500, fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'" },
    },
    series: seriesData,
    xAxis: {
      min: type !== ChartWidgetType.SCATTER_PLOT ? min : null,
      max: type !== ChartWidgetType.SCATTER_PLOT ? max : null,
      softMin: type == ChartWidgetType.SCATTER_PLOT ? 0 : null,
      softMax: type == ChartWidgetType.SCATTER_PLOT ? 0 : null,
      crosshair: true,
      title: {
        enabled: true,
        text: type == ChartWidgetType.SCATTER_PLOT && (path([0, "parameter", "name"], metrics) ?? ""),
      },
      labels: {
        autoRotationLimit: 100,
      },
      type: type !== ChartWidgetType.SCATTER_PLOT ? "datetime" : null,
    },
    yAxis: {
      softMin: 0,
      softMax: 0,
      crosshair: true,
      title: {
        align: "middle",
        enabled: true,
        useHTML: true,
        style: {
          // fontWeight: type !== ChartWidgetType.SCATTER_PLOT ? "normal" : "",
          color: type !== ChartWidgetType.SCATTER_PLOT ? "black" : "#666666",
        },
        // text: "Custom with <b>simple</b> <i>markup</i>",
        text: type === ChartWidgetType.SCATTER_PLOT ? path([1, "parameter", "name"], metrics) : getUnits(metrics[0]?.parameter?.unit),
      },
      max: Math.max(getMaxValueForYAxis(seriesData, yThreshold)),
      plotLines: yThreshold
        ? [
            {
              value: yThreshold,
              width: 1,
              color: "#d02525",
            },
          ]
        : [],
    },
    time: {
      useUTC: false,
    },
    scrollbar: {
      liveRedraw: false,
    },
    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      height: height,
      zoomType: "xy",
      type: CHART_HIGHCHARTS_TYPE_MAPPING[type],
      reflow: true,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG", "separator", "downloadCSV", "downloadXLS"],
        },
      },
      enabled: allowExport,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "top",
        padding: 5,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "center",
            },
          },
        },
      ],
    },
    lang: {
      noData: 'No data',
    }
  };
};
export const reflowAllCharts: NoParamVoidType = () => {
  Highcharts.charts.forEach((chart) => {
    chart?.reflow();
  });
};

export type MetricType = {
  parameter: ParameterType;
  results: Array<ValueType>;
};

export type ParameterType = {
  tag: string;
  name: string;
  unit?: string;
  dataType: "LONG" | "DOUBLE" | "BOOLEAN" | "STRING" | "UNDEFINED";
};
export type ValueType = {
  stringValue: string;
  timestamp: number;
};

type chartHighchartsTypeMapping = {
  [k: string]: HighchartsType;
};
type getSeriesObjectType = (type: ChartWidgetType, metrics: Array<MetricType>, colors: Array<string>) => Array<SeriesObjectType> | SeriesObjectType;
type getHighchartsConfigType = (allowExport: boolean, title: string, height: number, type: ChartWidgetType, metrics: Array<MetricType>, min: number | undefined, max: number | undefined, colors: Array<string>, yThreshold: number | boolean) => any;

type SeriesObjectType = {
  data: [number, number][];
  color: string;
  name?: string;
};

type getMaxValueForYAxisType = (seriesData: SeriesObjectType[], yThreshold: number | boolean) => number;
