import React, { FC, ReactElement } from "react";
import { BulbFilled, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
const getIconMapping: getIconMappingType = (key) => {
  switch (key) {
    case "ON":
     return <span className="text-success-500">
        <i className="fas fa-toggle-on"></i>
      </span>
    case "OFF":
      return <span className="text-danger-500">
        <i className="fas fa-power-off"></i>
      </span>
    case "UNAVAILABLE":
    case "NOT AVAILABLE":
      return (
        <span className="text-danger-500">
          <CloseCircleOutlined />
        </span>
      );
    case "Idle":
    case "AVAILABLE":
    case "ACTIVE":
    case "OK":
      return (
        <span className="text-success-500">
          <CheckCircleOutlined />
        </span>
      );
    case "CLOSED":
      return (
        <span>
          <i className="fa fa-lock text-danger-500"></i>
        </span>
      );
    case "NOT_LOCKED":
      return (
        <span>
          <i className="fa fa-unlock-alt text-success-500"></i>
        </span>
      );
    case "GREEN":
      return (
        <span>
          <BulbFilled className={"text-success-500"} />
        </span>
      );
    case "YELLOW":
      return (
        <span>
          <BulbFilled className={"text-warning-500"} />
        </span>
      );
    case "RED":
      return (
        <span>
          <BulbFilled className={"text-danger-500"} />
        </span>
      );
    case "ALERT":
      return (
        <span>
          <i className="fas fa-exclamation-triangle text-danger-500"></i>
        </span>
      );
    case "Stop":
      return (
          <span>
            <i className="fas fa-octagon text-danger-500"></i>
        </span>
      );
    default:
      return <></>;
  }
};

export const MetricValueIconMap: FC<MetricValueIconMapType> = function ({ value }: MetricValueIconMapType) {
  return getIconMapping(value);
};

type MetricValueIconMapType = {
  value: string;
};
type getIconMappingType = (key: string) => ReactElement;
