import { FC, ReactNode } from "react";

/** General **/
export type NoParamVoidType = () => void;

/** API Response **/
export type ResponseType<T = any> = {
  ok: boolean;
  status: number;
  data: T;
  headers: Headers;
};

/** Errors **/
export type ErrorType = {
  error?: ResponseType;
  message?: string;
};

/** Forms **/
export enum ACTION_TYPES {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export enum FORM_STATUS_TYPE {
  LOADING,
  SUCCESS,
  FAILED,
  NONE,
}

/** Dates **/
export type DateOptionType = {
  key: string;
  amount?: number;
  label?: ReactNode;
  abbreviation?: string | ReactNode;
  style?: string;
};

/** Menu Type **/
export type MenuItemType = {
  Component?: FC;
  exact: boolean;
  path: string | string[];
  title: ReactNode;
  key: string;
  icon?: ReactNode;
  permission: boolean;
  hoverPrefix?: ReactNode;
  hoverSuffix?: ReactNode;
};

export type handleErrorType = (message: string, error?: any) => void;
