import React, { FC, ReactNode, useState } from "react";
import { Button, message, Spin, Table, Tag, Tooltip } from "antd";
import { MetricType, MonitoringRuleSetResultType, MonitoringRuleType } from "../../utils/types/types";
import { getMonitorResults } from "../../services/services";
import moment from "moment";
import { ResponseType } from "../../utils/types/uiTypes";
import { getAlertRuleDescription, RULE_SET_STATUS_COLOR } from "../../utils/alerts/alert";
import { MonitoringRuleResultType, MonitoringRuleSetStatusType } from "../../utils/types/enums";
import { path } from "ramda";
import { displayDashAsDefault, getFloatValue, valueOrDefault } from "../../utils/general/helper";
import { getPopupContainer } from "../../utils/general/common";
import { useAutoRefresh } from "../../custom-hooks/useAutoRefresh";

export const MONITORING_RULE_RESULT_ICON = {
  PASS: <i className="fa fa-check-circle text-success-500"></i>,
  INCONCLUSIVE: <i className="fa fa-times-circle text-danger-500"></i>,
  FAIL: <i className="fa fa-times-circle text-danger-500"></i>,
};

const HISTORY_TABLE_COLUMNS = [
  {
    title: <span className={"text-xs text-gray-500"}>Status</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "status",
    key: "status",
    width: "40%",
    render: function getStatus(status: MonitoringRuleSetStatusType): ReactNode {
      return <Tag className={`text-xs ${RULE_SET_STATUS_COLOR[status]}`}>{status}</Tag>;
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Executed At</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "executedAt",
    key: "executedAt",
    width: "40%",
    render: function getExecutedAt(executedAt: string): ReactNode {
      return <div>{moment(executedAt).format("lll").valueOf()}</div>;
    },
  },
];

const EXECUTION_TABLE_COLUMNS = [
  {
    title: <span className={"text-xs text-gray-500"}>Condition</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "rule",
    key: "rule",
    render: function getDescription(_: any, o: MonitoringRuleType): ReactNode {
      return getAlertRuleDescription(o.primaryMetric, o.secondaryMetric, o.threshold, o.condition);
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Expected Value</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "primaryMetric",
    key: "primaryMetric",
    render: function getSecondaryMetric(_: any, { secondaryMetric, threshold }: MonitoringRuleType): ReactNode {
      return secondaryMetric ? displayDashAsDefault(getFloatValue(path(["results", 0, "stringValue"], secondaryMetric), 2)) : displayDashAsDefault(path(["value"], threshold));
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Actual Value</span>,
    className: "text-xs xl:text-sm",
    dataIndex: "primaryMetric",
    key: "primaryMetric",
    render: function getPrimaryyMetric(metric: MetricType): ReactNode {
      const value = path(["results", 0, "stringValue"], metric);
      return valueOrDefault(value, getFloatValue(value, 2));
    },
  },
  {
    title: <span className={"text-xs text-gray-500"}>Status</span>,
    className: "text-xs xl:text-sm text-center",
    dataIndex: "result",
    key: "result",
    render: function getStatus(result: MonitoringRuleResultType): ReactNode {
      return (
        <Tooltip title={result} getPopupContainer={getPopupContainer} placement={"bottom"}>
          <span className={"text-base"}>{MONITORING_RULE_RESULT_ICON[result]}</span>
        </Tooltip>
      );
    },
  },
];

export const MonitorHistory: FC<MonitorHistoryType> = function ({ deviceId, monitorId, innerClassname = "" }: MonitorHistoryType) {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<MonitoringRuleSetResultType[]>([]);

  const getExpandedExecutionView: getExpandedExecutionViewType = (record) => {
    return (
      <div>
        <div className={"flex flex-col m-2 border"}>
          <Table style={{ backgroundColor: "#fbfbfb" }} columns={EXECUTION_TABLE_COLUMNS} dataSource={record?.ruleSet?.rules} pagination={false} />
        </div>
      </div>
    );
  };

  const getHistory: getHistoryType = (deviceId, monitorId) => {
    getMonitorResults({
      segments: { deviceId, id: monitorId, from: moment(Date.now()).subtract(1, "h").valueOf(), to: moment(Date.now()).valueOf() },
    })
      .then(({ data }: ResponseType<{ results: Array<MonitoringRuleSetResultType> }>) => {
        setHistory(data.results);
      })
      .catch(() => {
        message.error("Unable to show monitor history at this time");
      })
      .then(() => {
        setLoading(false);
      });
  };

  useAutoRefresh(
    () => {
      getHistory(deviceId, monitorId);
    },
    true,
    [deviceId, monitorId]
  );

  return (
    <>
      <div className={"h-full w-full"}>
        {loading ? (
          <div className={"inline-flex h-full w-full items-center justify-center"}>
            <Spin spinning={loading} />
          </div>
        ) : (
          <div className={`${innerClassname}`}>
            <Table
              rowKey={"executedAt"}
              bordered={false}
              columns={HISTORY_TABLE_COLUMNS}
              expandable={{
                expandedRowRender: getExpandedExecutionView,
                expandIconColumnIndex: 3,
                columnWidth: "20%",
                expandIcon: ({ expanded, onExpand, record }): ReactNode => (
                  <Button
                    onClick={(e): void => {
                      onExpand(record, e);
                    }}
                  >
                    {expanded ? "Hide Details" : "View Details"}
                  </Button>
                ),
              }}
              dataSource={history}
              pagination={{ hideOnSinglePage: true, defaultPageSize: 15, showSizeChanger: true, pageSizeOptions: ["10", "15", "25", "50"] }}
            />
          </div>
        )}
      </div>
    </>
  );
};

type MonitorHistoryType = {
  deviceId: string;
  monitorId: string;
  innerClassname?: string;
};
type getHistoryType = (deviceId: string, monitorId: string) => void;
type getExpandedExecutionViewType = (record: MonitoringRuleSetResultType) => ReactNode;
