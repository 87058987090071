import React, { FC, ReactNode, useEffect, useState } from "react";
import { message, Table, Tag, Tooltip } from "antd";
import { DeviceLogType, PaginationContainer } from "../../../utils/types/types";
import { getDeviceMessages } from "../../../services/services";
import { getHumanizedElapsedTime } from "../../../utils/general/dates";
import { ResponseType } from "../../../utils/types/uiTypes";
import { useParams } from "react-router";
import { usePageTitle } from "../../../custom-hooks/usePageTitle";
import { valueOrDefault } from "../../../utils/general/helper";
import { PageLayout } from "../../layout/PageLayout";
import { ColumnsType } from "antd/es/table/Table";
import moment from "moment";
import {LogTypeType} from "../../../utils/types/enums";
import {TABLE_PAGINATION_STYLE} from "../../../utils/general/styles";
import { useAutoRefresh } from "../../../custom-hooks/useAutoRefresh";

export const LOG_STYLE_STATUS: Record<LogTypeType, string> = {
  "Fatal Error": "text-white bg-danger-500 border-danger-500",
  Error: "text-white bg-danger-500 border-danger-500",
  Info: "text-white bg-primary-500 border-primary-500",
  Warning: "text-white bg-warning-500 border-warning-500"
};

const columns: ColumnsType<DeviceLogType> = [
  {
    title: <span className={"text-xs text-gray-500"}>Logs</span>,
    dataIndex: "message",
    className: "text-xs xl:text-sm",
    key: "message",
    render: (message: string, record) => (
      <div className={"flex flex-col gap-1"}>
        <div className={"flex flex-row gap-2 items-center"}>
          <span className={'text-primary-500 text-xs font-semibold'}>{record.messageId}</span>
          {record.type && <Tag className={`${LOG_STYLE_STATUS[record.type]} text-xxs tracking-tighter w-fit`}>{record.type.toUpperCase()}</Tag>}
        </div>
        <span>{message}</span>
      </div>
    ),
  },
  {
    title: <span className={"text-xs text-gray-500"}>Raised At</span>,
    key: "raisedAt",
    className: "text-xs xl:text-sm",
    dataIndex: "raisedAt",
    width: "150px",
    render: function getRaisedAt(raisedAt: number): ReactNode {
      return <Tooltip title={moment(raisedAt)?.format("lll")}>{getHumanizedElapsedTime(raisedAt)}</Tooltip>;
    },
  },
];

export const DeviceLogs: FC = function () {
  usePageTitle("Logs");
  const { deviceId }: ParamsType = useParams();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<DeviceLogType[]>([]);

  const getMessages: getMessagesType = (id) => {
    getDeviceMessages({
      segments: {
        id,
      },
      params: {
        page: 0,
        size: 100,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<DeviceLogType>>) => {
        setMessages(valueOrDefault([], data.results));
      })
      .catch((error) => {
        console.log(error);
        message.error("Unable to load device logs");
      })
      .then(() => {
        setLoading(false);
      });
  };

  useAutoRefresh(
    () => {
      getMessages(deviceId);
    },
    true,
    [deviceId]
  );

  return (
    <PageLayout height={"full"} loading={loading} loadingText={"Loading Device Logs ..."}>
      <div className={"border shadow-xs max-h-full overflow-y-auto hide-scrollbar bg-white"}>
        <Table rowKey={"id"} bordered={false} sticky={true} columns={columns} dataSource={messages} scroll={{ x: 1200 }} pagination={TABLE_PAGINATION_STYLE} />
      </div>
    </PageLayout>
  );
};

type ParamsType = {
  deviceId: string;
};

type getMessagesType = (deviceId: string) => void;
