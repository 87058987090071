import { DependencyList, MutableRefObject, useEffect, useRef } from "react";
import { valueOrDefault } from "../utils/general/helper";

//TODO: pass props through object
export const useInterval: useIntervalType = (callback, delay, callWithoutDelay = true, dependencies = [], startByDefault = true) => {
  const savedCallback = useRef(callback);
  //TODO: timer is number -> useref not needed
  const timer = useRef<NodeJS.Timer>();

  const executeInterval: executeIntervalType = (newDelay) => {
    if (callWithoutDelay) savedCallback.current();
    timer.current = setInterval(() => {
      savedCallback.current();
    }, valueOrDefault(delay, newDelay));
  };

  const terminateInterval: terminateIntervalType = ({ current }) => {
    current && clearInterval(current);
    timer.current = undefined;
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (startByDefault && delay !== null) {
      callWithoutDelay && savedCallback.current();
      executeInterval();
    }
    return (): void => terminateInterval(timer);
  }, dependencies);

  return [timer, terminateInterval, executeInterval];
};

type terminateIntervalType = (timer: MutableRefObject<NodeJS.Timer | undefined>) => void;
type executeIntervalType = (newDelay?: number) => void;
type useIntervalType = (callback: () => void, delay: number, callWithoutDelay?: boolean, dependencies?: DependencyList, startByDefault?: boolean) => [MutableRefObject<NodeJS.Timer | undefined>, terminateIntervalType, executeIntervalType];
