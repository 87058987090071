import React, { FC } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import { MonitorDetails } from "../../alert/MonitorDetails";
import { usePageTitle } from "../../../custom-hooks/usePageTitle";
import { MonitorList } from "../../alert/MonitorsList";

export const DeviceMonitors: FC = function () {
  usePageTitle("Monitors");
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <MonitorList />
        </Route>
        <Route path={`${path}/:monitorId`}>
          <MonitorDetails isDashboard={false} />
        </Route>
      </Switch>
    </>
  );
};
