import { useState, useEffect } from "react";

const getWindowDimensions: getWindowDimensionsType = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function useWindowDimensions(): WindowType {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize: () => void = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
}

type WindowType = {
  width: number;
  height: number;
};
type getWindowDimensionsType = () => WindowType;
