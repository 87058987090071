import React, { FC } from "react";

const SIZES = {
  contain: 'bg-contain',
  cover: 'bg-cover',
};
const POSITION = {
  center: 'bg-center',
  left: 'bg-left',
  right: 'bg-right',
};

export const BackgroundImage: FC<BackgroundImageType> = function ({ src, position = 'center', size = 'cover', border }: BackgroundImageType) {
  return (
    <div
      style={{ backgroundImage: `url(${src})` }}
      className={`relative flex bg-clip-padding bg-origin-padding bg-no-repeat w-full h-full ${border ? 'border border-grey-400' : ''} ${POSITION[position]} ${
        SIZES[size]
      }`}
    />
  );
};

type BackgroundImageType = {
  src: string;
  position?: 'center' | 'left' | 'right';
  size?: 'contain' | 'cover';
  border?: boolean;
};
