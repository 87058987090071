import { MessageOutlined, PrinterOutlined, SettingOutlined } from "@ant-design/icons";
import { DeviceOverview } from "../components/device/details/DeviceOverview";
import { DeviceParameters } from "../components/device/details/DeviceParameters";
import { DeviceLogs } from "../components/device/details/DeviceLogs";
import React from "react";
import { MenuItemType } from "./types/uiTypes";
import { DeviceJobs } from "../components/device/details/DeviceJobs";
import { DeviceMonitors } from "../components/device/details/DeviceMonitors";
import { ModelType } from "./types/enums";
import { DeviceModelType } from "./types/types";

const getLabel = (type: ModelType) => {
  switch (type) {
    case ModelType.POST_PROCESSING_MACHINE:
      return "Post Processing Jobs"
    default:
      return "Print Jobs"
  }
}
export const MENU_ITEMS: getMenuItemsType = (model) => {
  return [
    {
      Component: DeviceOverview,
      path: "/devices/:deviceId/overview",
      exact: true,
      title: "Overview",
      key: "overview",
      icon: <SettingOutlined />,
      permission: true,
    },
    {
      Component: DeviceParameters,
      path: "/devices/:deviceId/parameters",
      exact: true,
      title: "Parameters",
      key: "parameters",
      icon: <i className="fas fa-list"></i>,
      permission: true,
    },
    {
      Component: DeviceJobs,
      path: "/devices/:deviceId/jobs",
      exact: true,
      title: getLabel(model.type),
      key: "jobs",
      icon: <PrinterOutlined />,
      permission: model.manufacturer.name !== "SLM Solutions",
    },
    {
      Component: DeviceMonitors,
      path: "/devices/:deviceId/monitors",
      exact: false,
      title: "Monitors",
      key: "monitors",
      icon: <i className="fas fa-binoculars" />,
      permission: true,
    },
    {
      Component: DeviceLogs,
      path: "/devices/:deviceId/logs",
      exact: true,
      title: "Logs",
      key: "logs",
      icon: <MessageOutlined />,
      permission: model.type === ModelType.PRINTER && model.manufacturer.name !== "SLM Solutions",
    },
  ];
};

type getMenuItemsType = (type: DeviceModelType) => Array<MenuItemType>;
