import { MonitoringRuleConditionType, MonitoringRuleSetStatusType, PrinterStatusType } from "../types/enums";
import React, { ReactNode } from "react";
import { MetricType, MonitoringRuleSetDetailsType, ThresholdType } from "../types/types";
import { isNil, path } from "ramda";
import { valueOrDefault } from "../general/helper";

export const RULE_SET_STATUS_COLOR: RuleSetStatusType = {
  OK: "text-white bg-success-500 border-success-500",
  ALERT: "text-white bg-danger-500 border-danger-500",
};

export const WAIT_TIMES: Array<WaitTimeOptionsType> = [
  {
    key: "10_MINS",
    label: "10 minutes",
    value: 600000,
  },
  {
    key: "20_MINS",
    label: "20 minutes",
    value: 1200000,
  },
  {
    key: "30_MINS",
    label: "30 minutes",
    value: 1800000,
  },
  {
    key: "40_MINS",
    label: "40 minutes",
    value: 2400000,
  },
  {
    key: "50_MINS",
    label: "50 minutes",
    value: 3000000,
  },
  {
    key: "60_MINS",
    label: "60 minutes",
    value: 3600000,
  },
  {
    key: "90_MINS",
    label: "90 minutes",
    value: 5400000,
  },
  {
    key: "120_MINS",
    label: "2 hours",
    value: 7200000,
  },
  {
    key: "180_MINS",
    label: "3 hours",
    value: 10800000,
  },
];

export const COMPARISON_TYPES: Array<OptionType> = [
  {
    key: "ALL",
    label: "All",
  },
  {
    key: "ANY",
    label: "Any",
  },
];
export const CONDITION_OPTIONS: Array<OptionType> = [
  {
    key: "GREATER",
    value: ">",
    label: "Greater than",
  },
  {
    key: "GREATER_EQUAL",
    value: "≥",
    label: "Greater than or equal to",
  },
  {
    key: "LOWER",
    value: "<",
    label: "Less than",
  },
  {
    key: "LOWER_EQUAL",
    value: "≤",
    label: "Less than or equal to",
  },
  {
    key: "EQUAL",
    value: "=",
    label: "Equal",
  },
];

export const PRINTER_STATUSES: Array<OptionType> = [
  {
    key: "IDLE",
    label: (
      <span className={"flex flex-row gap-2 items-center"}>
        <i className="fas fa-check text-success-500" />
        Idle
      </span>
    ),
  },
  {
    key: "BUSY",
    label: (
      <span className={"flex flex-row gap-2 items-center"}>
        <i className="fas fa-minus-circle text-danger-500" />
        Busy
      </span>
    ),
  },
  {
    key: "PRINTING",
    label: (
      <span className={"flex flex-row gap-2 items-center"}>
        <i className="fas fa-print text-primary-500" />
        Printing
      </span>
    ),
  },
  {
    key: "WAITING",
    label: (
      <span className={"flex flex-row gap-2 items-center"}>
        <i className="fas fa-circle-notch animate-spin text-warning-500" />
        Waiting
      </span>
    ),
  },
  {
    key: "UNKNOWN",
    label: (
      <span className={"flex flex-row gap-2 items-center"}>
        <i className="fas fa-exclamation-circle text-muted" />
        Unknown
      </span>
    ),
  },
];

export const PRINTER_SETTING_OPTIONS: Array<SettingOptionsType> = [
  {
    key: PrinterStatusType.PRINTER_STATE,
    label: "Select Printer Statuses",
    options: PRINTER_STATUSES,
  },
];

export const MONITORING_RULE_RESULT_STATUS_STYLE = {
  PASS: "text-white bg-success-500 border-success-500",
  INCONCLUSIVE: "text-white bg-warning-500 border-warning-500",
  FAIL: "text-white bg-danger-500 border-danger-500",
};

export const getAlertRuleDescription: getAlertRuleDescriptionType = (primaryMetric, secondaryMetric, threshold, comparison) => {
  const comparisonValue = valueOrDefault(
    "",
    path(
      ["0"],
      CONDITION_OPTIONS.filter(({ key }) => key == comparison)
    ) as OptionType
  )?.value;
  const secondary = isNil(secondaryMetric) ? [threshold?.value] : [secondaryMetric?.aggregation?.toLowerCase(), "(" + (secondaryMetric?.parameter.tag ?? "") + ")"];
  return (
    <>
      {primaryMetric.aggregation?.toLowerCase()}
      {"(" + primaryMetric.parameter.tag + ")"}&nbsp;<span className={"font-bold"}>{comparisonValue}</span>&nbsp;{secondary.join("")}
    </>
  );
};

export const getNumberOfAlerts: getNumberOfAlertsType = (monitors = []) => {
  return monitors?.filter(({ currentStatus }) => currentStatus?.status === MonitoringRuleSetStatusType.ALERT).length;
};

type RuleSetStatusType = Record<MonitoringRuleSetStatusType, string>;
type OptionType = {
  key: string;
  label: ReactNode;
  value?: string;
};
type SettingOptionsType = {
  key: PrinterStatusType;
  label: string;
  options?: Array<OptionType>;
};
type WaitTimeOptionsType = {
  key: string;
  label: string;
  value: number;
};
type getAlertRuleDescriptionType = (primaryMetric: MetricType, secondaryMetric: MetricType | undefined, threshold: ThresholdType | undefined, comparison: MonitoringRuleConditionType) => ReactNode;
type getNumberOfAlertsType = (monitors?: Array<MonitoringRuleSetDetailsType>) => number;
