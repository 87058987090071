import React, { FC, ReactElement } from "react";

const MAX_WIDTH = {
  xs: 'max-w-md',
  sm: 'max-w-md sm:max-w-screen-sm',
  md: 'max-w-md sm:max-w-screen-sm md:max-w-screen-md',
  lg: 'max-w-md sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg',
  xl: 'max-w-md sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl',
  full: 'max-w-full',
};
export const Layout: FC<LayoutType> = ({ className = '', size = 'full', children }) => (
  <div className={`w-full mx-auto ${className} ${size ? MAX_WIDTH[size] : ''}`}>{children}</div>
);

type LayoutType = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  className?: string;
  children: ReactElement | ReactElement[];
};
