import React, { FC } from "react";
import { useDrop } from "react-dnd";
import { DeleteOutlined } from "@ant-design/icons";
import { DashboardLayoutType } from "../../../../utils/types/enums";

export const TrashDropZone: FC<TrashDropZoneType> = ({ accept, onDrop }: TrashDropZoneType) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    canDrop: () => {
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <div ref={drop} className={`h-20 flex flex justify-center items-center bg-white ${isOver && canDrop ? "border border-dashed border-danger-500 text-danger-500" : "border border-gray-200 text-red-400"}`}>
      <div className={"text-center"}>
        <DeleteOutlined role={"button"} className={"text-3xl cursor-default"} />
        <div className={"text-xs"}>Drag here to remove</div>
      </div>
    </div>
  );
};

type TrashDropZoneType = {
  className?: any;
  text?: string;
  accept: Array<DashboardLayoutType>;
  onDrop: any;
};
