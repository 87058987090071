import React, { FC, ReactNode, useState } from "react";
import { ConnectionType, DeviceConnectionsStatusType, DeviceType, GatewayInstanceType, MachineConnectivityAgentType, PaginationContainer } from "../../utils/types/types";
import { indexOf, isNil, path } from "ramda";
import { displayDashAsDefault, updateItemInArray } from "../../utils/general/helper";
import { message, Switch, Table, TableColumnsType, Tag, Tooltip } from "antd";
import moment from "moment";
import { getHumanizedElapsedTime, getTimeFromNowByUnit } from "../../utils/general/dates";
import { getGatewayConnections, updateAgent } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { getConnectionStatus, STATUS_STYLE } from "../../utils/connection";
import { DeviceStatusType, MachineConnectivityAgentStatus } from "../../utils/types/enums";
import Paragraph from "antd/es/typography/Paragraph";
import { Link } from "react-router-dom";
import { SearchBar } from "../general/SearchBar";
import { useAutoRefresh } from "../../custom-hooks/useAutoRefresh";
import { CustomTag } from "../general/CustomTag";

export const GatewayConnection: FC<GatewayConnectionType> = ({ gateway }: GatewayConnectionType) => {
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState<string>("");
  const [gatewayConnections, setGatewayConnections] = useState<GatewayConnectionDTOType[]>([]);

  const COLUMNS: TableColumnsType<GatewayConnectionDTOType> = [
    {
      title: "Device",
      className: "text-xs",
      dataIndex: ["device", "id"],
      render: (id: string, gateway): ReactNode => (
        <Link to={`/devices/${id}/overview`} target="_blank" rel="noopener noreferrer" className={"group cursor-pointer flex flex-col"}>
          <span className={"group-hover:underline underline-offset-1 text-primary-500"}>{gateway.device.name}</span>
          <span className={"text-gray-400 text-xxs tracking-tighter"}>ID: {id}</span>
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: ["device", "currentStatus"],
      className: "text-xs",
      render: function status(status: DeviceConnectionsStatusType): ReactNode {
        return (
          <>
            <Tag className={`text-xs p-1 rounded-full ${status ? STATUS_STYLE[status.status] : STATUS_STYLE[DeviceStatusType.UNKNOWN]}`} />
            {getConnectionStatus(status.status, status.activeConnections, status.totalConnections)}
          </>
        );
      },
    },
    {
      title: "Integrations",
      className: "text-xs",
      dataIndex: ["connections"],
      render: (connections: ConnectionType[], record): ReactNode => {
        return (
          <div className={"flex flex-col items-start gap-2 w-full justify-center"}>
            {connections.map(({ agent, status, updatedAt }, connectionIndex) => (
              <div key={agent.id} className={"flex flex-row items-center justify-between w-full"}>
                <Tooltip title={getHumanizedElapsedTime(updatedAt)} placement={"right"}>
                  <div className={"flex flex-row items-center cursor-pointer"}>
                    <Tag className={`inline-block text-xs overflow-hidden shrink truncate px-2 py-1 rounded-sm ${status ? STATUS_STYLE[status] : STATUS_STYLE[DeviceStatusType.UNKNOWN]}`}>
                      {agent.connectivityProviderType.replaceAll("_", " ")}&nbsp;&nbsp;<i className="fas fa-info-circle text-white/50" />
                    </Tag>
                  </div>
                </Tooltip>
                <Switch
                  onChange={(val): void => {
                    update(record, val ? MachineConnectivityAgentStatus.ACTIVE : MachineConnectivityAgentStatus.PAUSED, connectionIndex, gatewayConnections);
                  }}
                  className={`${agent.status == MachineConnectivityAgentStatus.ACTIVE ? "bg-primary-500" : "bg-dark-400"}`}
                  checked={agent.status == MachineConnectivityAgentStatus.ACTIVE}
                />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const fetchConnections = (id: string): void => {
    getGatewayConnections({
      segments: { id },
    })
      .then(({ data }: ResponseType<PaginationContainer<GatewayConnectionDTOType>>) => {
        setGatewayConnections(data?.results.sort((a, b) => (a.device.name > b.device.name ? 1 : -1)) ?? []);
      })
      .catch(() => {
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const update: updateType = (gateway, status, connectionIndex, allConnections) => {
    const connection = path([connectionIndex], gateway.connections) as ConnectionType;
    updateAgent({
      segments: {
        deviceId: gateway.device.id,
        id: connection.agent.id,
      },
      body: JSON.stringify({
        id: connection?.id,
        equipmentId: gateway.device.id,
        connectivityProviderType: connection?.agent?.connectivityProviderType,
        gatewayId: connection.agent.gatewayId,
        status: status,
        requestParameters: connection.agent.requestParameters,
      }),
    })
      .then(({ data }: ResponseType<MachineConnectivityAgentType>) => {
        const deviceIndex = indexOf(gateway, gatewayConnections);
        setGatewayConnections(updateItemInArray(deviceIndex, allConnections, { device: gateway.device, connections: updateItemInArray(connectionIndex, gateway.connections, { ...connection, agent: data }) }));
      })
      .catch(() => {
        message.error("Unable to update connection");
      });
  };

  useAutoRefresh(
    () => {
      fetchConnections(gateway?.id);
    },
    true,
    [gateway?.id]
  );

  return (
    <>
      <div className={"flex flex-col divide-y divide-gray-200"}>
        <span className={"flex flex-row w-full text-xs font-medium py-2 items-center"}>
          Name <span className={"ml-auto font-normal"}>{gateway.name}</span>
        </span>
        <span className={"flex flex-row w-full text-xs font-medium py-2 items-center"}>
          UUID
          <span className={"ml-auto font-normal"}>
            {isNil(gateway.uuid) ? (
              <>{displayDashAsDefault(gateway.uuid)}</>
            ) : (
              <CustomTag>
                <Paragraph className={'flex mb-0'} copyable>
                  {gateway.uuid}
                </Paragraph>
              </CustomTag>
            )}
          </span>
        </span>
        <span className={"flex flex-row w-full text-xs font-medium py-2 items-center"}>
          Last Synced
          <span className={"ml-auto font-normal"}>
            {isNil(gateway.lastSyncedAt) ? (
              <>{displayDashAsDefault(gateway.lastSyncedAt)}</>
            ) : (
              <Tooltip title={moment(gateway.lastSyncedAt).format("lll").valueOf()}>
                <span className={`${getTimeFromNowByUnit(gateway.lastSyncedAt, "minutes") <= 5 ? "text-success-500" : "text-danger-500"}`}>{getHumanizedElapsedTime(gateway.lastSyncedAt)}</span>
              </Tooltip>
            )}
          </span>
        </span>
      </div>
      <div className={"flex flex-row items-center justify-end mt-5 mb-3"}>
        <SearchBar size={"SMALL"} placeholder={"Search Devices"} onSearch={setQuery} />
      </div>
      <div className={"border max-h-full overflow-y-scroll hide-scrollbar"}>
        <Table loading={loading} rowKey={(gateway): string => gateway.device.id} sticky={true} bordered={false} columns={COLUMNS} dataSource={gatewayConnections.filter((gateway) => (query === "" ? true : gateway.device.name.toLowerCase().includes(query.toLowerCase())))} pagination={false} />
      </div>
    </>
  );
};

type GatewayConnectionType = {
  gateway: GatewayInstanceType;
};

type GatewayConnectionDTOType = {
  device: DeviceType;
  connections: ConnectionType[];
};
type updateType = (gateway: GatewayConnectionDTOType, status: MachineConnectivityAgentStatus, connectionIndex: number, connections: GatewayConnectionDTOType[]) => void;
