import React, { FC, ReactNode, useEffect, useState } from "react";
import { PaginationContainer, PrintJobType } from "../../utils/types/types";
import { Collapse, Progress, Table, Tag } from "antd";
import { getDeviceJobs } from "../../services/services";
import moment from "moment";
import { ResponseType } from "../../utils/types/uiTypes";
import { JobStatusType } from "../../utils/types/enums";
import { valueOrDefault } from "../../utils/general/helper";
import { Spinner } from "../general/Spinner";
import { useHistory } from "react-router";
import { IconButton } from "../general/IconButton";
import { JobModal } from "./JobModal";
import { useAutoRefresh } from "../../custom-hooks/useAutoRefresh";

const { Panel } = Collapse;

const STATUS_STYLE = {
  COMPLETED: "text-white bg-success-500 border-success-500",
  FAILED: "text-white bg-danger-500 border-danger-500",
  IN_PROGRESS: "text-white bg-primary-500 border-primary-500",
  QUEUED: "text-white bg-warning-500 border-warning-500",
  PAUSED: "text-white bg-warning-500 border-warning-500",
  CANCELLED: "text-white bg-danger-500 border-danger-500",
  ABORTED: "text-white bg-danger-500 border-danger-500",
  WAITING: "text-white bg-gray-300 border-gray-300",
  UNKNOWN: "text-white bg-gray-300 border-gray-300",
};

export const JobsList: FC<JobsListType> = function ({ size, deviceId, title = "", statuses = [] }: JobsListType) {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [jobs, setJobs] = useState<PrintJobType[]>([]);
  const [jobsContainer, setJobsContainer] = useState<PaginationContainer<PrintJobType> | null>(null);
  const [selectedJob, setSelectedJob] = useState<PrintJobType | null>(null);
  const [pager, setPager] = useState<string>("1");
  const [pageSize, setPageSize] = useState<string>("20");

  const columns = [
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Job</span>,
      className: "text-xs xl:text-sm",
      dataIndex: "name",
      key: "name",
      width: "300px",
      render: function getJobDetails(name: string, { id }: PrintJobType): ReactNode {
        return (
          <div className={"overflow-hidden truncate"}>
            <Tag color={"black"} className={"w-16 text-xxs text-center opacity-50"}>
              {id}
            </Tag>
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Source Job Id</span>,
      className: "text-xs xl:text-sm",
      key: "originalId",
      dataIndex: "originalId",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>File Name</span>,
      className: "text-xs xl:text-sm",
      key: "filename",
      dataIndex: "filename",
      width: "200px",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Progress</span>,
      key: "percentComplete",
      dataIndex: "percentComplete",
      width: "150px",
      render: function getString(percentage: number): ReactNode {
        return (
          <div className={"w-24"}>
            <Progress className={"text-xs xl:text-sm"} size="small" showInfo={true} percent={Number(percentage?.toFixed(2) ?? 0)} />
          </div>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Status</span>,
      key: "status",
      dataIndex: "status",
      width: "100px",
      render: function getStatus(status: JobStatusType = JobStatusType.UNKNOWN): ReactNode {
        return <Tag className={`text-xs xl:text-sm px-2 ${STATUS_STYLE[status]}`}>{status.replace("_", " ")}</Tag>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Submission Date</span>,
      className: "text-xs xl:text-sm",
      key: "submissionDate",
      dataIndex: "submissionDate",
      width: "200px",
      render: function getSubmissionDate(submissionDate: number): ReactNode {
        return <span className={"empty:after:content-['--'] empty:text-muted"}>{submissionDate ? moment(submissionDate)?.format("lll") : ""}</span>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Start Date</span>,
      className: "text-xs xl:text-sm",
      key: "startDate",
      dataIndex: "startDate",
      width: "200px",
      render: function getDate(startDate = ""): ReactNode {
        return <span className={"empty:after:content-['--'] empty:text-muted"}>{startDate ? moment(parseInt(startDate)).format("lll").toString() : ""}</span>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>End Date</span>,
      className: "text-xs xl:text-sm",
      key: "endDate",
      dataIndex: "endDate",
      width: "200px",
      render: function getDate(endDate = ""): ReactNode {
        return <span className={"empty:after:content-['--'] empty:text-muted"}>{endDate ? moment(parseInt(endDate)).format("lll").toString() : ""}</span>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Estimated Time</span>,
      className: "text-xs xl:text-sm",
      key: "estimatedTime",
      dataIndex: "estimatedTime",
      width: "150px",
      render: function getEstimatedTime(time: string): ReactNode {
        return <span className={"empty:after:content-['--'] empty:text-muted"}>{time ? moment.duration(time, "second").humanize() : ""}</span>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Elapsed Time</span>,
      className: "text-xs xl:text-sm",
      key: "elapsedTime",
      dataIndex: "elapsedTime",
      width: "150px",
      render: function getElapsedTime(time: string): ReactNode {
        return <span className={"empty:after:content-['--'] empty:text-muted"}>{time ? moment.duration(time, "second").humanize() : ""}</span>;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Layer Count</span>,
      className: "text-xs xl:text-sm",
      key: "layerCountTarget",
      width: "150px",
      render: (_: any, record: any): ReactNode => {
        return `${record.layerCountCurrent} / ${record.layerCountTarget}`;
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Material #1</span>,
      className: "text-xs xl:text-sm empty:after:content-['Not_Available'] empty:text-muted",
      key: "material-1-name",
      dataIndex: ["materials", 0, "name"],
      width: "250px",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Material #1 - Estimated Consumption</span>,
      className: "text-xs xl:text-sm empty:after:content-['Not_Available'] empty:text-muted",
      key: "material-1-estimate",
      dataIndex: ["materials", "0", "estimatedAmount"],
      width: "250px",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Material #1 - Actual Consumption</span>,
      className: "text-xs xl:text-sm empty:after:content-['Not_Available'] empty:text-muted",
      key: "material-1-actual",
      dataIndex: ["materials", "0", "consumedAmount"],
      width: "250px",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Material #2</span>,
      className: "text-xs xl:text-sm empty:after:content-['Not_Available'] empty:text-muted",
      key: "material-2-name",
      dataIndex: ["materials", 1, "name"],
      width: "250px",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Material #2 - Estimated Consumption</span>,
      className: "text-xs xl:text-sm empty:after:content-['Not_Available'] empty:text-muted",
      key: "material-2-estimate",
      dataIndex: ["materials", 1, "estimatedAmount"],
      width: "250px",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Material #2 - Actual Consumption</span>,
      className: "text-xs xl:text-sm empty:after:content-['Not_Available'] empty:text-muted",
      key: "material-2-actual",
      dataIndex: ["materials", 1, "consumedAmount"],
      width: "fit-content",
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Metrics</span>,
      key: "metric",
      dataIndex: ["deviceId"],
      width: "fit-content",
      render: function getDate(_: string, { startDate, endDate = "" }: PrintJobType): ReactNode {
        return (
          <IconButton
            type={"primary"}
            onClick={(): void => {
              history.push({ pathname: `/devices/${deviceId}/metrics`, search: startDate && endDate ? `&from=${startDate}&to=${endDate}` : "" });
            }}
          >
            <i className="fas fa-chart-line"></i>
          </IconButton>
        );
      },
    },
    {
      title: <span className={"text-xs text-gray-500 whitespace-nowrap"}>Images</span>,
      key: "images",
      dataIndex: ["name"],
      width: "fit-content",
      render: function getPrintJobImages(_: string, o: PrintJobType): ReactNode {
        return (
          <IconButton
            type={"primary"}
            onClick={(): void => {
              setSelectedJob(o);
            }}
          >
            <i className="fas fa-chart-line"></i>
          </IconButton>
        );
      },
    },
  ];

  const getJobs: getJobsType = (id, page, size) => {
    getDeviceJobs({
      segments: {
        id,
      },
      params: {
        size,
        status: statuses,
        page: page - 1,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<PrintJobType>>) => {
        const { results = [], page } = data;
        setJobsContainer({ results, page });
        setJobs(valueOrDefault([], data.results));
      })
      .catch(() => {})
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!(pager && pageSize)) {
      setPage();
    }
    getJobs(deviceId, parseInt(pager), parseInt(pageSize));
  }, [pager, pageSize]);

  useAutoRefresh(
    () => {
      if (!(pager && pageSize)) {
        setPage();
      }
      getJobs(deviceId, parseInt(pager), parseInt(pageSize));
    },
    selectedJob === null,
    [deviceId, selectedJob]
  );

  const setPage = (page = 1, size = 15) => {
    if (jobsContainer && size != parseInt(jobsContainer.page.size)) {
      setPager("1");
    } else {
      setPager(page.toString());
    }
    setPageSize(size.toString());
  };

  return (
    <Collapse defaultActiveKey={["jobs"]} className={"shadow-xs"}>
      <Panel header={title} key="jobs">
        <>
          {loading ? (
            <div className={"relative min-h-[4rem] w-full"}>
              <Spinner loading={loading} loadingText={""} />
            </div>
          ) : (
            jobsContainer && (
              <div className={"max-h-[500px] h-full overflow-auto -mt-4"}>
                <Table
                  rowClassName={"whitespace-nowrap"}
                  rowKey={"id"}
                  className={"h-full hide-scrollbar"}
                  bordered={false}
                  columns={columns}
                  dataSource={jobs}
                  pagination={{
                    onChange: setPage,
                    size: "small",
                    defaultPageSize: 20,
                    hideOnSinglePage: false,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "30"],
                    pageSize: parseInt(jobsContainer.page.size),
                    current: parseInt(jobsContainer.page.number + 1),
                    total: parseInt(jobsContainer.page.size) * parseInt(jobsContainer.page.totalPages),
                  }}
                />
                {selectedJob && <JobModal job={selectedJob} onClose={(): void => setSelectedJob(null)} />}
              </div>
            )
          )}
        </>
      </Panel>
    </Collapse>
  );
};

type JobsListType = {
  size: number;
  deviceId: string;
  title?: string | ReactNode;
  statuses?: Array<JobStatusType>;
};
type getJobsType = (deviceId: string, page: number, size: number) => void;
