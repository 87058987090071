import React, { FC, ReactElement } from "react";
import { isNil } from "ramda";
import { displayDashAsDefault } from "../../../utils/general/helper";
import { Tooltip } from "antd";
import { getPopupContainer } from "../../../utils/general/common";
import {BulbFilled, CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

const getIconMapping: getIconMappingType = (key) => {
  switch (key) {
    case "GREEN":
      return (
      <span>
          <BulbFilled className={"text-success-500"} />
        </span>
      );
    case "YELLOW":
      return (
      <span>
          <BulbFilled className={"text-warning-500"} />
        </span>
      );
    case "RED":
      return (
          <span>
          <BulbFilled className={"text-danger-500"} />
        </span>
      );
    case "AVAILABLE":
      return <span className="text-success-500">{key}</span>;
    case "NOT AVAILABLE":
    case "CLOSED":
      return <span className="text-danger-500">{key}</span>;
    case "Idle":
      return <span className="text-success-500">{key}</span>;
    case "t":
      return (
          <span className="text-success-500">
          <CheckCircleOutlined />
        </span>
      );
    case "f":
      return (
          <span className="text-danger-500">
          <CloseCircleOutlined />
        </span>
      );
    default:
      return <span>{key}</span>;
  }
};

export const DeviceStatusInfo: FC<DeviceStatusInfoType> = function ({ label, value }: DeviceStatusInfoType) {
  return (
    <div className={"inline-block w-full sm:w-1/2 truncate overflow-ellipsis px-4 sm:px-2"}>
      <span className={"w-1/2 pr-2 font-light"}>{label}:</span>
      <span className={"w-1/2 font-medium truncate overflow-ellipsis"}>
        {isNil(value) ? (
          <span className={"text-muted"}>{displayDashAsDefault(value)}</span>
        ) : (
          <Tooltip getPopupContainer={getPopupContainer} placement="bottom" title={<span className={"text-xxs"}>{value}</span>}>
            {getIconMapping(value)}
          </Tooltip>
        )}
      </span>
    </div>
  );
};

type DeviceStatusInfoType = {
  label: string;
  value: string;
};

type getIconMappingType = (key: string) => ReactElement;
