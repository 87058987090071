import React, { FC, MouseEventHandler } from "react";
import { ParameterDataType } from "../../../utils/types/enums";

export const LatestValueCard: FC<LatestValueCardType> = function ({ label = "--", value = "--", unit = "--", selected = false, onClick }: LatestValueCardType) {
  return (
    <div onClick={onClick} className={`h-22 w-full rounded-sm border cursor-pointer p-2 hover:shadow-sm ${selected ? "bg-primary-50 border text-grey-800 border-primary-100 shadow-inner" : "bg-white border-primary-100 opacity-80 hover:border-primary-200 text-grey-800"}`}>
      <div className={"text-xxs text-left text-gray-500 truncate overflow-ellipsis"}>{label}</div>
      <div className={"text-base text-left text-gray-700 truncate overflow-ellipsis font-medium py-2"}>{value}</div>
      <div className={"text-xxs text-left text-gray-500 truncate overflow-ellipsis font-light"}>{unit}</div>
    </div>
  );
};

type LatestValueCardType = {
  label?: string;
  value: string;
  unit?: string;
  dataType?: ParameterDataType;
  selected?: boolean;
  onClick: MouseEventHandler;
};
