import React, { FC, ReactNode, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Affix, Layout, Menu } from "antd";
import { DashboardOutlined, GatewayOutlined, LogoutOutlined, LoadingOutlined } from "@ant-design/icons";
import { HOME_PAGE_URL } from "../../utils/routes/config";
import FullLogo from "../../images/logo/coam/coam-full-logo.png";
import ShortLogo from "../../images/logo/coam/coam-short-logo.png";
import { logout } from "../../services/services";
import { AuthenticationContext } from "../../context/AuthenticationContext";
import { NoParamVoidType, ResponseType } from "../../utils/types/uiTypes";
import { path, pluck } from "ramda";
import { BackgroundImage } from "../general/BackgroundImage";

const { Sider } = Layout;
const NAVIGATION_ITEMS: Array<MenuItemsType> = [
  {
    icon: <DashboardOutlined />,
    url: "/devices",
    name: "Devices",
  },
  {
    icon: <GatewayOutlined />,
    url: "/gateways",
    name: "Gateways",
  },
  /* {
    icon: <i className="fas fa-binoculars" />,
    url: "/monitors",
    name: "Monitors",
  },*/
 /*  {
    icon: <BookOutlined />,
    url: "http://3.236.215.176:9081/#/notebook/2GGGUNG2Y",
    name: "Python Notebook",
    newTab: true,
  },*/
];

export const SideBar: FC<SideBarType> = function ({ collapsed, onCollapse }: SideBarType) {
  const { authentication, setAuthentication } = useContext(AuthenticationContext);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  const onLogout: NoParamVoidType = () => {
    setLoading(true);
    logout({})
      .then(() => {
        setLoading(false);
        setAuthentication(null);
      })
      .catch(({ data }: ResponseType) => {
        console.log(data);
      });
  };
  return (
    <div className={"h-screen border-r-2 pr-px"}>
      <Sider theme={"light"}  className={"h-full bg-white select-none"} collapsible collapsed={collapsed} onCollapse={onCollapse} collapsedWidth={80} width={250}>
        <Affix>
          <div>
            <div className={"inline-flex h-16 w-full items-center justify-center p-4 shadow-bottom"}>
              <Link to={HOME_PAGE_URL} className={'h-full w-full'}>
                <BackgroundImage src={collapsed ? ShortLogo : FullLogo} size={"contain"} />
              </Link>
            </div>
            <div className={'w-full border-t'}>
            <Menu className={'border-r-0'} selectedKeys={[`/${path(["1"], location.pathname.split("/"))}`]} defaultOpenKeys={["sub1"]} mode="inline" theme="light">
              {NAVIGATION_ITEMS.map(({ name, url, icon, newTab }) => (
                <Menu.Item key={url} icon={icon} className={"select-none"}>
                  {newTab ? (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{
                        pathname: `${url}?tenantIds=${pluck("id")(authentication?.tenants ?? [])}`,
                      }}
                    >
                      {name}
                    </Link>
                  ) : (
                    <Link to={url} style={{ color: "inherit" }}>
                      {name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
              <Menu.Item key={"logout"} icon={<LogoutOutlined />} onClick={onLogout} className={"select-none"}>
                Logout {loading && <LoadingOutlined className={"ml-2"} />}
              </Menu.Item>
            </Menu>
          </div>
          </div>
        </Affix>
      </Sider>
    </div>
  );
};

type SideBarType = {
  collapsed: boolean;
  onCollapse: (o: any) => void;
};

type MenuItemsType = {
  icon: ReactNode;
  url: string;
  name: string;
  newTab?: boolean;
};
