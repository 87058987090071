import React, { FC } from "react";
import { EquipmentStatusType } from "../../utils/types/enums";

export const DEVICE_STATUSES = {
    DISCONNECTED: {color: '#F44336', text: 'Disconnected'},
    PARTIALLY_CONNECTED: {color: '#FFCB30', text: 'Partially Connected'},
    CONNECTED: {color: '#87C34A', text: 'Connected'},
}

export const DeviceStatusOption: FC<DeviceStatusOptionType> = function({status} :  DeviceStatusOptionType) {
    return (
        <span className={"flex flex-row gap-2 items-center"}>
          <i className="fas fa-circle text-danger mx-1" style={{color:DEVICE_STATUSES[status].color}}/>
            {DEVICE_STATUSES[status].text}
        </span>
    );
}

type DeviceStatusOptionType = {
    status: EquipmentStatusType
}