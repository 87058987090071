import React, { FC } from "react";
import { Spin } from "antd";

export const Spinner: FC<SpinnerType> = function ({ loading = true, loadingText = "Loading ..." }: SpinnerType) {
  return (
    <div className={`${loading ? "absolute visible bg-grey-50 bg-opacity-50 cursor-not-allowed" : "hidden invisible"} flex h-full w-full max-w-full items-center justify-center z-[1000]`}>
      <Spin spinning={loading} tip={loadingText} size={"large"} />
    </div>
  );
};

type SpinnerType = {
  loading?: boolean;
  loadingText?: string;
};
