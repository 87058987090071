import React, { FC } from "react";
import { useDrag } from "react-dnd";
import { ChartWidgetType, DashboardLayoutType } from "../../../../utils/types/enums";

export const WidgetMenuItem: FC<WidgetMenuItemType> = function ({ id, label, icon }: WidgetMenuItemType) {
  const [{ opacity }, drag] = useDrag({
    type: DashboardLayoutType.MENU_ITEM,
    item: {
      id,
      type: DashboardLayoutType.MENU_ITEM,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  return (
    <div ref={drag} style={{ opacity }}>
      <div className={"rounded-sm cursor-pointer hover:shadow-sm bg-white border hover:border-blue-300 opacity-80 text-gray-800 text-xs text-center w-32 p-2"}>
        {label}
        <div className={"pt-3 pb-1"}>
          <img className={"h-8 w-8 mx-auto"} src={icon} alt={""} />
        </div>
      </div>
    </div>
  );
};

type WidgetMenuItemType = {
  id: ChartWidgetType;
  label: string;
  icon: string;
};
