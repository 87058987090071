import React, { FC } from "react";
import { Link } from "react-router-dom";
import { DeviceInfo } from "./DeviceInfo";
import { PrintJobType } from "../../../utils/types/types";
import { Dropdown, Empty, Menu, Skeleton } from "antd";
import { ConnectionStatus } from "../../connections/ConnectionStatus";
import { DeviceStatusType, JobStatusType, ModelType } from "../../../utils/types/enums";
import { path } from "ramda";
import { getPopupContainer } from "../../../utils/general/common";
import { JobInfo } from "../../job/JobInfo";
import { DeviceStatusInfo } from "./DeviceStatusInfo";
import moment from "moment";
import { AlertBadge } from "../../alert/AlertBadge";

const DEFAULT_JOB: PrintJobType = {
  id: "",
  filename: "",
  name: "--",
  materials: [],
  layerCountCurrent: "--",
  layerCountTarget: "--",
  startDate: "--",
  endDate: "--",
  elapsedTime: "--",
  estimatedTime: "--",
  percentComplete: "--",
  status: JobStatusType.UNKNOWN,
  submissionDate: moment().valueOf(),
  position: 0,
};
export const DeviceGridContainer: FC<DeviceGridType> = function ({ id, className = "", loading, error, name, type, job = DEFAULT_JOB, widgets = [], alerts = 0, status = DeviceStatusType.UNKNOWN, activeConnections = 0, totalConnections = 0, image }: DeviceGridType) {
  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1" className={"text-xs hover:text-primary-500"}>
        <Link to={`/devices/${id}/overview`}>Device Overview</Link>
      </Menu.Item>
      <Menu.Item key="2" className={"text-xs hover:text-primary-500"}>
        <Link to={`/devices/${id}/metrics`}>Device Metrics</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={`relative flex flex-col gap-2 justify-between h-full max-w-sm bg-white border border-gray-200 mx-auto p-4 md:max-w-none ${className}`}>
      {!loading && <AlertBadge id={id} alerts={alerts} />}
      <div className={"flex justify-between"}>
        <ConnectionStatus loading={loading} status={status} active={activeConnections} total={totalConnections} />
        <Dropdown overlay={dropdownMenu} trigger={["click"]} placement={"bottomRight"} className={"my-auto cursor-pointer"} getPopupContainer={getPopupContainer}>
          <div>
            <i className="fas fa-ellipsis-v hover:text-primary-500 p-2"></i>
          </div>
        </Dropdown>
      </div>
      <div>
        <Link to={`/devices/${id}/metrics`}>
          <DeviceInfo id={id} name={name} image={image} />
        </Link>
        {loading ? (
          <div className={"h-10 border border-gray-200 rounded-b inline-block w-full sm:text-center pt-2 sm:py-2 px-4"}>
            <Skeleton.Input className={"w-full max-w-xxs h-3 sm:h-5 rounded"} active={true} />
            <Skeleton.Input className={"w-full max-w-xxs sm:hidden h-3 rounded"} active={true} />
          </div>
        ) : (
          !error && (
            <div className={"h-10 flex flex-row items-center justify-center rounded-b bg-grey-50 border border-gray-200 text-xxs sm:text-center bg-white rounded-b-sm sm:divide-x divide-primary-700"}>
              {widgets.slice(0, 2).map(({ label = "--", metrics = [] }, i) => {
                const value: any = path([0, "results", 0, "stringValue"], metrics);
                return (
                  <React.Fragment key={i}>
                    <DeviceStatusInfo label={label} value={value} />
                  </React.Fragment>
                );
              })}
            </div>
          )
        )}
      </div>
      <div>
        {loading ? (
          <Skeleton className={"flex items-center justify-center h-40 rounded border border-gray-200 p-4"} active={true} paragraph={{ rows: 3 }} />
        ) : !error ? (
          <JobInfo name={job.name} type={type} showRemainingTime={job.status === JobStatusType.IN_PROGRESS} materials={job.materials} currentLayer={job.layerCountCurrent} targetLayer={job.layerCountTarget} percentage={job.percentComplete} estimatedTime={job.estimatedTime} elapsedTime={job.elapsedTime} />
        ) : (
          <div className={"flex items-center justify-center h-40"}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </div>
  );
};

type DeviceGridType = {
  id: string;
  className?: string;
  loading: boolean;
  error?: boolean;
  name: string;
  type?: ModelType;
  status?: DeviceStatusType;
  activeConnections?: number;
  totalConnections?: number;
  image: string;
  job?: PrintJobType;
  widgets?: Array<WidgetType>;
  alerts?: number;
};

type WidgetType = {
  label: string;
  metrics: Array<MetricType>;
};

type MetricType = {
  results: Array<ValueType>;
};

type ValueType = {
  stringValue: string;
};
