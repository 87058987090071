import React, { FC } from "react";
import { useDrop } from "react-dnd";
import { DashboardLayoutType } from "../../../../utils/types/enums";

export const DropZone: FC<DropZoneType> = ({ className, text = "", accept, onDrop }: DropZoneType) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    canDrop: () => {
      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <div ref={drop} className={`inline-flex items-center justify-center text-gray-600 ${className} ${isOver && canDrop ? "p-5 h-full bg-blue-200" : "bg-white px-3 py-0.5"}`}>
      <div>{text}</div>
    </div>
  );
};

type DropZoneType = {
  className?: any;
  text?: string;
  accept: Array<DashboardLayoutType>;
  onDrop: any;
};
