import { useEffect } from "react";
import { concat } from "ramda";
import { events } from "../utils/events";

export const useRequestUnauth: usePageTitleType = (fn) => {
  useEffect(() => {
    events.on("REQUEST-UNAUTHORIZED", fn);
    console.log("dswe");
    return () => {
      events.off("REQUEST-UNAUTHORIZED", fn);
    };
  }, []);
};

type usePageTitleType = (fn: (o: any) => void) => void;
