import React, { FC } from "react";
import { MetricType, ThresholdType } from "../../utils/types/types";
import { getAlertRuleDescription, MONITORING_RULE_RESULT_STATUS_STYLE } from "../../utils/alerts/alert";
import { MonitoringRuleConditionType, MonitoringRuleResultType } from "../../utils/types/enums";

export const AlertRuleCard: FC<AlertRuleCardType> = function ({ disabled = false, selected = false, condition, primaryMetric, secondaryMetric, threshold, status = false, onSelect = (): void => {}, onDelete = (): void => {} }: AlertRuleCardType) {
  return (
    <div>
      <button className={`${!disabled && "w-full"}`} disabled={disabled} onClick={onSelect}>
        <div className={`transition-colors ease-linear duration-75 flex flex-row w-full rounded border cursor-pointer px-2 py-1 hover:shadow-xs tracking-[.015rem] ${selected ? "bg-blue-100 border text-blue-500 border-blue-300 shadow-inner" : "bg-grey-50 border-blue-200 opacity-80 hover:border-blue-200 text-gray-800"}`}>
          {getAlertRuleDescription(primaryMetric, secondaryMetric, threshold, condition)}
          {status && <span className={`ml-2 text-xs font-bold ${MONITORING_RULE_RESULT_STATUS_STYLE[status]}`}>{status}</span>}
        </div>
      </button>
    </div>
  );
};

type AlertRuleCardType = {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  condition: MonitoringRuleConditionType;
  primaryMetric: MetricType;
  secondaryMetric?: MetricType;
  threshold?: ThresholdType;
  status?: MonitoringRuleResultType | false;
  onSelect?: () => void;
  onDelete?: () => void;
};
