import React, { ReactNode } from "react";

export const getUnits: getUnitsType = (unit: any = "") => {
  switch (unit) {
    case "MILLIMETER":
      return <span>mm</span>;
    case "CUBIC_MILLIMETER":
      return (
        <span>
          mm<sup>3</sup>
        </span>
      );
    case "CUBIC_MM":
      return (
        <span>
          mm<sup>3</sup>
        </span>
      );
    case "PERCENT":
      return <span>%</span>;
    case "CELSIUS":
      return <span>&#8451;</span>;
    case "HERTZ":
      return <span>Hz</span>;
    default:
      return <span>{unit.replace("_", " ")}</span>;
  }
};

type getUnitsType = (unit: any) => ReactNode;
