import React, { FC, useContext, useState } from "react";
import { Drawer, message, Space } from "antd";
import { DeviceDashboardContext } from "../../context/DeviceDashboardContext";
import { useParams } from "react-router";
import { FiltersType, MonitoringRuleSetDetailsType, PaginationContainer } from "../../utils/types/types";
import { insertItemIntoArray, valueOrDefault } from "../../utils/general/helper";
import { getDeviceMonitors } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { AddMonitorForm } from "./AddMonitorForm";
import { MonitorHistory } from "./MonitorHistory";
import { PageLayout } from "../layout/PageLayout";
import { FloatingButton } from "../general/FloatingButton";
import { MonitorListTable } from "./MonitorsListTable";
import { SearchBar } from "../general/SearchBar";
import { SearchFiltersBar } from "../general/SearchFiltersBar";
import * as R from "ramda";
import { AuthenticationContext } from "../../context/AuthenticationContext";
import { useAutoRefresh } from "../../custom-hooks/useAutoRefresh";

export const MonitorList: FC = function () {
  const { authentication } = useContext(AuthenticationContext);
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const { deviceId }: ParamsType = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [drawerType, setDrawerType] = useState<DrawerType | null>(null);
  const [selectedMonitor, setSelectedMonitor] = useState<string | null>(null);
  const [monitors, setMonitors] = useState<MonitorType[]>([]);
  const [filterList, setFilterList] = useState<FiltersType[]>([
    { key: "status", label: "Status", path: ["currentStatus", "status"], options: [{ value: "OK" }, { value: "ALERT" }, { value: "NONE", label: "NONE" }] },
    {
      key: "enabled",
      label: "Enabled",
      path: ["enabled"],
      options: [
        { value: "true", label: "ENABLED" },
        { value: "false", label: "DISABLED" },
      ],
    },
  ]);
  const [query, setQuery] = useState("");
  const [filtersEnabled, setFiltersEnabled] = useState<Array<{ key: string; value: string; path: string[] }>>([]);

  const onConfirmFilter = (filters: Array<{ key: string; value: string; path: string[] }>): void => {
    setFiltersEnabled(filters);
  };

  const filterMonitors = (): Array<MonitorType> => {
    const groupedFilters = R.groupBy(R.prop("key"))(filtersEnabled);
    return monitors.filter((monitor) =>
      filtersEnabled.length > 0
        ? filtersEnabled.filter((filter) => {
            return groupedFilters[filter.key].map((item) => String(item.value)).includes(String(R.path(filter.path, monitor) ?? "NONE"));
          }).length === filtersEnabled.length
        : true
    );
  };

  const getMonitors: getMonitorsType = (deviceId) => {
    getDeviceMonitors({
      segments: {
        deviceId,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<MonitoringRuleSetDetailsType>>) => {
        setMonitors(valueOrDefault([], data.results) as Array<MonitorType>);
        setLoading(false);
      })
      .catch(() => {
        message.error("Unable to load device monitors");
        setLoading(false);
      })
  };

  const getDrawerContent = (): React.ReactNode => {
    if (!deviceDashboard) return;
    switch (drawerType) {
      case "ADD_MONITOR":
        return (
          <AddMonitorForm
            deviceId={deviceId}
            users={authentication?.teamMembers}
            parameters={deviceDashboard.parameters}
            onSuccess={(value): void => {
              setMonitors(insertItemIntoArray(monitors.length, monitors, value));
            }}
            onClose={(): void => {
              setDrawerType(null);
            }}
          />
        );
      case "VIEW_HISTORY":
        return (
          selectedMonitor && (
            <Drawer
              destroyOnClose={true}
              placement={"right"}
              width={"1200"}
              closable={true}
              onClose={(): void => {
                setDrawerType(null);
              }}
              maskClosable={false}
              footer={<div className={"w-full flex justify-end"} />}
              title={"Monitor History"}
              visible={true}
            >
              <MonitorHistory deviceId={deviceId} monitorId={selectedMonitor} innerClassname={"border pb-2"} />
            </Drawer>
          )
        );
    }
  };

  const searchMonitors = (): Array<MonitorType> => {
    return filterMonitors()?.filter((param) =>
      query
        ? Object.values(param)
            .map((o) => o?.toString().toLowerCase())
            .find((i) => i.includes(query))
        : true
    );
  };
  useAutoRefresh(
    () => {
      getMonitors(deviceId);
    },
    drawerType === null && selectedMonitor === null,
    [deviceId, drawerType, selectedMonitor]
  );

  return (
    <PageLayout loading={loading} loadingText={"Loading Monitors"} height={"full"}>
      <>
        <div className={"mb-4"}>
          <Space className={`flex justify-between`}>
            <div className={"flex justify-start gap-2"}>
              <SearchBar size={"SMALL"} placeholder={"Search Monitors"} onSearch={setQuery} />
            </div>
            <SearchFiltersBar filtersList={filterList} onSubmit={onConfirmFilter} />
          </Space>
        </div>
        <MonitorListTable isDashboard={false} monitors={searchMonitors()} setMonitors={setMonitors} />
        <FloatingButton icon={<i className="fas fa-plus" />} label={"Add Monitor"} onClick={(): void => setDrawerType("ADD_MONITOR")} />
        {drawerType && getDrawerContent()}
      </>
    </PageLayout>
  );
};

type ParamsType = {
  deviceId: string;
};
type MonitorType = MonitoringRuleSetDetailsType & LoadingType;
type LoadingType = { loading: boolean };
type getMonitorsType = (deviceId: string) => void;
type DrawerType = "ADD_MONITOR" | "VIEW_HISTORY";
