import React, { FC, ReactElement } from "react";

export const CustomTag: FC<CustomTagType> = function ({ children }: CustomTagType) {
  return React.cloneElement(children, {
    className: `inline-block text-xs overflow-hidden shrink w-fit truncate px-2 py-1 rounded-sm bg-primary-50 text-primary-500 border border-primary-200 ${children.props.className}`,
  });
};

type CustomTagType = {
  children: ReactElement;
};
