import React, { FC, useContext, useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { useHistory } from "react-router";
import { login } from "../services/services";
import { AuthenticationContext } from "../context/AuthenticationContext";
import { ResponseType } from "../utils/types/uiTypes";
import { HOME_PAGE_URL } from "../utils/routes/config";
import { AuthenticationType } from "../utils/types/types";
import { usePageTitle } from "../custom-hooks/usePageTitle";
import { MaterialiseFooter } from "../components/layout/MaterialiseFooter";
import Hero from  "../images/hero-image.png";
import Logo from "../images/logo/coam/coam-full-logo.png"
import { BackgroundImage } from "../components/general/BackgroundImage";
import { Layout } from "../components/general/Layout";

export const Login: FC = function () {
  usePageTitle();
  const { setAuthentication } = useContext(AuthenticationContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSuccess: onSuccessType = (data) => {
    setAuthentication(data);
    history.push(HOME_PAGE_URL);
  };
  const onFailure: onFailureType = () => {
    setError(true);
    setLoading(false);
  };
  const authenticate: authenticateType = (email, password) => {
    login({
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(({ data }: ResponseType<AuthenticationType>) => {
        onSuccess(data);
      })
      .catch(() => {
        onFailure();
      });
  };

  return (
  <div className={'bg-white h-screen w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5'}>
    <div className={'xl:col-span-2 relative flex flex-col items-center justify-center w-full'}>
      <div className={'flex flex-grow w-full items-center'}>
        <Layout size={'xs'}>
          <div className={'h-8 sm:h-9 lg:h-10 w-full mb-10'}>
            <div className={'h-8 sm:h-10 lg:h-11 w-full'}>
              <BackgroundImage src={Logo} size={'contain'} position={'left'} border={false}></BackgroundImage>
            </div>
          </div>
          <div className={'w-full'}>
            <div className={'pb-8'}>
              <span className={'text-xl font-semibold'}>Login</span>
            </div>
            <Form
              form={form}
              onFinish={(): void => {
                setError(false);
                setLoading(true);
                authenticate(form.getFieldValue("email"), form.getFieldValue("password"));
              }}
              layout={"vertical"}
              requiredMark={false}
            >
              <Form.Item label={'Username or Email'} name="email" rules={[{ required: true, type: "email", message: "Please enter email address" }]} validateTrigger={[]}>
                <Input placeholder="example@mail.com" />
              </Form.Item>
              <Form.Item label={'Password'} className={"mt-2 placeholder:text-grey-600 placeholder:italic"} name="password" rules={[{ required: true, message: "Please input your password!" }]} validateTrigger={[]}>
                <Input.Password placeholder="Enter Password" />
              </Form.Item>
              {error && <Alert message="Invalid Credentials" closable={true} type="error" />}
              <div className={'mt-6 flex flex-row justify-between items-center'}>
                <Form.Item className={"text-center"}>
                  <Button
                    loading={loading}
                    className={"bg-primary-500 border-primary-500 motion-safe:hover:bg-opacity-90 motion-safe:hover:border-opacity-90 text-white"}
                    htmlType={"submit"}
                  >
                    LOG IN
                  </Button>
                </Form.Item>
                <Form.Item className={"text-center"}>
                  <Button type={'link'} href={'/oauth2/authorization/link3d-okta'}>
                    LOGIN WITH SSO
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Layout>
      </div>
      <MaterialiseFooter />
    </div>
    <div className={'hidden lg:block xl:col-span-3'}>
      <BackgroundImage src={Hero} size={'cover'} border={false}></BackgroundImage>
    </div>
  </div>
  );
};

type onSuccessType = (data: any) => void;
type onFailureType = () => void;
type authenticateType = (email: string, password: string) => void;
