import React, { FC, ReactElement } from "react";
const getIconMapping: getIconMappingType = (tag, value) => {
  if (tag.toLowerCase().includes("layer")) {
    return (
      <span>
        {value}
        <i className="px-2 fas fa-layer-group"></i>
      </span>
    );
  } else if (tag.toLowerCase().includes("time")) {
    return (
      <span>
        {value}
        <i className="px-2 fas fa-clock"></i>
      </span>
    );
  } else if (tag.toLowerCase().includes("lock")) {
    return (
        <span>
        {value}
          <i className="px-2 fas fa-lock"></i>
      </span>
    )} else {
    return <>{value}</>;
  }
};

export const MetricTagIconMap: FC<MetricLabelIconMapType> = function ({ tag, value }: MetricLabelIconMapType) {
  return getIconMapping(tag, value);
};

type MetricLabelIconMapType = {
  tag: string;
  value: string;
};
type getIconMappingType = (tag: string, value: string) => ReactElement;
