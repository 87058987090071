import React, { FC, ReactNode } from "react";
import { MENU_ITEMS } from "../../../../utils/dashboardCustomization";

import { WidgetMenuItem } from "./WidgetMenuItem";

export const WidgetMenuBar: FC<WidgetMenuBarType> = function ({ children }: WidgetMenuBarType) {
  return (
    <div className={"max-h-full"}>
      <div className={"bg-white border p-2 mb-2"}>
        <div className={"grid grid-rows gap-2 overflow-x-auto"}>
          {MENU_ITEMS.map(({ id, label, icon }) => (
            <div key={id}>
              <WidgetMenuItem id={id} label={label} icon={icon} />
            </div>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

type WidgetMenuBarType = {
  children?: ReactNode;
};
