import React, { FC, useEffect } from "react";
import Countdown from "antd/es/statistic/Countdown";
import { useHistory } from "react-router";
import { HOME_PAGE_URL } from "../utils/routes/config";
import { Result } from "antd";
import { usePageTitle } from "../custom-hooks/usePageTitle";

export const InternalPageNotFound: FC = function () {
  usePageTitle("Not Found");
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(HOME_PAGE_URL);
    }, 5000);
    return (): void => clearTimeout(timer);
  }, []);

  return (
    <div className={"relative flex flex-col w-full h-screen max-h-screen"}>
      <div className={"flex h-full w-full max-w-full items-center justify-center"}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <div className={"text-center text-gray-500 mt-2"}>
              <span className={"text-xs"}>Redirecting to the dashboard in </span>
              <div className={"inline-block"}>
                <Countdown valueStyle={{ fontSize: "0.75rem", fontWeight: "bold", color: "#d02525" }} value={Date.now() + 6000} format="s" />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
