import { DeviceStatusType } from "./types/enums";

export const STATUS_STYLE = {
  CONNECTED: "text-white bg-success-500 border-success-500",
  PARTIALLY_CONNECTED: "text-white bg-warning-600 border-warning-600",
  DISCONNECTED: "text-white bg-danger-500 border-danger-500",
  UNKNOWN: "text-white bg-gray-300 border-gray-300",
};

export const getConnectionStatus: getConnectionStatusType = (status = DeviceStatusType.UNKNOWN, active = 0, total = 0) => {
  return status === DeviceStatusType.PARTIALLY_CONNECTED ? `${active} / ${total} ${DeviceStatusType.CONNECTED}` : `${status?.replace("_", " ")}`;
};

type getConnectionStatusType = (status?: DeviceStatusType, active?: number, total?: number) => string;
