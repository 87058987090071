/** Device **/
export enum DeviceStatusType {
  CONNECTED = "CONNECTED",
  PARTIALLY_CONNECTED = "PARTIALLY_CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  UNKNOWN = "UNKNOWN",
}

export enum EquipmentStatusType {
  CONNECTED = "CONNECTED",
  PARTIALLY_CONNECTED = "PARTIALLY_CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

/** Parameters **/
export enum ParameterDataType {
  LONG = "LONG",
  DOUBLE = "DOUBLE",
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  UNDEFINED = "UNDEFINED",
}

/** Metrics and Monitors **/
export enum MonitoringRuleConditionType {
  EQUAL = "EQUAL",
  GREATER = "GREATER",
  GREATER_EQUAL = "GREATER_EQUAL",
  LOWER = "LOWER",
  LOWER_EQUAL = "LOWER_EQUAL",
}

export enum AggregationType {
  AVG = "AVG",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
  COUNT = "COUNT",
  COUNTD = "COUNTD",
  LATEST = "LATEST",
}

export enum MonitoringRuleSetConditionType {
  ALL = "ALL",
  ANY = "ANY",
}

export enum MonitoringRuleSetStatusType {
  OK = "OK",
  ALERT = "ALERT",
}

export enum PrinterStatusType {
  PRINTER_STATE = "PRINTER_STATE",
  PRINTER_STATE2 = "PRINTER_STATE2",
}

export enum PrinterStatuses {
  IDLE = "IDLE",
  BUSY = "BUSY",
  WAITING = "WAITING",
  UNKNOWN = "UNKNOWN",
}

export enum MonitoringRuleResultType {
  PASS = "PASS",
  FAIL = "FAIL",
  INCONCLUSIVE = "INCONCLUSIVE",
}

/** Dashboard Configuration **/
export enum DashboardLayoutType {
  SECTION = "SECTION",
  ROW = "ROW",
  WIDGET = "WIDGET",
  MENU_ITEM = "MENU_ITEM",
}

/** Widgets and Charts **/
export enum ChartWidgetType {
  LINE_PLOT = "LINE_PLOT",
  AREA_PLOT = "AREA_PLOT",
  SCATTER_PLOT = "SCATTER_PLOT",
  TABLE = "TABLE",
  SINGLE_VALUE = "SINGLE_VALUE",
}

export enum HighchartsType {
  line = "line",
  area = "area",
  scatter = "scatter",
}

/** Form **/
export enum RequestParameterType {
  TEXT = "TEXT",
  SELECT_SINGLE = "SELECT_SINGLE",
  SELECT_MULTIPLE = "SELECT_MULTIPLE",
  CREDENTIAL = "CREDENTIAL",
  CREDENTIAL_TEXT = "CREDENTIAL_TEXT",
  FILE = "FILE",
}

/** Connections **/
export enum ConnectionStatusType {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

/** Agent Status **/
export enum MachineConnectivityAgentStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
}

/** Job Status **/
export enum JobStatusType {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  QUEUED = "QUEUED",
  PAUSED = "PAUSED",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED",
  ABORTED = "ABORTED",
  WAITING = "WAITING",
  UNKNOWN = "UNKNOWN",
}

export enum JobMaterialType {
  PRIMARY = "PRIMARY",
  SUPPORT = "SUPPORT",
  PRINT_SHEET = "PRINT_SHEET",
  FUSING_AGENT = "FUSING_AGENT",
  DETAILING_AGENT = "DETAILING_AGENT",
}

export enum ModelType {
  PRINTER = "PRINTER",
  POST_PROCESSING_MACHINE = "POST_PROCESSING_MACHINE",
  SENSOR = "SENSOR",
}

/** ProfileItemType **/
export enum ProfileItemType {
  DEVICE_UI = "DEVICE_UI",
  DEVICE_AGENT = "DEVICE_AGENT",
  DEVICE_MONITOR = "DEVICE_MONITOR",
  DEVICE_DATA_CONVERTER = "DEVICE_DATA_CONVERTER",
  DEVICE_PARAMETER = "DEVICE_PARAMETER",
}

export enum LogTypeType {
  Info = "Info",
  Error = "Error",
  Warning = "Warning",
  "Fatal Error" = "Fatal Error",
}
