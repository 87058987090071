import { DeviceParameterType, MonitoringRuleType } from "./types/types";
import { compose, isEmpty, isNil, join, toLower, toUpper, zipObj } from "ramda";
import { AggregationType, ChartWidgetType, ParameterDataType } from "./types/enums";
import { valueOrDefault } from "./general/helper";

export const AGGREGATIONS = [
  {
    key: AggregationType.AVG,
    value: "Average",
  },
  {
    key: AggregationType.MAX,
    value: "Maximum",
  },
  {
    key: AggregationType.MIN,
    value: "Minimum",
  },
  {
    key: AggregationType.SUM,
    value: "Sum",
  },
  {
    key: AggregationType.COUNT,
    value: "Count",
  },
  {
    key: AggregationType.COUNTD,
    value: "Count Distinct",
  },
  {
    key: AggregationType.LATEST,
    value: "Latest Value",
  },
];
export const TIME_PERIODS = [
  { key: "300", value: "5 Minutes" },
  { key: "900", value: "15 Minutes" },
  { key: "3600", value: "1 Hour" },
  { key: "21600", value: "6 Hours" },
  { key: "86400", value: "1 Day" },
];

const removeT2 = (aggregation: string) => (aggregation.charAt(0) === "T" || aggregation.charAt(0) === "t" ? `${aggregation.slice(1)}` : `${aggregation}`);
const appendT = (aggregation: string, parameter: string, isTimeSeries: boolean) => {
  const v = aggregation.charAt(0) === "T" || aggregation.charAt(0) === "t" ? aggregation.substring(1) : aggregation;
  return v === AggregationType.LATEST ? `${v}:${parameter}-limit:${isTimeSeries ? '500' : '1'}` : `T${v}:${parameter}-limit:${isTimeSeries ? '500' : '1'}`;
}
const removeT = (aggregation: string, parameter: string) => (aggregation.charAt(0) === "T" || aggregation.charAt(0) === "t" ? `${aggregation.slice(1)}:${parameter}` : `${aggregation}:${parameter}`);
export const mapExpressionToMetric: mapExpressionToMetricType = (item = "") => {
  const vals = item.split(":");
  const agg = valueOrDefault(undefined, compose(toUpper, removeT2)(vals[0]));
  const param = valueOrDefault(undefined, vals[1]?.substring(0, vals[1].concat("-")?.indexOf('-')))
  return zipObj(["aggregation", "parameter"], [agg, param]) as unknown as MetricType;
};
export const convertMetricsToExpressions2: convertMetricsToExpressionsType2 = (metrics = [], isTimeSeries) => {
  return metrics
    .filter(({ aggregation = "", parameter = "" }) => !isEmpty(aggregation) && !isEmpty(parameter))
    .map(({ aggregation, parameter }) => {
      return isTimeSeries ? compose(toLower, appendT)(aggregation, parameter, isTimeSeries) : compose(toLower, removeT)(aggregation, parameter);
    });
};

export const convertMetricsToExpressions: convertMetricsToExpressionsType = (metrics = [], widgetType) => {
  console.log(metrics);
  return metrics
    .map(({ aggregation = "", parameter }) => {
      if (isNil(aggregation) || isNil(parameter)) {
        return "";
      } else if (widgetType == ChartWidgetType.SINGLE_VALUE) {
        return join(":", [aggregation, parameter]);
      } else {
        return aggregation.charAt(0) != "T" && aggregation.charAt(0) != "t" ? join(":", [(parameter?.dataType === ParameterDataType.STRING ? "" : "T") + aggregation, parameter.tag]) : join(":", [aggregation, parameter.tag]);
      }
    })
    .filter((o) => !isEmpty(o));
};

export const convertRuleToExpressions: convertRuleToExpressionsType = (rule) => {
  if (isNil(rule)) return [];
  const { primaryMetric, secondaryMetric } = rule;
  const metrics = [primaryMetric, secondaryMetric].filter((o) => !isNil(o)) as Array<MetricType>;
  return convertMetricsToExpressions(metrics, ChartWidgetType.LINE_PLOT);
};

export const convertExpressionsToMetrics: convertExpressionsToMetricsType = (expressions) => expressions.map((expression) => mapExpressionToMetric(expression));

type MetricType = {
  parameter: DeviceParameterType;
  aggregation: AggregationType;
};

type MetricType2 = {
  parameter: string;
  aggregation: string;
};

type mapExpressionToMetricType = (expression: string) => MetricType;
type convertMetricsToExpressionsType = (metrics: Array<MetricType>, widgetType: ChartWidgetType) => Array<string>;
type convertMetricsToExpressionsType2 = (metrics: Array<MetricType2>, isTimeSeries: boolean) => Array<string>;

type convertRuleToExpressionsType = (rule: MonitoringRuleType | null) => Array<string>;
type convertExpressionsToMetricsType = (expressions: Array<string>) => Array<MetricType>;
