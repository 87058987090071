import React, { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { HOME_PAGE_URL } from "../utils/routes/config";

export const RedirectToMain: FC = function () {
  const history = useHistory();

  useEffect(() => {
    history.push(HOME_PAGE_URL);
  }, []);

  return <></>;
};
