import React, { FC, useContext, useState } from "react";
import { Widget } from "./Widget";
import { Modal } from "antd";
import { DeviceDashboardContext } from "../../../context/DeviceDashboardContext";
import { ChartWidgetType } from "../../../utils/types/enums";

export const Section: FC<SectionType> = function ({ rows = [] }: SectionType) {
  const { deviceDashboard } = useContext(DeviceDashboardContext);
  const [modal, setModal] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState<WidgetType | null>(null);

  const widgetModal = (
    <Modal
      destroyOnClose={true}
      title={selectedWidget?.label}
      visible={modal}
      closable={true}
      onCancel={(): void => {
        setModal(false);
        setSelectedWidget(null);
      }}
      cancelText={"Close"}
      width={"65%"}
      centered={true}
      footer={null}
    >
      {selectedWidget && <div>{deviceDashboard && <Widget deviceId={deviceDashboard.deviceId} from={deviceDashboard.fromDate} to={deviceDashboard.toDate} expressions={selectedWidget.expressions} type={selectedWidget.type} height={500} allowExport={true} stock={true} />}</div>}
    </Modal>
  );
  return (
    <div className={"flex flex-col gap-3"}>
      {rows.map((row, i) => (
        <div key={i} className={`grid grid-cols-${row.widgets.length} gap-3`}>
          {row.widgets.map(({ label = "", type, expressions = [] }, j) => (
            <div key={j} className={"relative group"}>
              <div className={`z-50 absolute top-9 right-4 opacity-0 ${type !== "SINGLE_VALUE" && "group-hover:opacity-100"}`}>
                <i
                  role={"button"}
                  onClick={(): void => {
                    console.log(deviceDashboard);
                    setSelectedWidget({ label, type, expressions });
                    setModal(true);
                  }}
                  className="fas fa-expand-arrows-alt text-gray-600"
                />
              </div>
              {deviceDashboard && <Widget outerClassName={"border p-6"} deviceId={deviceDashboard.deviceId} from={deviceDashboard.fromDate} to={deviceDashboard.toDate} expressions={expressions} type={type} title={label} height={340} allowExport={true} />}
            </div>
          ))}
        </div>
      ))}
      {widgetModal}
    </div>
  );
};

type WidgetType = {
  label: string;
  type: ChartWidgetType;
  expressions: Array<string>;
};

type RowType = {
  widgets: Array<WidgetType>;
};

type SectionType = {
  rows: Array<RowType>;
};
