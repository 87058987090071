import React, { FC, useEffect, useState } from "react";
import { MachineConnectivityAgentStatus } from "../../../utils/types/enums";
import { Empty, message } from "antd";
import { ConnectionFormModal } from "../../connections/ConnectionFormModal";
import { getObjectFromPropertyValue, updateItemInArray, valueOrDefault } from "../../../utils/general/helper";
import { find, findIndex, isEmpty, propEq } from "ramda";
import { ConnectionType, DeviceManufacturerType, GatewayInstanceType, MachineConnectivityAgentType, PaginationContainer } from "../../../utils/types/types";
import { ConnectionCard } from "../../connections/ConnectionCard";
import { getDeviceConnections, getGateways, updateAgent } from "../../../services/services";
import { NoParamVoidType, ResponseType } from "../../../utils/types/uiTypes";
import { Spinner } from "../../general/Spinner";

export const DeviceConnections: FC<DeviceConnectionsType> = function ({ cardSize = 'default', deviceId, manufacturer }: DeviceConnectionsType) {
    const [loading, setLoading] = useState(true);
    const [selectedConnection, setSelectedConnection] = useState<ConnectionType | null>(null);
    const [gateways, setGateways] = useState<GatewayInstanceType[]>([]);
    const [connections, setConnections] = useState<ConnectionType[]>([]);

    const onClear: NoParamVoidType = () => {
        setSelectedConnection(null);
    };

    const onUpdateSuccess: onUpdateSuccessType = (connection, agent) => {
        const updatedConnections = updateItemInArray(findIndex(propEq("id", connection?.id))(connections), connections, { ...connection, agent });
        setConnections(updatedConnections);
        message.success("Connection was updated successfully");
        onClear();
    };

    const update: updateType = (connection, status, gatewayId, requestParameters) => {
        updateAgent({
            segments: {
                deviceId,
                id: connection?.agent?.id,
            },
            body: JSON.stringify({
                id: connection?.id,
                equipmentId: deviceId,
                connectivityProviderType: connection?.agent?.connectivityProviderType,
                gatewayId: gatewayId,
                status: status,
                requestParameters: requestParameters,
            }),
        })
            .then(({ data }: ResponseType<MachineConnectivityAgentType>) => {
                onUpdateSuccess(connection, data);
            })
            .catch(() => {
                message.error("Unable to update connection");
            });
    };

    const getConnections: getDeviceOverviewPromiseType = (id) => {
        return new Promise((resolve, reject) => {
            getDeviceConnections({
                segments: {
                    id,
                },
            })
                .then(({ data }: ResponseType<PaginationContainer<ConnectionType>>) => {
                    setConnections(valueOrDefault([], data.results));
                    resolve();
                })
                .catch((error) => {
                    console.log(error);
                    reject({
                        error: true,
                        message: error ? error : "Unable to fetch device connections!",
                    });
                });
        });
    };

    const fetchGateways: getGatewaysPromiseType = () => {
        return new Promise((resolve, reject) => {
            getGateways({})
                .then(({ data }: ResponseType<PaginationContainer<GatewayInstanceType>>) => {
                    setGateways(valueOrDefault([], data.results));
                    resolve();
                })
                .catch((error: any) => {
                    console.log(error);
                    reject({
                        error: true,
                        message: error ? error : "Unable to fetch gateways!",
                    });
                });
        });
    };

    const getDeviceConnectionsData: getDeviceConnectionsDataType = (id) => {
        setLoading(true);
        Promise.all([getConnections(id), fetchGateways()])
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getDeviceConnectionsData(deviceId);
    }, [deviceId]);

    return (
        <div className={"relative min-h-[15rem]"}>
            {loading ? (
                <Spinner loading={loading} loadingText={"Loading Connections"} />
            ) : (
                <>
                    {isEmpty(connections) ? (
                        <Empty description={"No Connections"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    ) : (
                        <>
                            <div className={"flex flex-row flex-wrap gap-3 justify-center lg:justify-start"}>
                                {connections.map(({ id, agent, agentId, createdAt, updatedAt, initializedAt, status }, index) => {
                                    const gateway = find(propEq("id", agent?.gatewayId))(gateways) as GatewayInstanceType | null;
                                    return (
                                        <ConnectionCard size={cardSize}
                                            key={index}
                                            id={id}
                                            connectivityProvider={agent?.connectivityProviderType}
                                            image={manufacturer?.imageUrl}
                                            gateway={gateway}
                                            name={agent?.name}
                                            connectionStatus={status}
                                            agentStatus={agent?.status}
                                            updatedAt={updatedAt}
                                            onSwitch={(v): void => {
                                                update(
                                                    {
                                                        id,
                                                        agent,
                                                        agentId,
                                                        createdAt,
                                                        updatedAt,
                                                        initializedAt,
                                                        status,
                                                    },
                                                    v ? MachineConnectivityAgentStatus.ACTIVE : MachineConnectivityAgentStatus.PAUSED,
                                                    agent.gatewayId,
                                                    agent.requestParameters
                                                );
                                            }}
                                            onEdit={(): void => {
                                                setSelectedConnection({
                                                    id,
                                                    agent,
                                                    agentId,
                                                    createdAt,
                                                    updatedAt,
                                                    initializedAt,
                                                    status,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {selectedConnection && (
                        <ConnectionFormModal
                            connection={selectedConnection}
                            currentGateway={getObjectFromPropertyValue("id", selectedConnection?.agent?.gatewayId, gateways)}
                            visible={selectedConnection !== null}
                            connectivityProviderType={selectedConnection?.agent?.connectivityProviderType}
                            manufacturer={manufacturer?.name}
                            connectionId={selectedConnection.id}
                            deviceId={deviceId}
                            agent={selectedConnection?.agent}
                            gateways={gateways}
                            onSave={(connection) => {
                                const updatedConnections = updateItemInArray(findIndex(propEq("id", connection?.id))(connections), connections, connection);
                                setConnections(updatedConnections);
                                setSelectedConnection(null);
                            }}
                            onCancel={() => {
                                setSelectedConnection(null);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

type DeviceConnectionsType = {
    cardSize?: 'small' | 'default'
    deviceId: string;
    manufacturer?: DeviceManufacturerType;
};
type getDeviceConnectionsDataType = (deviceId: string) => void;
type getDeviceOverviewPromiseType = (id: string) => Promise<void>;
type getGatewaysPromiseType = () => Promise<void>;
type onUpdateSuccessType = (connection: ConnectionType, agent: MachineConnectivityAgentType) => void;
type updateType = (connection: ConnectionType, status: MachineConnectivityAgentStatus, gatewayId: string, requestParameters: any) => void;