import React, { FC, ReactNode, useEffect, useState } from "react";
import {Button, Form, Input, message, Modal, Select, Space, Spin, Tooltip} from "antd";
import {find, pick, pluck, propEq} from "ramda";
import { ConnectionType, ConnectivityProviderDetailsType, GatewayInstanceType, MachineConnectivityAgentType, PaginationContainer } from "../../utils/types/types";
import { filterSortWithTitle, valueOrDefault } from "../../utils/general/helper";
import {ConnectionStatusType, MachineConnectivityAgentStatus} from "../../utils/types/enums";
import { getDeviceConnection, getDeviceIntegrations, updateAgent } from "../../services/services";
import { ResponseType } from "../../utils/types/uiTypes";
import { PRIMARY_BUTTON_STYLE } from "../../utils/general/styles";
import { LoadingOutlined } from "@ant-design/icons";
import { useInterval } from "../../custom-hooks/useInterval";
import { ConnectionStatusPopover } from "../general/ConnectionStatusPopover";
import moment from "moment";
import { getHumanizedElapsedTime, getTimeFromNowByUnit } from "../../utils/general/dates";
import {ConnectionFormField} from "./ConnectionFormField";

const { Option } = Select;

const STATUS_ICON: Record<TestConnectionStatusType, ReactNode> = {
  loading: <LoadingOutlined />,
  success: <i className="ml-2 fa fa-check-circle text-success-500" />,
  timeout: <i className="ml-2 fa fa-clock text-warning-600" />,
  error: <i className="ml-2 fa fa-exclamation-circle text-danger-500" />,
};

export const ConnectionFormModal: FC<ConnectionFormModalType> = function ({ visible = false, deviceId, connectionId, agent, manufacturer, connection, connectivityProviderType, currentGateway, gateways, onSave, onCancel }: ConnectionFormModalType) {
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [testConnectionStatus, setTestConnectionStatus] = useState<TestConnectionStatusType | false>(false);
  const [connectivityProviderLoading, setConnectivityProviderLoading] = useState(false);
  const [integration, setIntegration] = useState<ConnectivityProviderDetailsType | null>(null);
  const [selectedGateway, setSelectedGateway] = useState<GatewayInstanceType | null>(null);

  const timeoutMessage = () => {
    setTimeout(() => {
      setTestConnectionStatus(false);
    }, 20000);
  };

  const timeoutTerminate = () => {
    setTimeout(() => {
      terminate(timer);
      setTestConnectionStatus("timeout");
      timeoutMessage();
    }, 120000);
  };

  //no need to return timer
  const [timer, terminate, execute] = useInterval(
    (): void => {
      getDeviceConnection({
        segments: {
          id: deviceId,
          connectionId: connection.id,
        },
      }).then(({ data }: ResponseType<ConnectionType>) => {
        if (data.status === ConnectionStatusType.CONNECTED) {
          terminate(timer);
          setTestConnectionStatus("success");
          timeoutMessage();
        }
      });
    },
    5000,
    true,
    [],
    false
  );

  const onUpdateSuccess: onUpdateSuccessType = (test, agent) => {
    if (test) {
      execute();
      timeoutTerminate();
    } else {
      message.success("Connection updated successfully");
      setActionLoading(false);
      onSave({ ...connection, agent });
    }
  };

  const update: updateType = (test, id, agentId, connectivityProviderType, status, gatewayId, requestParameters, save = false) => {
    updateAgent({
      segments: {
        deviceId,
        id: agentId,
      },
      body: JSON.stringify({
        id,
        equipmentId: deviceId,
        connectivityProviderType,
        gatewayId: gatewayId,
        status: status,
        requestParameters: requestParameters,
      }),
    })
      .then(({ data }: ResponseType<MachineConnectivityAgentType>) => {
        onUpdateSuccess(test, data);
      })
      .catch((e) => {
        console.log(e);
        message.error(`Unable to ${test ? "test" : "update"} connection`);
      });
  };

  const fetchConnectivityProviders: fetchConnectivityProvidersType = (gatewayVersion = null) => {
    getDeviceIntegrations({
      segments: {
        deviceId,
      },
      params: {
        version: gatewayVersion ?? "2.6.0",
        name: connectivityProviderType,
      },
    })
      .then(({ data }: ResponseType<PaginationContainer<ConnectivityProviderDetailsType>>) => {
        setIntegration(valueOrDefault(null, data.results[0]));
      })
      .catch((error: any) => {
        console.log(error);
      })
      .then(() => {
        setConnectivityProviderLoading(false);
      });
  };

  useEffect(() => {
    if (selectedGateway) {
      setConnectivityProviderLoading(true);
      fetchConnectivityProviders(selectedGateway?.version);
    }
  }, [selectedGateway]);

  useEffect(() => {
    currentGateway && setSelectedGateway(currentGateway);
    form.setFieldsValue({ ...connection.agent.requestParameters, gatewayId: connection.agent.gatewayId, status: connection.agent.status });
  }, [currentGateway]);

  return (
    <Modal
      bodyStyle={{ paddingBottom: 0 }}
      title={"Edit Connection"}
      width={600}
      visible={visible}
      closable={true}
      onCancel={onCancel}
      destroyOnClose={true}
      footer={
        <div className={"flex justify-between"}>
          <ConnectionStatusPopover visible={testConnectionStatus === "timeout" || testConnectionStatus === "success"} label={testConnectionStatus}>
            <Button
              type={"link"}
              onClick={(): void => {
                form.validateFields().then(() => {
                  terminate(timer);
                  const values = form.getFieldsValue();
                  setTestConnectionStatus("loading");
                  update(true, connectionId, agent.id, agent.connectivityProviderType, MachineConnectivityAgentStatus.ACTIVE, values?.gatewayId, pick(pluck('name', integration?.connectionParameters ?? []), values));
                });
              }}
            >
              {testConnectionStatus === "loading" ? "Testing" : "Test Connection"}
              {testConnectionStatus && STATUS_ICON[testConnectionStatus]}
            </Button>
          </ConnectionStatusPopover>
          <Space>
            <Button key={"back"} type={"default"} onClick={onCancel}>
              Cancel
            </Button>
            <Button
              key={"submit"}
              type={"primary"}
              className={PRIMARY_BUTTON_STYLE}
              loading={actionLoading}
              onClick={(): void => {
                form.submit();
              }}
            >
              Save
            </Button>
          </Space>
        </div>
      }
    >
      <div className={"h-full overflow-y-auto hide-scrollbar max-h-[70vh]"}>
        <Form
          scrollToFirstError
          layout="vertical"
          form={form}
          name="edit-connection"
          onFinish={(values): void => {
            setActionLoading(true);
            update(false, connectionId, agent.id, agent.connectivityProviderType, agent.status, values.gatewayId, pick(pluck('name', integration?.connectionParameters ?? []), values), true);
          }}
        >
          <>
            <Form.Item name="gatewayId" label={<span className={"text-xs text-gray-400"}>Gateway</span>} rules={[{ required: true }]}>
              <Select
                placeholder="Search"
                onChange={(value): void => {
                  setConnectivityProviderLoading(true);
                  const gateway: GatewayInstanceType = find(propEq("id", value))(gateways) as GatewayInstanceType;
                  fetchConnectivityProviders(gateway?.version);
                  if (testConnectionStatus === "loading") {
                    terminate(timer);
                    setTestConnectionStatus(false);
                  }
                }}
                showSearch
                optionFilterProp={"title"}
                filterSort={filterSortWithTitle}
              >
                {gateways.map(({ id, name, lastSyncedAt }) => (
                  <Option key={id} value={id} title={name}>
                    <div className={"flex flex-col items-start p-0 m-0 w-full"}>
                      <span className={"flex flex-row items-center w-full"}>
                        {name}
                        <span className={"ml-auto font-extralight italic text-xs"}>
                          {lastSyncedAt && (
                            <Tooltip title={moment(lastSyncedAt).format("lll").valueOf()}>
                              <span className={`${getTimeFromNowByUnit(lastSyncedAt, "minutes") <= 5 ? "text-success-500" : "text-danger-500"}`}>{getHumanizedElapsedTime(lastSyncedAt)}</span>
                            </Tooltip>
                          )}
                        </span>
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {connectivityProviderLoading ? (
              <div className={"inline-flex w-full items-center justify-center pb-[24px]"}>
                <Spin />
              </div>
            ) : (
              integration?.connectionParameters?.map(({ type , name, required, values = [] }, i) => <ConnectionFormField key={name} type={type} required={required} name={name} values={values} />))}
          </>
        </Form>
      </div>
    </Modal>
  );
};

type ConnectionFormModalType = {
  visible?: boolean;
  connectionId: string;
  connection: ConnectionType;
  deviceId: string;
  agent: MachineConnectivityAgentType;
  manufacturer?: string;
  connectivityProviderType?: string;
  currentGateway?: GatewayInstanceType;
  gateways: Array<GatewayInstanceType>;
  onSave: (o: ConnectionType) => void;
  onCancel: () => void;
};

type TestConnectionStatusType = "success" | "error" | "timeout" | "loading";
type fetchConnectivityProvidersType = (gatewayVersion: string | null) => void;
type updateType = (testConnection: boolean, connectionId: string, agentId: string, connectivityProviderType: string, status: MachineConnectivityAgentStatus, gatewayId: string, requestParameters: any, save?: boolean) => void;
type onUpdateSuccessType = (testConnection: boolean, agent: MachineConnectivityAgentType) => void;
