import { Modal } from "antd";
import React, { ReactNode } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { NoParamVoidType } from "./types/uiTypes";

export const ConfirmDeleteModal: confirmDeleteModalType = (title, onOk, content, onCancel) => {
  Modal.confirm({
    title,
    onOk,
    onCancel,
    centered: true,
    content: <span>{content}</span>,
    icon: <QuestionCircleFilled className={"text-danger-500"} />,
    okText: "Delete",
    okButtonProps: {
      className: "bg-danger-500 border-danger-500 motion-safe:hover:bg-danger-600 motion-safe:hover:border-danger-600 text-white",
    },
  });
};

type confirmDeleteModalType = (title: ReactNode, onOk: NoParamVoidType, content?: ReactNode, onCancel?: NoParamVoidType) => void;
