import React, { FC, ReactElement, useState } from "react";
import { Checkbox, Select, Tag } from "antd";
import { FiltersType } from "../../utils/types/types";
import { DownOutlined, CloseOutlined } from "@ant-design/icons";

export const FilterInput: FC<SearchFiltersBarType> = function ({ onChange, filter }) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const addRemoveFilter = (option: string): void => {
    setSelectedOptions((list) => (list.includes(option) ? list.filter((item) => item !== option) : [...list, option]));
    onChange(filter.key, option, filter.path);
  };
  const clearFilter = (): void => {
    setSelectedOptions([]);
    onChange(filter.key, "", filter.path);
  };
  const customTag = (label: string): ReactElement => {
    return (
      <div className={"mx-2"}>
        {label}:
        <Tag.CheckableTag className={"bg-primary-500 ml-2"} checked>
          {selectedOptions.length}
        </Tag.CheckableTag>
      </div>
    );
  };
  return (
    <>
      <div className={"min-w-[200px] flex"}>
        <div className={`${selectedOptions.length > 0 && "border-primary-500 text-primary-500"} inline-flex flex-1 items-center group bg-white border hover:border-primary-500 hover:text-primary-500 text-black`}>
          <Select
            allowClear
            onClear={clearFilter}
            showSearch={false}
            value={selectedOptions.length > 0 ? ["Tag"] : []}
            tagRender={(): ReactElement => customTag(filter.label)}
            className={"w-full"}
            suffixIcon={<DownOutlined className={`${selectedOptions.length > 0 ? "text-primary-500" : "text-black"} float-right group-hover:text-primary-500`} />}
            mode="multiple"
            showArrow={true}
            bordered={false}
            placeholder={filter.label}
          >
            {filter.options.map((item) => (
              <Select.Option key={item.value} className={"cursor-pointer"} value={item.value} disabled={true}>
                <Checkbox onChange={(): void => addRemoveFilter(item.value)} checked={selectedOptions.includes(item.value)} className={"w-full"}>
                  {item.label || item.value}
                </Checkbox>
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
};

type SearchFiltersBarType = {
  onChange: (key: string, value: string, path: Array<string>) => void;
  filter: FiltersType;
};
