import { useEffect } from "react";
import { concat } from "ramda";

export const usePageTitle: usePageTitleType = (title = false) => {
  useEffect(() => {
    document.title = concat(title ? `${title} - ` : "", "CO-AM");
  }, []);
};

type usePageTitleType = (title?: string | false) => void;
