import { curry, defaultTo, find, insert, mergeAll, nth, path, pickBy, propEq, remove } from "ramda";

/** Display Defaults **/
export const displayDashAsDefault = defaultTo("--");

/** Parse Float Values **/
export const getFloatValue: getFloatValueType = (value, decimals) => {
  const toFixedValue = parseFloat(value).toFixed(decimals);
  return isNaN(Number(toFixedValue)) ? null : toFixedValue;
};

/** Remove Null Values from Object **/
export const removeNilFromObject: removeNilFromObjectType = (object) =>
  pickBy((value) => {
    return !(value === undefined || value === null);
  }, object);

/** Mutate Lists **/
export const insertItemIntoArray: insertOrUpdateItemType = (i, a, v) => [...a.slice(0, i), v, ...a.slice(i)];
export const updateItemInArray: insertOrUpdateItemType = (i, a, v) => [...a.slice(0, i), v, ...a.slice(i + 1)];
export const removeItemFromArray: removeItemFromArrayType = (i, arr) => [...arr.slice(0, i), ...arr.slice(i + 1)];
export const moveItemInArray = curry((at: number, to: number, list: Array<any>) => insert(to, nth(at, list), remove(at, 1, list)));

/** Fallback for null values **/
export const valueOrDefault: valueOrDefaultType = (d, v) => (v ? v : d);

/** Sort and filter **/
export const filterSort: filterType = (a, b) => {
  return a?.children?.toLowerCase()?.localeCompare(b?.children?.toLowerCase());
};
export const filterSortWithTitle: filterType = (a, b) => {
  return a?.title?.toLowerCase()?.localeCompare(b?.title?.toLowerCase());
};

export const arrayToMapConversion: arrayToMapConversionType = (data = [], property, pathToObject = [], useIndexAsKey = false) => {
  return mergeAll(
    data.map(function (o, i) {
      const key: string | number = useIndexAsKey ? i : o[property];
      return {
        [key]: pathToObject ? path(pathToObject, o) : o,
      };
    })
  );
};

export const getObjectFromPropertyValue: getObjectFromPropertyValueType = (property, value, array) => {
  return find(propEq(property, value))(array);
};

export const populateArrayWithPropertyPath: arrayToArrayConversionType = (propertyPath, data) => {
  return data ? data.map((o) => path(propertyPath, o)) : [];
};

/** Types **/
type getFloatValueType = (value: any, decimals: number) => string | null;
type removeNilFromObjectType = (object: any) => any;
type insertOrUpdateItemType = (index: number, array: Array<any>, value: any) => Array<any>;
type removeItemFromArrayType = (index: number, array: Array<any>) => Array<any>;
type valueOrDefaultType = (defaultValue: any, value: any) => any;
type filterType = (a: any, b: any) => any;
type arrayToMapConversionType = (data: Array<any>, property: string, pathToObject?: Array<string>, useIndexAsKey?: boolean) => any;
type getObjectFromPropertyValueType = (property: string, value: any, array: Array<any>) => any;
type arrayToArrayConversionType = (propertyPath: string[], data: any[] | undefined) => Array<any>;
