import { DashboardRowType, DashboardSectionType, DashboardWidgetType } from "./types/types";
import AreaChart from "../images/icons/area-chart.svg";
import LineChart from "../images/icons/line-chart.svg";
import ScatterPlot from "../images/icons/scatter-plot.svg";
import SingleValue from "../images/icons/single-value.svg";
import { uniqueId } from "./general/uniqueId";
import { ChartWidgetType, DashboardLayoutType } from "./types/enums";

export const MENU_ITEMS: Array<MenuType> = [
  {
    id: ChartWidgetType.LINE_PLOT,
    type: DashboardLayoutType.MENU_ITEM,
    label: "Line Plot",
    icon: LineChart,
  },
  {
    id: ChartWidgetType.AREA_PLOT,
    type: DashboardLayoutType.MENU_ITEM,
    label: "Area Plot",
    icon: AreaChart,
  },
  {
    id: ChartWidgetType.SCATTER_PLOT,
    type: DashboardLayoutType.MENU_ITEM,
    label: "Scatter Plot",
    icon: ScatterPlot,
  },
  /*{
    id: WIDGET_TYPES.TABLE,
    type: LAYOUT_TYPES.MENU_ITEM,
    label: "Table",
    icon: Table,
  },*/
  {
    id: ChartWidgetType.SINGLE_VALUE,
    type: DashboardLayoutType.MENU_ITEM,
    label: "Single Value",
    icon: SingleValue,
  },
];

export const createNewRow: createNewRowType = (widgets = []) => ({
  id: uniqueId(),
  widgets,
});
export const creatNewSection: creatNewSectionType = () => {
  return {
    id: uniqueId(),
    name: "New Section",
    rows: [],
  };
};

export type DraggableType = {
  id: string;
  type: DashboardLayoutType;
  data?: any;
};

type MenuType = {
  id: ChartWidgetType;
  type: DashboardLayoutType;
  label: string;
  icon: string;
};
type createNewRowType = (widgets: DashboardWidgetType[]) => DashboardRowType;
type creatNewSectionType = () => DashboardSectionType;
