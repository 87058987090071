import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { addProfileItem, getAllProfileItems, updateProfileItem } from "../../services/services";
import { ProfileItemsType } from "../../utils/types/types";
import { Form, Input, message, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table/Table";
import { IconButton } from "../general/IconButton";
import { useParams } from "react-router";
import { ResponseType } from "../../utils/types/uiTypes";
import { ProfileItemType } from "../../utils/types/enums";
import { PRIMARY_BUTTON_STYLE, TABLE_PAGINATION_STYLE } from "../../utils/general/styles";
import { updateItemInArray } from "../../utils/general/helper";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { FloatingButton } from "../general/FloatingButton";
import { formItemRequiredRule } from "../../utils/forms";
import { path } from "ramda";

const ITEM_TYPES_LIST = [
  { name: "DEVICE_UI", value: ProfileItemType.DEVICE_UI },
  { name: "DEVICE_AGENT", value: ProfileItemType.DEVICE_AGENT },
  { name: "DEVICE_MONITOR", value: ProfileItemType.DEVICE_MONITOR },
  { name: "DEVICE_DATA_CONVERTER", value: ProfileItemType.DEVICE_DATA_CONVERTER },
  { name: "DEVICE_PARAMETER", value: ProfileItemType.DEVICE_PARAMETER }
];

export const ProfileDetails: FC = () => {
  const { profileId }: ParamsType = useParams();
  const configRef = useRef<HTMLDivElement>(null);
  const [form] = useForm();

  const [profilesItems, setProfiles] = useState<ProfileItemsType[]>([]);
  const [loading, setLoading] = useState(true);
  const [itemModal, setItemModal] = useState<ItemModalType | null>(null);

  const getProfileItem = (index: number): ProfileItemsType => {
    console.log(path([index], profilesItems));
    return path([index], profilesItems) as ProfileItemsType;
  };

  const isJsonValid = (str: string): boolean => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const fetchProfiles = (): void => {
    setLoading(true);
    getAllProfileItems({
      segments: {
        profileId,
      },
    })
      .then(({ data }: ResponseType<{ results: ProfileItemsType[] }>) => {
        setProfiles(data.results);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const saveProfileItem =
    (itemIndex?: number) =>
    (item: ProfileItemsType): void => {
      console.log("Here");
      Modal.warning({
        title: "Confirm Update",
        content: (
          <>
            The update will affect the database. <br /> Proceed with Caution!
          </>
        ),
        onOk: () => {
          updateProfileItem({
            segments: {
              itemId: item.id,
              profileId: item.profileId,
            },
            body: JSON.stringify(item),
          })
            .then(() => {
              setProfiles(updateItemInArray(itemIndex ?? profilesItems.length, profilesItems, item));
              setItemModal(null);
            })
            .catch((error: string) => {
              message.error(error ?? "Error updating Profile Item");
            });
        },
      });
    };

  const submitNewProfileItem: submitNewProfileItemType = (item) => {
    addProfileItem({ segments: { profileId }, body: JSON.stringify({ ...item, profileId }) })
      .then(({ data }: ResponseType<ProfileItemsType>) => {
        setProfiles([...profilesItems, data]);
        form.resetFields();
        setItemModal(null);
      })
      .catch(() => {
        message.error("Error Creating new profile item");
      });
  };

  const onModalOk = (index: number): void => {
    const updatedConfig = configRef?.current?.innerHTML.toString();
    if (isJsonValid(updatedConfig ?? "")) {
      saveProfileItem(index)({ ...getProfileItem(index), config: JSON.parse(updatedConfig ?? "{}") });
    } else {
      message.error("Invalid JSON Input");
    }
  };

  const columns: ColumnsType<ProfileItemsType> = [
    { title: "Id", dataIndex: ["id"] },
    { title: "Name", dataIndex: ["name"] },
    {
      title: "Type",
      dataIndex: ["type"],
      render: (type: ProfileItemType): ReactNode => <span>{type?.replaceAll("_", " ")}</span>,
    },
    { title: "Description", dataIndex: ["description"], className: "min-w-[200px]" },
    {
      title: "Actions",
      dataIndex: ["id"],
      render: (id: string, profileItem, index): ReactNode => (
        <div className={"flex flex-row items-center gap-2"}>
          <IconButton
            type={"primary"}
            icon={"edit"}
            onClick={(): void => {
              setItemModal({ index, type: MODAL_TYPE.EDIT });
              form.setFieldsValue(profileItem);
            }}
          />
          <IconButton
            type={"primary"}
            icon={"gear"}
            onClick={(): void => {
              setItemModal({ index, type: MODAL_TYPE.CONFIG });
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchProfiles();
  }, []);

  return (
    <>
      <div className={"border shadow-xs max-h-full overflow-y-auto hide-scrollbar bg-white"}>
        <Table loading={loading} dataSource={profilesItems} columns={columns} rowKey={"id"} scroll={{ x: 1200 }} pagination={TABLE_PAGINATION_STYLE} />
      </div>
      <FloatingButton icon={<i className="fas fa-plus" />} label={"Add Profile"} onClick={(): void => setItemModal({ type: MODAL_TYPE.NEW })} />
      <Modal
        maskClosable={false}
        okButtonProps={{ className: PRIMARY_BUTTON_STYLE }}
        okText={"Update Config"}
        width={800}
        visible={itemModal?.type === MODAL_TYPE.CONFIG && itemModal.index !== undefined}
        title={"Update Config"}
        onOk={(): void => {
          if (itemModal?.index !== undefined) {
            onModalOk(itemModal.index);
          } else {
            console.log("error", itemModal?.index);
          }
        }}
        destroyOnClose={true}
        onCancel={(): void => {
          setItemModal(null);
        }}
      >
        <pre className={"bg-gray-900 text-white p-2 rounded-sm"}>
          <code ref={configRef} className={"focus:ring-0 focus:outline-none focus:border-none border-none"} contentEditable={true} dangerouslySetInnerHTML={{ __html: JSON.stringify(itemModal?.index !== undefined ? getProfileItem(itemModal?.index).config : {}, null, 2) }} />
        </pre>
      </Modal>

      <Modal
        title={itemModal?.type === MODAL_TYPE.NEW ? "Add Profile Item" : "Edit Profile Item"}
        visible={itemModal?.type === MODAL_TYPE.NEW || itemModal?.type === MODAL_TYPE.EDIT}
        onOk={(): void => {
          form.submit();
        }}
        okButtonProps={{ className: PRIMARY_BUTTON_STYLE }}
        onCancel={(): void => {
          setItemModal(null);
          form.resetFields();
        }}
        okText={itemModal?.type === MODAL_TYPE.NEW ? "Submit" : "Edit"}
      >
        <Form
          form={form}
          onFinish={(val): void => {
            if (itemModal?.type === MODAL_TYPE.NEW) submitNewProfileItem(val);
            else if (itemModal?.index !== undefined) {
              saveProfileItem(itemModal?.index)({ ...getProfileItem(itemModal?.index), ...val });
            }
          }}
          layout={"vertical"}
        >
          <Form.Item label={"Profile Item Name"} name={"name"} rules={[formItemRequiredRule]}>
            <Input placeholder={"Profile Item Name"} type={"text"} />
          </Form.Item>
          <Form.Item label={"Profile Item Description"} name={"description"} rules={[formItemRequiredRule]}>
            <TextArea placeholder={"Profile Item Description"} />
          </Form.Item>
          <Form.Item label={"Profile Item Type"} name={"type"} rules={[formItemRequiredRule]}>
            <Select placeholder={"Device Type"} disabled={itemModal?.type === MODAL_TYPE.EDIT}>
              {ITEM_TYPES_LIST.map(({ name, value }) => (
                <Select.Option key={value} value={value}>
                  {name?.replace("_", " ")}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type ParamsType = {
  profileId: string;
};

type submitNewProfileItemType = (item: NewProfileItemType) => void;

type NewProfileItemType = {
  name: string;
  description: string;
  type: ProfileItemType;
};

enum MODAL_TYPE {
  EDIT = "EDIT",
  NEW = "NEW",
  CONFIG = "CONFIG",
}

type ItemModalType = { index?: number; type: MODAL_TYPE };
