import { useEffect, DependencyList } from "react";

export const useConfirmReload: saveBeforeReloadOrCloseType = (
  condition,
  dependencies,
  callback // Callback still in testing
) => {
  useEffect(() => {
    if (condition) {
      callback ? callback() : (window.onbeforeunload = (): boolean => true);
    } else {
      window.onbeforeunload = null;
    }
    return (): void => {
      window.onbeforeunload = null;
    };
  }, dependencies);
};

type saveBeforeReloadOrCloseType = (
  condition: boolean,
  dependencies: DependencyList,
  callback?: () => void
) => void;
