import React from "react";
import { DateOptionType } from "../types/uiTypes";
import moment from "moment";

export const DATE_OPTIONS: DateOptionType[] = [
  {
    key: "PAST_HOUR",
    abbreviation: "1h",
    label: <span className={"text-xs"}>Past Hour</span>,
    amount: 3600,
  },
  {
    key: "PAST_DAY",
    abbreviation: "1d",
    label: <span className={"text-xs"}>Past Day</span>,
    amount: 86400,
  },
  {
    key: "PAST_WEEK",
    abbreviation: "1w",
    label: <span className={"text-xs"}>Past Week</span>,
    amount: 604800,
  },
  {
    key: "PAST_MONTH",
    abbreviation: "1mo",
    label: <span className={"text-xs"}>Past Month</span>,
    amount: 2592000,
  },
];

export const getAbbreviatedHumanizedElapsedTime: getAbbreviatedHumanizedElapsedTimeType = (v) => moment.duration(v).humanize().replace("hours", "h").replace("minutes", "m").replace("months", "mo").replace("years", "y").toString();
export const getHumanizedElapsedTime: getHumanizedElapsedTimeType = (date) => {
  if (isNaN(Number(date))) return;
  return moment(Number(date)).fromNow();
};

export const getTimeFromNowByUnit: getTimeFromNowByUnitType = (date, unit) => {
  return moment(Date.now()).diff(moment(date), unit);
};

type getTimeFrameType = (startDate: string, endDate: string) => string;
type getAbbreviatedHumanizedElapsedTimeType = (value: number) => string;
type getHumanizedElapsedTimeType = (date: string | number) => string | undefined;
type getTimeFromNowByUnitType = (date: string | number, unit: "minutes" | "hours" | "days" | "weeks" | "months") => number;
