import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button } from "antd";

export const ConnectionStatusPopover: FC<ConnectionStatusPopoverType> = ({ children, visible = false, label, timeout = 8000 }: ConnectionStatusPopoverType) => {
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    setShowStatus(visible);
    visible &&
      setTimeout(() => {
        setShowStatus(false);
      }, timeout);
  }, [visible]);

  return (
    <div className={"relative inline-block group "}>
      <div className={`absolute ${!showStatus ? "invisible" : "visible"} rounded bg-gray-800 p-2 -top-10 z-50 text-white min-w-[100px] max-w-[200px]`}>
        <Button className={"hover:shadow-inner rounded-full flex items-center text-xs absolute border-0 bg-transparent right-0 top-0 text-white"} size={"small"} onClick={(): void => setShowStatus(false)}>
          &#10005;
        </Button>
        <p className={"flex w-full"}>{label}</p>
      </div>
      {children}
    </div>
  );
};

type ConnectionStatusPopoverType = {
  children: ReactNode;
  visible?: boolean;
  label: ReactNode;
  timeout?: number;
};
