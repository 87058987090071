import React, { FC, useEffect, useState } from "react";
import { Button, Collapse, Drawer, Form, Input, InputNumber, message, Select, Space, Switch, Tag } from "antd";
import { RuleConfigurationForm } from "./RuleConfigurationForm";
import { DeviceParameterType, MonitoringRuleSetDetailsType, UserType } from "../../utils/types/types";
import { isNil, map, pick } from "ramda";
import { addMonitor, updateMonitor } from "../../services/services";
import { COMPARISON_TYPES, PRINTER_SETTING_OPTIONS } from "../../utils/alerts/alert";
import { ResponseType } from "../../utils/types/uiTypes";
import { updateItemInArray } from "../../utils/general/helper";
import { CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const { Panel } = Collapse;

export const AddMonitorForm: FC<MonitorFormType> = function ({ deviceId, users, monitor, parameters, onSuccess, onClose }: MonitorFormType) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<MonitoringRuleSetDetailsType | null>(null);

  useEffect(() => {
    monitor && setFormValues({ ...monitor });
    const allValues = form.getFieldsValue();
  }, [monitor]);

  const addition: additionType = (deviceId, values) => {
    setLoading(true);
    addMonitor({
      segments: {
        deviceId,
      },
      body: JSON.stringify({ ...values, enabled: true }),
    })
      .then(({ data }: ResponseType<MonitoringRuleSetDetailsType>) => {
        message.success("Monitor was added successfully");
        onSuccess(data);
        setLoading(false);
        onClose();
      })
      .catch(({ data }: ResponseType<string>) => {
        message.error(data);
        setLoading(false);
      });
  };

  const update: updateType = (deviceId, monitorId, enabled, values: MonitoringRuleSetDetailsType) => {
    setLoading(true);
    monitor &&
      updateMonitor({
        segments: {
          deviceId,
          id: monitorId,
        },
        body: JSON.stringify({ ...values, enabled }),
      })
        .then(({ data }: ResponseType<MonitoringRuleSetDetailsType>) => {
          onSuccess(data);
          message.success("Monitor was updated successfully!");
          setLoading(false);
          onClose();
        })
        .catch(() => {
          message.error("Unable to update monitor");
          setLoading(false);
        });
  };

  const onTabSwitch: onTabSwitchType = (i, key) => {
    const allValues = form.getFieldsValue();
    const { ruleSet = {} } = allValues;
    const { rules = [] } = ruleSet;
    const value = pick(["condition", "primaryMetric", "timeRange", key], rules[i]);
    form.setFieldsValue({ ...allValues, ruleSet: { ...ruleSet, rules: updateItemInArray(i, rules, value) } });
    setFormValues({ ...allValues, ruleSet: { ...ruleSet, rules: updateItemInArray(i, rules, value) } });
  };

  return (
    <>
      <Drawer
        destroyOnClose={true}
        placement="right"
        width={"1200"}
        closable={true}
        onClose={onClose}
        maskClosable={false}
        footer={
          <div className={"w-full flex justify-end"}>
            <Space className={"gap-2"}>
              <Button
                loading={loading}
                onClick={(): void => {
                  form.submit();
                }}
                type={"primary"}
                className={"bg-primary-500 hover:opacity-80 w-24"}
              >
                Save
              </Button>
              <Button onClick={onClose} type={"default"} className={"w-24"}>
                Cancel
              </Button>
            </Space>
          </div>
        }
        title={isNil(monitor) ? "Add Monitor" : "Edit Monitor"}
        visible={true}
      >
        <Form
          layout={"vertical"}
          onValuesChange={(): void => {
            setFormValues(form.getFieldsValue());
          }}
          colon={false}
          form={form}
          name="add-monitor"
          requiredMark={false}
          onFinish={(values): void => {
            const data = { ...values, waitTime: values?.waitTime * 60000, recipients: values?.recipients != undefined ? values.recipients.map((val: string) => JSON.parse(val)) : monitor?.recipients };
            isNil(monitor) ? addition(deviceId, data) : update(deviceId, monitor.id, monitor.enabled, data);
          }}
          initialValues={
            isNil(monitor)
              ? { enabled: true }
              : { name: monitor.name, description: monitor.description, ruleSet: monitor.ruleSet, enabled: monitor.enabled, settings: monitor.settings, notificationsEnabled: monitor.notificationsEnabled, waitTime: +monitor.waitTime / 60000, recipients: monitor.recipients ? map(JSON.stringify)(monitor.recipients) : undefined }
          }
        >
          <Collapse defaultActiveKey={[1, 2, 3, 4]} ghost>
            <Panel header={<span className={"font-semibold"}>1. Describe Your Monitor</span>} key={1}>
              <div className={"flex flex-col gap-4"}>
                <Space className={"gap-2"}>
                  <Form.Item name="name" label={<span className={"text-xs text-gray-400"}>Name</span>} rules={[{ required: true, message: "Name is required" }]} className={"ml-6 mb-0"}>
                    <Input placeholder={"Enter monitor name"} className={"w-96"} />
                  </Form.Item>
                </Space>
                <Form.Item name="description" label={<span className={"text-xs text-gray-400"}>Description</span>} className={"ml-6 mb-0"}>
                  <TextArea placeholder={"Enter description"} />
                </Form.Item>
              </div>
            </Panel>
            <Panel header={<span className={"font-semibold"}>2. Specify Monitoring Conditions</span>} key={2}>
              <div className={"ml-6"}>
                <div className={"text-sm text-gray-80 inline-block"}>
                  <div className={"inline-block mt-1"}>When </div>
                  <Form.Item className={"inline-block mx-3"} name={["ruleSet", "condition"]} rules={[{ required: true, message: "Condition is required" }]}>
                    <Select className={"w-32"}>
                      {COMPARISON_TYPES.map(({ key, label }) => (
                        <Option key={key} value={key}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className={"inline-block mt-1"}>of the following conditions are met:</div>
                </div>
                <div>
                  <RuleConfigurationForm form={form} deviceId={deviceId} parameters={parameters} onTabSwitch={onTabSwitch} formValues={formValues} setFormValues={setFormValues} />
                </div>
              </div>
            </Panel>
            <Panel header={<span className={"font-semibold"}>3. Specify Advanced Settings</span>} key={3}>
              <div className={"ml-6"}>
                {PRINTER_SETTING_OPTIONS.map(({ key, label, options }, index) => {
                  const currentValues = monitor?.settings?.forEach(({ name, values }) => {
                    if (name === key) {
                      return values;
                    }
                  });
                  return (
                    <div key={key.toString()}>
                      <Form.Item style={{ display: "none" }} name={["settings", index, "name"]} initialValue={key.toString()} />
                      <Form.Item name={["settings", index, "values"]} label={<span className={"text-xs text-gray-400"}>Printer Statuses</span>}>
                        <Select mode="multiple" allowClear placeholder="Select Printer Statuses" defaultValue={currentValues != undefined ? currentValues : []}>
                          {options?.map(({ key, label }) => {
                            return (
                              <Option key={key} value={key}>
                                {label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </Panel>
            <Panel header={<span className={"font-semibold"}>4. Notify Team</span>} key={4}>
              <div className={"ml-6"}>
                <div>
                  <div className={"flex flex-row items-center gap-2"}>
                    <span>Enable/Disable Notifications</span>
                    <Form.Item name={"notificationsEnabled"} className={"m-0"} valuePropName="checked">
                      <Switch className={form.getFieldValue("notificationsEnabled") ? "bg-primary-500" : "bg-muted"} size={"default"} checkedChildren={<i className="fas fa-bell" />} unCheckedChildren={<i className="fas fa-bell-slash" />} />
                    </Form.Item>
                  </div>
                  <div className={"items-center mt-4"}>
                    <Form.Item
                      name={"recipients"}
                      className={"m-0"}
                      label={<span>Select Team Members to Notify:</span>}
                      rules={[
                        {
                          required: form.getFieldValue("notificationsEnabled"),
                          message: "At least one recipient is required",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder="Select Team Members"
                        tagRender={({ value, onClose }) => {
                          const user = JSON.parse(value);
                          if (form.getFieldValue("notificationsEnabled")) {
                            return (
                              <Tag color={"blue"} closable={true} onClose={onClose}>
                                {user.firstName}&nbsp;{user.lastName}
                              </Tag>
                            );
                          } else {
                            return (
                              <Tag style={{ background: "#f5f5f5", color: "#bfbfbf" }} closable={false}>
                                {user.firstName}&nbsp;{user.lastName}
                              </Tag>
                            );
                          }
                        }}
                        disabled={!form.getFieldValue("notificationsEnabled")}
                      >
                        {users?.map((user) => {
                          return (
                            <Option title={`${user.firstName} ${user.lastName}`} key={user.email} value={JSON.stringify(user)}>
                              <div className={"leading-none flex flex-col"}>
                                {user.firstName} {user.lastName}
                                <span className={"text-muted text-xs"}>{user.email}</span>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={"flex flex-row items-center gap-2 mt-4"}>
                    <span>In the case of an alert, notify every</span>
                    <Form.Item
                      name={"waitTime"}
                      className={"m-0 w-44"}
                      rules={[
                        {
                          required: form.getFieldValue("notificationsEnabled"),
                          message: "Wait Time is required",
                        },
                      ]}
                    >
                      <Input type={"number"} disabled={!form.getFieldValue("notificationsEnabled")} />
                    </Form.Item>
                    <span>&nbsp;minutes</span>
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        </Form>
      </Drawer>
    </>
  );
};

type MonitorFormType = {
  deviceId: string;
  users: Array<UserType> | undefined;
  monitor?: MonitoringRuleSetDetailsType | null;
  parameters: Array<DeviceParameterType>;
  onSuccess: (o: MonitoringRuleSetDetailsType) => void;
  onClose: () => void;
};

type onTabSwitchType = (i: number, key: string) => void;
type additionType = (deviceId: string, values: any) => void;
type updateType = (deviceId: string, monitorId: string, enabled: boolean, values: any) => void;
