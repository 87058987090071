import React, { FC, ReactNode, useEffect, useState } from "react";
import { Widget } from "../Widget";
import { Avatar, Button, Divider, Form, Input, Modal, Radio, Space } from "antd";
import { convertExpressionsToMetrics, convertMetricsToExpressions2 } from "../../../../utils/metric";
import { DeleteFilled } from "@ant-design/icons";
import { MENU_ITEMS } from "../../../../utils/dashboardCustomization";
import { ACTION_TYPES } from "../../../../utils/types/uiTypes";
import { AggregationType, ChartWidgetType, ParameterDataType } from "../../../../utils/types/enums";
import { and, forEachObjIndexed, slice } from "ramda";
import { DeviceParameterType } from "../../../../utils/types/types";
import { PRIMARY_BUTTON_STYLE } from "../../../../utils/general/styles";
import { ParameterAggregationSelection } from "./ParameterAggregationSelection";

const isSingleValue: isSingleValueType = (type) => {
  return type === ChartWidgetType.SINGLE_VALUE;
};
const getInitialMetrics: getInitialMetricsType = (type, widgetType, expressions) => {
  switch (type) {
    case ACTION_TYPES.EDIT:
      return convertExpressionsToMetrics(expressions);
    case ACTION_TYPES.ADD:
      return [{ aggregation: undefined, parameter: undefined }];
    default:
      return [];
  }
};

const disabledParameter: disabledParameterType = (type, dataType) => {
  switch (type) {
    case ChartWidgetType.AREA_PLOT:
    case ChartWidgetType.LINE_PLOT:
    case ChartWidgetType.SCATTER_PLOT:
      return and(dataType !== ParameterDataType.DOUBLE, dataType !== ParameterDataType.LONG);
    default:
      return false;
  }
};

const disabledAggregation: disabledAggregationType = (dataType, aggregationType) => {
  return dataType === ParameterDataType.STRING && aggregationType !== AggregationType.LATEST;
};

export const AddWidgetModal: FC<AddWidgetFormType> = function ({ deviceId, fromDate, toDate, type, expressions = [], label = "", parameters = [], formType, onSubmit, onCancel }: AddWidgetFormType) {
  const [form] = Form.useForm();
  const [widget, setWidget] = useState<WidgetType | null>(null);

  const onCancelClick = (): void => {
    form.resetFields();
    onCancel();
  };
  const handleFormChange = (value: any, key: string): void => {
    if (widget) {
      switch (key) {
        case "metrics":
          return setWidget({ ...widget, expressions: convertMetricsToExpressions2(form.getFieldValue("metrics"), !isSingleValue(form.getFieldValue("type"))) });
        case "type": {
          const metrics = isSingleValue(form.getFieldValue("type")) ? slice(0, 1, form.getFieldValue("metrics")) : form.getFieldValue("metrics");
          form.setFieldsValue({ metrics });
          return setWidget({
            ...widget,
            type: form.getFieldValue("type"),
            expressions: convertMetricsToExpressions2(metrics, !isSingleValue(form.getFieldValue("type"))),
          });
        }
        case "label":
          return setWidget({ ...widget, label: form.getFieldValue("label") });
      }
    }
  };

  useEffect(() => {
    const metrics = getInitialMetrics(formType, type, expressions);
    form.setFieldsValue({ type, label, metrics });
    setWidget({ type, label, expressions });
  }, [type, expressions, label]);

  return (
    <Modal
      bodyStyle={{ paddingBottom: 0 }}
      forceRender
      title={formType == ACTION_TYPES.ADD ? "Add Widget" : "Edit Widget"}
      width={1200}
      visible={true}
      closable={true}
      onCancel={onCancelClick}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancelClick}>
          Cancel
        </Button>,
        <Button
          key={"submit"}
          className={PRIMARY_BUTTON_STYLE}
          type={"primary"}
          onClick={(): void => {
            form.submit();
          }}
        >
          Save
        </Button>,
      ]}
    >
      <div className={"h-full overflow-y-auto hide-scrollbar max-h-[75vh]"}>
        {widget && <Widget outerClassName={"border p-6"} title={form.getFieldValue("label")} height={180} deviceId={deviceId} from={fromDate} to={toDate} expressions={widget.expressions} type={form.getFieldValue("type")} />}
        <Divider />
        <Form
          form={form}
          onFinish={(): void => {
            widget && onSubmit(widget);
          }}
          colon={false}
          requiredMark={false}
          layout={"vertical"}
          onValuesChange={(changedValues): void => {
            forEachObjIndexed(handleFormChange, changedValues);
          }}
        >
          <Space className={"mb-2"}>
            <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
              1
            </Avatar>
            <div className={"text-sm font-medium"}>Set Display Preferences</div>
          </Space>
          <Form.Item className={"mb-0 pl-8"} labelAlign={"left"} name="type" label={<span className={"text-xs text-gray-400"}>Widget Type</span>} rules={[{ required: true }]}>
            <Radio.Group className={`flex flex-row gap-4`}>
              {MENU_ITEMS.map(({ id, label }) => (
                <Radio.Button key={id} value={id} className={`group w-full inline-flex justify-center items-center h-14`}>
                  <div>{label}</div>
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Divider />
          <Space className={"mb-2"}>
            <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
              2
            </Avatar>
            <div className={"text-sm font-medium"}>Metrics</div>
          </Space>
          <Form.List name="metrics">
            {(metrics, { add, remove }): ReactNode => {
              return (
                <div className={"w-full pl-8"}>
                  {metrics.map((field, index) => (
                    <React.Fragment key={field.key}>
                      <div className={"flex flex-row gap-4"}>
                        <div className={"flex-1 grid grid-cols-2 gap-4"}>
                          <ParameterAggregationSelection
                            isParameterDisabled={(dataType): boolean => disabledParameter(form.getFieldValue("type"), dataType)}
                            isAggregationDisabled={disabledAggregation}
                            parameters={parameters}
                            onParameterChange={(type): void => {
                              //    type === ParameterDataType.STRING && form.setFieldsValue(assocPath(["metrics", index, "aggregation"], AggregationType.LATEST, form.getFieldsValue()));
                            }}
                            aggregationPathname={[index, "aggregation"]}
                            parameterPathname={[index, "parameter"]}
                          />
                        </div>
                        {metrics.length > 1 && (
                          <div className={"flex"}>
                            <DeleteFilled
                              disabled={metrics.length == 1}
                              role={"button"}
                              className={"text-2xl text-danger-500 hover:text-danger-600 pt-8"}
                              onClick={(): void => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  <Button
                    hidden={form.getFieldValue("type") === ChartWidgetType.SINGLE_VALUE || (form.getFieldValue("type") === ChartWidgetType.SCATTER_PLOT && metrics.length >= 2)}
                    onClick={(): void => {
                      add({ aggregation: undefined, parameter: undefined });
                    }}
                    className={"mt-4 mb-2 bg-grey-50 border border-gray-200 text-center text-gray-600 w-full h-10 hover:shadow-sm"}
                  >
                    Add Metric
                  </Button>
                </div>
              );
            }}
          </Form.List>
          <Divider />
          <Space className={"mb-2"}>
            <Avatar className={"bg-primary-600 border-primary-600"} size={"small"}>
              3
            </Avatar>
            <div className={"text-sm font-medium"}>Add Title</div>
          </Space>
          <Form.Item name="label" className={"pl-8"} label={<span className={"text-xs text-gray-400"}>Label</span>} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

type AddWidgetFormType = WidgetType & FormType;
type WidgetType = {
  expressions: Array<string>;
  label: string;
  type: ChartWidgetType;
};
type FormType = {
  deviceId: string;
  fromDate: number;
  toDate: number;
  parameters: Array<DeviceParameterType>;
  formType: ACTION_TYPES;
  onSubmit: (o: WidgetType) => void;
  onCancel: () => void;
};
type MetricType = {
  aggregation: AggregationType | undefined;
  parameter: DeviceParameterType | undefined;
};
type isSingleValueType = (type: ChartWidgetType) => boolean;
type getInitialMetricsType = (formType: ACTION_TYPES, widgetType: ChartWidgetType, expressions: Array<string>) => Array<MetricType>;

type disabledParameterType = (widgetType: ChartWidgetType, dataType: ParameterDataType) => boolean;
type disabledAggregationType = (widgetType: ParameterDataType | null, dataType: AggregationType) => boolean;
