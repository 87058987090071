import { Form, Select } from "antd";
import { filterSortWithTitle, valueOrDefault } from "../../../../utils/general/helper";
import { AGGREGATIONS } from "../../../../utils/metric";
import React, { FC, useState } from "react";
import { AggregationType, ParameterDataType } from "../../../../utils/types/enums";
import { DeviceParameterType } from "../../../../utils/types/types";
import { getPopupContainer } from "../../../../utils/general/common";

const { Option } = Select;

export const ParameterAggregationSelection: FC<ParameterAggregationSelectionType> = ({ parameters, aggregationPathname, isAggregationDisabled = (): boolean => false, isParameterDisabled = (): boolean => false, parameterPathname, onParameterChange }) => {
  const [currentDataType, setCurrentDataType] = useState<ParameterDataType | null>(null);

  return (
    <>
      <Form.Item className={"w-full mb-2"} labelAlign={"left"} label={<span className={"text-xs text-gray-400"}>Parameter</span>} name={parameterPathname} rules={[{ required: true, message: "Parameter is required" }]}>
        <Select
          onChange={(v, w): void => {
            onParameterChange && onParameterChange(w.type);
            setCurrentDataType(w.type);
          }}
          getPopupContainer={getPopupContainer}
          showSearch
          placeholder="Search to Select"
          optionFilterProp={"title"}
          filterSort={filterSortWithTitle}
        >
          {parameters.map(({ id, tag, name = tag, unit, dataType }) => {
            return (
              <Option title={name + tag + valueOrDefault("", unit)} key={id} value={tag} type={dataType} disabled={isParameterDisabled(dataType)}>
                <div className={"flex flex-col items-start p-0 m-0 w-full"}>
                  <span className={"flex flex-row items-center w-full"}>
                    {tag}
                    <span className={"ml-auto font-extralight text-xs"}>
                      {valueOrDefault("", unit)}&nbsp;({dataType})
                    </span>
                  </span>
                  <span className={"font-extralight text-xs"}>{name}</span>
                </div>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item className={"w-full mb-2"} labelAlign={"left"} label={<span className={"text-xs text-gray-400"}>Aggregation</span>} name={aggregationPathname} rules={[{ required: true, message: "Aggregation is required" }]}>
        <Select className={"w-full"} getPopupContainer={getPopupContainer}>
          {AGGREGATIONS.map(({ key, value }, index) => (
            <Option key={index} value={key} disabled={isAggregationDisabled(currentDataType, key)}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

type ParameterAggregationSelectionType = {
  parameters: DeviceParameterType[];
  aggregationPathname: (number | string)[];
  parameterPathname: (number | string)[];
  isParameterDisabled?: (dataType: ParameterDataType) => boolean;
  isAggregationDisabled?: (widgetType: ParameterDataType | null, dataType: AggregationType) => boolean;
  onParameterChange?: (dataType: ParameterDataType) => void;
};
