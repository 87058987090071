type eventsType = { [key: string]: any };
type onType = (key: string, callback: any) => void;
type offType = (key: string, callback: any) => void;
type triggerType = (key: string, value: any) => void;
type EventsType = () => { on: onType; off: offType; trigger: triggerType };

const Events: EventsType = function () {
  const events: eventsType = {};
  const on: onType = (k, f) => {
    if (!events[k]) events[k] = [];
    events[k].push(f);
  };
  const off: offType = (k, f) => {
    events[k] = events[k].filter((v: any) => v !== f);
  };
  const trigger: triggerType = (k, v) => {
    (events[k] || []).forEach((f: any) => {
      f(v);
    });
  };
  return {
    on,
    off,
    trigger,
  };
};
export const events = Events();
