const expression = /[0-9A-Fa-f]{6}/g;
export const COLORS: Array<ColorType> = [
  {
    key: "CLASSIC",
    label: "Classic",
    values: ["#000000", "#531dab", "#1d39c4", "#08979c", "#389e0d", "#cf1322", "#c41d7f", "#d46b08", "#ffec3d"],
  },
  {
    key: "COOL",
    label: "Cool",
    values: ["#1d39c4", "#2f54eb", "#006d75", "#08979c", "#135200", "#237804", "#389e0d", "#adc6ff", "#87e8de"],
  },
  {
    key: "WARM",
    label: "Warm",
    values: ["#820014", "#a8071a", "#f5222d", "#ff7875", "#c41d7f", "#f759ab", "#fa8c16", "#fadb14", "#fff566"],
  },
  {
    key: "GRAY",
    label: "Gray",
    values: ["#000000", "#141414", "#262626", "#434343", "#595959", "#8c8c8c", "#bfbfbf", "#f0f0f0", "#ffffff"],
  },
  {
    key: "PURPLE",
    label: "Purple",
    values: ["#120338", "#22075e", "#391085", "#531dab", "#722ed1", "#9254de", "#b37feb", "#d3adf7", "#efdbff"],
  },
  {
    key: "BLUE",
    label: "Blue",
    values: ["#030852", "#061178", "#10239e", "#1d39c4", "#2f54eb", "#597ef7", "#85a5ff", "#adc6ff", "#d6e4ff"],
  },
  {
    key: "CYAN",
    label: "Cyan",
    values: ["#002329", "#00474f", "#006d75", "#08979c", "#13c2c2", "#36cfc9", "#5cdbd3", "#87e8de", "#b5f5ec"],
  },
  {
    key: "GREEN",
    label: "Green",
    values: ["#092b00", "#135200", "#237804", "#389e0d", "#52c41a", "#73d13d", "#95de64", "#b7eb8f", "#d9f7be"],
  },
  {
    key: "RED",
    label: "Red",
    values: ["#5c0011", "#820014", "#a8071a", "#cf1322", "#f5222d", "#ff4d4f", "#ff7875", "#ffa39e", "#ffccc7"],
  },
  {
    key: "MAGENTA",
    label: "Magenta",
    values: ["#520339", "#780650", "#9e1068", "#c41d7f", "#eb2f96", "#f759ab", "#ff85c0", "#ffadd2", "#ffd6e7"],
  },
  {
    key: "ORANGE",
    label: "Orange",
    values: ["#612500", "#873800", "#ad4e00", "#d46b08", "#fa8c16", "#ffa940", "#ffc069", "#ffd591", "#ffe7ba"],
  },
  {
    key: "YELLOW",
    label: "Yellow",
    values: ["#614700", "#876800", "#ad8b00", "#d4b106", "#fadb14", "#ffec3d", "#fff566", "#fffb8f", "#ffffb8"],
  },
];

export const isHexColor: isHexColorType = (value) => expression.test(value);

type ColorType = {
  key: string;
  label: string;
  values: Array<string>;
};
type isHexColorType = (value: string) => boolean;
