import React, { FC, ReactElement, ReactNode } from "react";
import { Menu } from "antd";
import { Route, Switch } from "react-router-dom";
import { MenuItemType } from "../../utils/types/uiTypes";

const getRoutes: getRouteMenuType = (menu = []) => {
  return menu
    .filter(({ permission }) => permission)
    .map(({ key, exact, path, Component }) => (
      <Route key={key} exact={exact} path={path}>
        {Component && <Component />}
      </Route>
    ));
};

const getMenu: getRouteMenuType = (menu = []): ReactNode => {
  return menu
    .filter(({ permission }) => permission)
    .map(({ key, icon, title, hoverPrefix, hoverSuffix }) => (
      <Menu.Item key={key} icon={icon} className={"group my-0 select-none"}>
        {hoverPrefix && <span className={"hidden group-hover:inline-block mr-0.5"}>{hoverPrefix}</span>}
        {title}
        {hoverSuffix && <span className={"hidden group-hover:inline-block ml-0.5"}>{hoverSuffix}</span>}
      </Menu.Item>
    ));
};

export const MenuNavigation: FC<MenuNavigationType> = ({ title, menu, defaultSelectedKeys, selectedKeys, defaultValue, routePath, extra = <></>, children }: MenuNavigationType) => {
  return (
    <div className={"relative flex flex-col w-full h-screen max-h-screen bg-grey-50"}>
      <div className={"absolute w-full max-w-full shadow-xs bg-white pt-6 z-[100]"}>
        <div className={"2xl:max-w-screen-2xl mx-auto"}>
          <div className={"inline-block w-full px-6"}>{title}</div>
          <div className={"w-full flex justify-between items-center"}>
            <Menu className={"w-full px-1 py-px font-medium text-gray-400 border-b-0 overflow-x-auto hide-scrollbar"} defaultValue={defaultValue} selectedKeys={selectedKeys} defaultSelectedKeys={defaultSelectedKeys} mode={"horizontal"} onClick={routePath}>
              {getMenu(menu)}
            </Menu>
            <div>{extra}</div>
          </div>
        </div>
      </div>
      <div className={"flex-1 w-full h-full max-h-full max-w-full mx-auto pt-24 transition-colors duration-200 ease-out motion-reduce:transition-none motion-reduce:transform-none"}>{children ? children : <Switch>{getRoutes(menu)}</Switch>}</div>
    </div>
  );
};

type MenuNavigationType = {
  title: ReactNode;
  menu: MenuItemType[];
  defaultSelectedKeys?: string[];
  selectedKeys?: string[];
  defaultValue?: string;
  routePath: (link: any) => void;
  extra?: ReactNode;
  children?: ReactElement;
};
type getRouteMenuType = (menu: MenuItemType[]) => ReactNode;
